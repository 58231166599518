import React from "react";

const LoloftInfoButton = props => {
  return (
    <img
      {...props}
      className="ui info image"
      width="21"
      src="/assets/icons/icon-info.svg"
      alt=""
    >
      {props.children}
    </img>
  );
};

export default LoloftInfoButton;
