import debounce from "lodash.debounce";
import React, { Component } from "react";
import { Search } from "semantic-ui-react";

const autocompleteService = new window.google.maps.places.AutocompleteService();
var geocoder = new window.google.maps.Geocoder();

export default class LoloftLocationInput extends Component {
  componentWillMount() {
    this.resetComponent();
  }

  resetComponent = () =>
    this.setState({ isLoading: false, results: [], value: "" });

  getTypeParameter = () => {
    if (this.isCitySearch) {
      return ["(cities)"];
    } else {
      return ["geocode"];
    }
  };

  findCoordinates(address) {
    return new Promise(function(resolve) {
      geocoder.geocode({ address: address }, function(results, status) {
        if (status === "OK") {
          resolve({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng()
          });
        } else {
          resolve({ lat: undefined, lng: undefined });
        }
      });
    });
  }

  isCitySearch = () => {
    return !this.props.type || this.props.type === "cities";
  };

  handleResultSelect = async (e, { result }) => {
    console.log(result);
    this.setState({ value: result.title, selectedPlace: result });
    if (this.isCitySearch()) {
      const state = result.description.split(",")[0].trim();
      this.props.onSelect(result.title, state);
    } else {
      const coordinates = await this.findCoordinates(result.title);
      this.props.onSelect(result.title, coordinates);
    }
  };

  handleSearchChange = (e, { value }) => {
    if (value.length === 0) {
      return this.resetComponent();
    }

    this.setState({ isLoading: true, value });
    autocompleteService.getQueryPredictions(
      {
        input: value,
        types: this.getTypeParameter(),
        componentRestrictions: { country: "us" }
      },
      this.handleAutocompleteResult
    );
  };

  handleAutocompleteResult = (predictions, status) => {
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      this.setState({
        isLoading: false,
        results: predictions.map(prediction => {
          return {
            key: prediction.id,
            title: prediction.structured_formatting.main_text,
            description: prediction.structured_formatting.secondary_text,
            source: prediction
          };
        })
      });
    }
  };

  render() {
    const { isLoading, value, results } = this.state;
    return (
      <Search
        className="field"
        placeholder={this.props.placeholder}
        loading={isLoading}
        onResultSelect={this.handleResultSelect}
        onSearchChange={debounce(this.handleSearchChange, 500, {
          leading: true
        })}
        results={results}
        value={value}
      />
    );
  }
}
