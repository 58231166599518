import React from "react";

import { Message } from "semantic-ui-react";

//Wrapper for Message component from semantic ui
//if wrapper receives state and name where state.errors[name].text is existing
//component will render message
function LoloftErrorMessage(props) {
  const { parentState, name } = props;
  if (parentState && name && parentState.errors && parentState.errors[name]) {
    return <Message error content={parentState.errors[name].text} />;
  } else {
    return null;
  }
}

export default LoloftErrorMessage;
