import React from "react";
import { Link } from "react-router-dom";
import eq from "lodash/eq";

import history from "../../history";
import { sidebar } from "../../statics/pages/account-page";

//this component will render items for sidebar in account page
//it will use data from statics folder as a model
function AccountPageItems(props) {
  //returns list of items
  const renderItems = () => {
    return sidebar.map(item => {
      const shouldRenderItem =
        !props.user.type || eq(props.user.type, item.type);
      //if there is a type in user object, and it is not same as type of
      //item, then item will not be rendered
      if (!shouldRenderItem) return null;
      const isActive = history.location.pathname.includes(item.url);
      return (
        <div key={item.url} className={`${isActive ? "active" : ""}`}>
          <Link to={`/account/${item.url}`}>
            <img src={item.icon} />
            <div>{item.title}</div>
          </Link>
        </div>
      );
    });
  };

  return <div>{renderItems()}</div>;
}

export default AccountPageItems;
