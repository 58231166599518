import React, { Component } from "react";
import { connect } from "react-redux";

import PriceBreakdownPopup from "./PriceBreakdownPopup";
import LoloftButton from "../../components/common/LoloftButton";
import ModalDialog from "../modal/ModalDialog";
import SelectDates from "../browse-properties-page/SelectDates";
import LoloftCommitmentTypes from "../../components/common/LoloftCommitmentTypes";

import { setBookingData } from "../../store/actions/properties";

import { propertyPage } from "../../statics/pages/property-page";
import { propertiesPage } from "../../statics/pages/browse-properties-page";

class PropertyDataMobile extends Component {
  state = {
    showSetDate: false,
    showSetDateMobile: false
  };

  //hide view for setting date
  //if modal param is true, action will replace set date view with basic view
  //if false, action will hide modal dialogue
  hideSetDate = mobile => {
    if (mobile) {
      this.setState({ showSetDateMobile: false });
    } else {
      this.setState({ showSetDate: false });
    }
  };

  //show view for setting date
  //if modal param is true, action will replace existing view with set date view
  //if false, action will show modal dialogue
  showSetDate = mobile => {
    if (mobile) {
      this.setState({ showSetDateMobile: true });
    } else {
      this.setState({ showSetDate: true });
    }
  };

  renderCheckAvailabilityButton = () => {
    return (
      <LoloftButton onClick={() => this.showSetDate(false)}>
        {propertyPage.checkButton}
      </LoloftButton>
    );
  };

  renderStayHereButton = () => {
    return (
      <LoloftButton 
        onClick={() => this.props.setBookingData(this.props.property)}
      >
        {propertyPage.stayButton}
      </LoloftButton>
    );
  };

  onCommitmentTypeChange = (e, { value }) => {
    this.setState({ commitmentType: value });
  };

  renderCommitmentTypesAndStayHereButton = () => {
    //if dates are set, render commitment and stay here button
    if (this.props.dates.date) {
      //if there is a commitment type set in app state
      //and not in component state
      //we will use it as a default for commitmentTypes component
      const { price } = this.props.property;
      return (
        <div>
          <LoloftCommitmentTypes
            onChange={this.onCommitmentTypeChange}
            price={price}
            commitmentType={this.state.commitmentType}
          />
          {this.renderStayHereButton()}
        </div>
      );
    }
  };

  renderCheckAvailability = () => {
    //if dates are not set, render check availability panel
    if (!this.props.dates.date) {
      const { price } = this.props.property;
      return (
        <div>
          <div className="box flex spaced">
            <div className="property-price">
              ${price} <span>/ month</span>
            </div>
            <PriceBreakdownPopup property={this.props.property} />
          </div>
          <div>
            <ModalDialog
              closeIcon
              trigger={this.renderCheckAvailabilityButton()}
              title={propertiesPage.selectDatesModalTitle}
              onClose={() => this.hideSetDate(false)}
              open={this.state.showSetDate}
            >
              <SelectDates
                closeAction={() => this.hideSetDate(false)}
                onSubmit={() => {}}
              />
            </ModalDialog>
          </div>
        </div>
      );
    }
  };

  renderData = () => {
    const {
      state,
      bedrooms,
      bathrooms,
      location,
      size,
      numberId,
      available
    } = this.props.property;
    return (
      <div className="special mobile box">
        <h3>{location.address}</h3>
        <p>{bedrooms} Bedrooms &bull; {bathrooms} Bathrooms</p>
        <div className="row flex spaced inline">
          <p>{size} Sq Ft</p>
          <p>ID: {numberId}</p>
        </div>
        <div className="row flex spaced inline pushed top">
          <div>
            {this.renderCheckAvailability()}
          </div>
          <span>
            <span className="ui black label">{state}</span>
            <span className="ui yellow label">{available}</span>
          </span>
        </div>
        {this.renderCommitmentTypesAndStayHereButton()}
      </div>
    );
  };

  render() {
    const { property } = this.props;
    if (property) {
      return this.renderData();
    }
    return null;
  }

  //when props are updated - and this will
  //happen when users set date and commitment
  //we want to state with commitment value
  //so we can have controlled LoloftCommitmentTypes
  static getDerivedStateFromProps(props, currentState) {
    const { commitmentType } = props.dates;
    if (!currentState.commitmentType && commitmentType) {
      return {
        commitmentType
      };
    }
    return null;
  }
}

const mapStateToProps = state => {
  return { dates: state.properties.dates };
};

export default connect(mapStateToProps, { setBookingData })(PropertyDataMobile);
