import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { Form } from "semantic-ui-react";

import { submitPropertyAddress } from "../../store/actions/onboarding";
import { propertyAddressData } from "../../statics/pages/property-onboarding-page";
import { validateRequired } from "../../utils/validation";
import LoloftErrorMessage from "../../components/common/LoloftErrorMessage";
import LoloftLocationInput from "../../components/common/LoloftLocationInput";
import { LoloftMap, Pointer } from "../../components/common/LoloftMap";

class PropertyAddressStep extends Component {
  state = {
    address: "",
    coordinates: {},
    errors: {},
  };

  onAddressSelect = (address, coordinates) => {
    this.setState({ address, coordinates });
  };

  //returns js object with property named as field where error has occurred
  validate = () => {
    let errors = {};
    const addressError = validateRequired(this.state.address, "Address");
    if (addressError) {
      errors.address = addressError;
    }

    return errors;
  };

  onSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate();
    if (!isEmpty(errors)) {
      return this.setState({ errors });
    }
    try {
      this.props.submitPropertyAddress(this.state);
      this.props.setCompletedStep(this.props.step);
    } catch (e) {
      const errors = { ...this.state.errors, [e.name]: { text: e.message } };
      this.setState({ errors });
    }
  };

  //renders information about data that user entered in this step
  renderCompletedInformation = () => {
    const pa = this.props.propertyAddress;
    if (pa) {
      return (
        <div>
          <h2>{propertyAddressData.title}</h2>
          <p>{pa.address}</p>
        </div>
      );
    }
    return null;
  };

  //renders information about data that user entered in this step
  renderIncompleteInformation = () => {
    return <h2>{propertyAddressData.title}</h2>;
  };

  renderMap = () => {
    const { lat, lng } = this.state.coordinates;
    if (!lat || !lng) {
      return null;
    } else {
      return (
        <div style={{ height: "400px", width: "400px" }}>
          <LoloftMap center={this.state.coordinates}>
            <Pointer lat={lat} lng={lng} />
          </LoloftMap>
        </div>
      );
    }
  };

  //renders specific for or data for this step, and will bi directly used
  //for mobile view
  renderStep = () => {
    const { addressInput, buttonLabel, title } = propertyAddressData;
    return (
      <Fragment>
        <h2>{title}</h2>
        <Form onSubmit={this.onSubmit} error>
          <LoloftLocationInput
            onSelect={this.onAddressSelect}
            placeholder={addressInput.placeholder}
            type="address"
          />
          <LoloftErrorMessage parentState={this.state} name="address" />
          {this.renderMap()}
          <Form.Button type="submit" primary fluid>
            {buttonLabel}
          </Form.Button>
        </Form>
      </Fragment>
    );
  };

  //props
  //mobile - is this a view for mobile device
  //open - is this step currently in focus/ opened
  //competed - is this step already completed
  //setCompletedStep - will set this step as compete in parent,
  //which will make parent switch to the next step
  render() {
    const { open, completed, mobile } = this.props;
    if (mobile) {
      if (open) return this.renderStep();
      else return null;
    } else {
      if (open) return this.renderStep();
      else {
        if (completed) return this.renderCompletedInformation();
        else return this.renderIncompleteInformation();
      }
    }
  }
}

const mapStateToProps = (state) => {
  return { propertyAddress: state.onboarding.propertyAddress };
};

export default connect(mapStateToProps, { submitPropertyAddress })(
  PropertyAddressStep
);
