import React, { Component } from "react";
import isEmpty from "lodash/isEmpty";
import eq from "lodash/eq";
import { connect } from "react-redux";

import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "react-stripe-elements";
import { Checkbox, Form } from "semantic-ui-react";

import LoloftErrorMessage from "../../common/LoloftErrorMessage";
import { registerCreditCard } from "../../../store/actions/payment";
import { addPaymentInformation } from "../../../store/actions/onboarding";
import { creditCard } from "../../../statics/paymentInfo";

export class CreditCardInput extends Component {
  state = {
    termsAndService: false,
    errors: {},
  };

  //controlls input fields and removes field error message on field input change
  onChange = (e, data) => {
    const { name, checked } = data;
    //termsAndService are checkbox fields, and they return thair value as checked property
    if (eq(name, "termsAndService")) {
      this.setState({ [name]: checked });
    }
  };

  //listens for errors on stripe elements
  onStripeElementChange = (e) => {
    const { error, elementType } = e;
    var errors = this.state.errors;
    if (!isEmpty(error)) {
      const msg = { text: error.message };
      errors[elementType] = msg;
    } else {
      errors[elementType] = undefined;
    }
    this.setState({ errors });
  };

  registerCard = async () => {
    try {
      const card = await registerCreditCard(
        this.props.stripe,
        this.props.owner
      );
      this.props.addPaymentInformation(card);
      this.props.closeInput();
    } catch (err) {
      const msg = { text: err.message };
      var errors = this.state.errors;
      errors.cardValidation = msg;
      return this.setState({ errors });
    }
  };

  render() {
    const {
      holder,
      addButtonLabel,
      checkboxLabel,
      cardNumberInput,
      cvcInput,
      validThruInput,
    } = creditCard;

    return (
      <div>
        <div className="flex inline dummy">
          <img src="/assets/icons/icon-user.svg" />
          <div className="contents">
            <label>{holder}</label>
            <p>{this.props.legalName}</p>
          </div>
        </div>
        <Form onSubmit={this.registerCard} error>
          <Form.Field>
            <label>{cardNumberInput.label}</label>
            <CardNumberElement
              placeholder={cardNumberInput.placeholder}
              onChange={this.onStripeElementChange}
            />
            <LoloftErrorMessage parentState={this.state} name="cardNumber" />
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field>
              <label>{validThruInput.label}</label>
              <CardExpiryElement
                placeholder={validThruInput.placeholder}
                onChange={this.onStripeElementChange}
              />
              <LoloftErrorMessage parentState={this.state} name="cardExpiry" />
            </Form.Field>
            <Form.Field>
              <label>{cvcInput.label}</label>
              <CardCvcElement
                placeholder={cvcInput.placeholder}
                onChange={this.onStripeElementChange}
              />
              <LoloftErrorMessage parentState={this.state} name="cardCvc" />
            </Form.Field>
            <LoloftErrorMessage
              parentState={this.state}
              name="cardValidation"
            />
          </Form.Group>
          <p>{creditCard.note}</p>
          <Checkbox
            className="full"
            label={checkboxLabel}
            name="termsAndService"
            checked={this.state.termsAndService}
            onChange={this.onChange}
          />
          <Form.Button
            disabled={!this.state.termsAndService}
            primary
            fluid
            type="submit"
          >
            {addButtonLabel}
          </Form.Button>
        </Form>
      </div>
    );
  }
}

export default connect(null, { addPaymentInformation })(
  injectStripe(CreditCardInput)
);
