import React, { Component } from "react";

import "../../assets/css/loloft-v2/loloft-input.scss";

class LoloftInput extends Component {
  render() {
    const {
      name,
      type = "text",
      placeholder,
      value,
      onChange,
      secondary,
    } = this.props;
    return (
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        secondary={secondary.toString()}
      />
    );
  }
}

export default LoloftInput;
