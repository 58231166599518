import React from "react";

import { LoloftMap, Pointer } from "../../components/common/LoloftMap";

import { propertyPage } from "../../statics/pages/property-page";

function PropertyMap(props) {
  const { property } = props;
  if (property && property.location) {
    const { lat, lng } = property.location;
    return (
      <div className="map box" style={{ height: "400px", width: "100%" }}>
        <h3>{propertyPage.location}</h3>
        <LoloftMap center={{ lat, lng }} zoom={16}>
          <Pointer lat={lat} lng={lng} />
        </LoloftMap>
      </div>
    );
  }
  return null;
}

export default PropertyMap;
