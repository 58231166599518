import eq from "lodash/eq";
import { states } from "../statics/states";
export const getStateName = (abbr) => {
  const state = states.find((s) => {
    return eq(s.abbr.trim(), abbr);
  });
  if (state) {
    return state.name;
  } else {
    return undefined;
  }
};

export const getStateAbbr = (stateName) => {
  const state = states.find((s) => {
    return eq(s.name.trim(), stateName);
  });
  if (state) {
    return state.abbr;
  } else {
    return undefined;
  }
};
