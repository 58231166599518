import React, { Component } from "react";
import { connect } from "react-redux";
import eq from "lodash/eq";
import { Form, Checkbox } from "semantic-ui-react";

import LoloftErrorMessage from "../LoloftErrorMessage";
import LoloftUploadImage from "../LoloftUploadImage";
import { photoIDData } from "../../../statics/pages/member-onboarding-page";

class PhotoIDStep extends Component {
  state = { front: false, back: false, termsAndService: false, errors: {} };

  onSubmit = async (e) => {
    e.preventDefault();
    try {
      this.props.setCompletedStep(this.props.step);
    } catch (e) {
      const errors = { ...this.state.errors, onboarding: { text: e.message } };
      this.setState({ errors });
    }
  };

  //controlls input fields and removes field error message on field input change
  onChange = (e, data) => {
    const { name, checked } = data;
    if (eq(name, "termsAndService")) {
      this.setState({ [name]: checked });
    }
  };
  //renders information about data that user entered in this step
  renderCompletedInformation = () => {
    return (
      <div>
        <h2>{photoIDData.title}</h2>
        <div>Uploaded</div>
      </div>
    );
  };

  //renders information about data that user entered in this step
  renderIncompleteInformation = () => {
    return <h2>{photoIDData.title}</h2>;
  };

  //renders specific for or data for this step, and will bi directly used
  //for mobile view
  renderStep = () => {
    if (!this.props.auth || !this.props.auth.user) {
      return;
    }
    const {
      acceptable,
      back,
      front,
      title,
      buttonLabel,
      checkboxLabel,
    } = photoIDData;
    const user = this.props.auth.user;
    return (
      <Form onSubmit={this.onSubmit}>
        <h2>{title}</h2>
        <LoloftErrorMessage parentState={this.state} name="onboarding" />
        <div>
          <p>{acceptable.title}</p>
          <ul>
            <li>{acceptable.items[0]}</li>
            <li>{acceptable.items[1]}</li>
          </ul>
        </div>
        <LoloftUploadImage
          fileName={`documents/${user.uid}/front.jpg`}
          label={front}
          onUploadFile={() => this.setState({ front: true })}
          onRemoveFile={() => this.setState({ front: false })}
        />
        <LoloftUploadImage
          fileName={`documents/${user.uid}/back.jpg`}
          label={back}
          onUploadFile={() => this.setState({ back: true })}
          onRemoveFile={() => this.setState({ back: false })}
        />
        <Checkbox
          className="full"
          label={checkboxLabel}
          name="termsAndService"
          checked={this.state.termsAndService}
          onChange={this.onChange}
        />
        <Form.Button
          disabled={
            !this.state.termsAndService || !this.state.front || !this.state.back
          }
          type="submit"
          primary
          fluid
        >
          {buttonLabel}
        </Form.Button>
      </Form>
    );
  };

  //props
  //mobile - is this a view for mobile device
  //open - is this step currently in focus/ opened
  //competed - is this step already completed
  //setCompletedStep - will set this step as compete in parent,
  //which will make parent switch to the next step
  render() {
    const { open, completed, mobile } = this.props;
    if (mobile) {
      if (open) return this.renderStep();
      else return null;
    } else {
      if (open) return this.renderStep();
      else {
        if (completed) return this.renderCompletedInformation();
        else return this.renderIncompleteInformation();
      }
    }
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

export default connect(mapStateToProps)(PhotoIDStep);
