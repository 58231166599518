import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import firebase from "../../config/firebase";
import isEmpty from "lodash/isEmpty";
import times from "lodash/times";
import { Form, Button, Grid } from "semantic-ui-react";

import { submitPropertyDetails } from "../../store/actions/onboarding";
import { propertyDetailsData } from "../../statics/pages/property-onboarding-page";
import { validateYear, validatePropertySize } from "../../utils/validation";
import LoloftErrorMessage from "../../components/common/LoloftErrorMessage";
import LoloftUploadImage from "../../components/common/LoloftUploadImage";

class PropertyDetailsStep extends Component {
  state = {
    buildingName: "",
    images: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    lastRenovated: new Date().getFullYear(),
    builtYear: new Date().getFullYear(),
    propertySize: 1000,
    imageFieldCount: 3,
    errors: {},
  };

  componentDidMount() {
    //used to set ref to property state, because it takes time
    this.setPropertyRef();
  }

  //controlls input fields and removes field error message on field input change
  onChange = (e, data) => {
    const { name, value } = data;
    const errors = { ...this.state.errors, [name]: undefined };
    this.setState({ [name]: value, errors });
  };

  //returns js object with property named as field where error has occurred
  validate = () => {
    let errors = {};
    const propertySizeError = validatePropertySize(
      this.state.propertySize,
      "Property Size"
    );
    if (propertySizeError) {
      errors.propertySize = propertySizeError;
    }
    const builtYearError = validateYear(this.state.builtYear, "Built Year");
    if (builtYearError) {
      errors.builtYear = builtYearError;
    }
    const lastRenovatedError = validateYear(
      this.state.lastRenovated,
      "Last Ranovated"
    );
    var setImages = 0;
    this.state.images.forEach((i) => {
      if (i) setImages++;
    });
    if (setImages < 3) {
      errors.images = { text: "Please set at least 3 images" };
    }

    if (lastRenovatedError) {
      errors.lastRenovated = lastRenovatedError;
    }

    return errors;
  };

  onSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate();
    if (!isEmpty(errors)) {
      return this.setState({ errors });
    }
    this.props.submitPropertyDetails(this.state);
    this.props.setCompletedStep(this.props.step);
  };

  //renders information about data that user entered in this step
  renderCompletedInformation = () => {
    const pd = this.props.propertyDetails;
    if (pd) {
      return (
        <div>
          <h2>{propertyDetailsData.title}</h2>
          <p>
            {pd.buildingName} {pd.builtYear}, {pd.propertySize}
            {propertyDetailsData.propertySizeInput.unit} renovated in
          </p>
        </div>
      );
    }
    return null;
  };

  //renders information about data that user entered in this step
  renderIncompleteInformation = () => {
    return <h2>{propertyDetailsData.title}</h2>;
  };

  //used for async setting of property ref, which will be used
  //for setting path for image storage
  setPropertyRef = async () => {
    const ref = await firebase
      .firestore()
      .collection("propertiesOnboarding")
      .doc();
    this.setState({ propertyRef: ref.id });
  };

  //button action for adding more images
  addMoreImageFields = () => {
    if (this.state.imageFieldCount + 3 > 10) {
      this.setState({ imageFieldCount: 10 });
    } else {
      this.setState({ imageFieldCount: this.state.imageFieldCount + 3 });
    }
  };

  //this method will render image input fields,
  //in one row for mobile, or three rows for desktop screens
  renderImageFields = () => {
    //we need propertyRef for setting proper image path in firebase storage
    if (!this.state.propertyRef) return;
    var imageFields = [];
    var imagesState = [...this.state.images];
    const errors = { ...this.state.errors, images: undefined };

    times(this.state.imageFieldCount, (i) => {
      imageFields.push(
        <Grid.Column key={i}>
          <LoloftUploadImage
            fileName={`propertyOnboarding/${this.state.propertyRef}/${i}`}
            onUploadFile={() => {
              imagesState[i] = 1;
              this.setState({ images: imagesState, errors });
            }}
            onRemoveFile={() => {
              imagesState[i] = 0;
              this.setState({ images: imagesState });
            }}
          />
        </Grid.Column>
      );
    });
    return (
      <Grid className="straight" columns={this.props.mobile ? 1 : 3}>
        {imageFields}
      </Grid>
    );
  };

  renderImagesPanel = () => {
    const {
      addMoreImagesButtonLabel,
      title,
      info,
    } = propertyDetailsData.images;
    return (
      <div className="images box">
        <h4>{title}</h4>
        <p>{info}</p>
        <div>{this.renderImageFields()}</div>
        <LoloftErrorMessage parentState={this.state} name="images" />

        <Button
          className="ui white fluid button"
          disabled={this.state.imageFieldCount === 10}
          onClick={this.addMoreImageFields}
        >
          {addMoreImagesButtonLabel}
        </Button>
      </div>
    );
  };

  //renders specific for or data for this step, and will bi directly used
  //for mobile view
  renderStep = () => {
    const {
      buildingNameInput,
      builtYearInput,
      lastRenovatedInput,
      propertySizeInput,
      buttonLabel,
      title,
    } = propertyDetailsData;
    return (
      <Fragment>
        <h2>{title}</h2>
        <Form error>
          <Form.Input
            onChange={this.onChange}
            label={buildingNameInput.label}
            name="buildingName"
            placeholder={buildingNameInput.placeholder}
            value={this.state.buildingName}
            error={this.state.errors.buildingName ? true : false}
          />
          <LoloftErrorMessage parentState={this.state} name="buildingName" />
          <Form.Input
            onChange={this.onChange}
            label={builtYearInput.label}
            name="builtYear"
            type="number"
            placeholder={builtYearInput.placeholder}
            value={this.state.builtYear}
            error={this.state.errors.builtYear ? true : false}
          />
          <LoloftErrorMessage parentState={this.state} name="builtYear" />
          <Form.Input
            onChange={this.onChange}
            label={lastRenovatedInput.label}
            name="lastRenovated"
            type="number"
            placeholder={lastRenovatedInput.placeholder}
            value={this.state.lastRenovated}
            error={this.state.errors.lastRenovated ? true : false}
          />
          <LoloftErrorMessage parentState={this.state} name="lastRenovated" />
          <div className="suffix">
            <Form.Input
              onChange={this.onChange}
              label={propertySizeInput.label}
              name="propertySize"
              type="number"
              placeholder={propertySizeInput.placeholder}
              value={this.state.propertySize}
              error={this.state.errors.propertySize ? true : false}
            />
            <div className="value">{propertySizeInput.unit}</div>
          </div>
          <LoloftErrorMessage parentState={this.state} name="propertySize" />
          {this.renderImagesPanel()}
          <Form.Button type="submit" primary fluid onClick={this.onSubmit}>
            {buttonLabel}
          </Form.Button>
        </Form>
      </Fragment>
    );
  };

  //props
  //mobile - is this a view for mobile device
  //open - is this step currently in focus/ opened
  //competed - is this step already completed
  //setCompletedStep - will set this step as compete in parent,
  //which will make parent switch to the next step
  render() {
    const { open, completed, mobile } = this.props;
    if (mobile) {
      if (open) return this.renderStep();
      else return null;
    } else {
      if (open) return this.renderStep();
      else {
        if (completed) return this.renderCompletedInformation();
        else return this.renderIncompleteInformation();
      }
    }
  }
}

const mapStateToProps = (state) => {
  return { propertyDetails: state.onboarding.propertyDetails };
};

export default connect(mapStateToProps, { submitPropertyDetails })(
  PropertyDetailsStep
);
