import debounce from "lodash/debounce";
import firebase from "../../config/firebase";
import history from "../../history.js";

import {
  SET_USER,
  REMOVE_USER,
  PHONE_VERIFIED,
  SET_VERIFIED_USER,
} from "../types";

const USER_NOT_FOUND = "user-not-found";
const WRONG_PASSWORD = "wrong-password";
const TOO_MANY_REQUESTS = "too-many-requests";
const USER_DISABLED = "user-disabled";
const EXPIRED_ACTION_CODE = "expired-action-code";
const INVALID_ACTION_CODE = "invalid-action-code";
const WEAK_PASSWORD = "weak-password";
const EMAIL_ALREADY_IN_USE = "email-already-in-use";
const ALREADY_EXISTS = "already-exists";
const INVALID_ARGUMENT = "invalid-argument";
const UNAUTHENTICATED = "unauthenticated";
const NOT_FOUND = "not-found";
const INTERNAL = "not-found";

//Async register action, that will call firebase auth api
//On success will dispatch REGISTER type action, that will
export const register = async (registrationData) => {
  const {
    email,
    password,
    firstName,
    lastName,
    phone,
    companyName,
    companyAddress,
  } = registrationData;
  try {
    //checking uniqnuess of phone number
    //error will be thrown if it's not
    const phoneCheck = firebase.functions().httpsCallable("checkPhone");
    await phoneCheck({ phone });
    const res = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    //if user creation is successfull, we will add data to userDetails
    //collection
    var userDetails = { firstName, lastName, phone, email };
    if (companyAddress && companyName) {
      userDetails.companyAddress = companyAddress;
      userDetails.companyName = companyName;
    }
    //adding additional user details to firebase
    await firebase
      .firestore()
      .collection("userDetails")
      .doc(`${res.user.uid}`)
      .set(userDetails, { merge: true });
  } catch (error) {
    if (error.code) {
      if (error.code.includes(EMAIL_ALREADY_IN_USE)) {
        var e = new Error("This email is already in use");
        e.name = "email";
        throw e;
      } else if (error.code.includes(TOO_MANY_REQUESTS)) {
        e = new Error("Too many requests. Please try later");
        e.name = "auth";
        throw e;
      } else if (error.code.includes(ALREADY_EXISTS)) {
        e = new Error("This phone number is already used.");
        e.name = "phone";
        throw e;
      }
    }
    console.error(error.code, error.message);
  }
};

export const emailVerification = async (token) => {
  try {
    const verifyEmail = firebase.functions().httpsCallable("verifyEmail");
    await verifyEmail({ token });
  } catch (err) {
    throw new Error("Email verification failed.");
  }
};

//Async login action, that will call firebase auth api
//On success will dispatch LOGIN type action, that will add user to app state
//On fail will throw Error
export const login = (email, password) => async (dispatch) => {
  try {
    const resp = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    const userDetails = await firebase
      .firestore()
      .collection("userDetails")
      .doc(resp.user.uid)
      .get();
    dispatch({
      type: SET_USER,
      payload: { ...resp.user, ...userDetails.data() },
    });
  } catch (error) {
    if (error.code) {
      if (
        error.code.includes(USER_NOT_FOUND) ||
        error.code.includes(WRONG_PASSWORD) ||
        error.code.includes(USER_DISABLED)
      ) {
        throw new Error("Incorrect email or password");
      } else if (error.code.includes(TOO_MANY_REQUESTS)) {
        throw new Error("Too many requests. Please try later");
      }
    }
    console.error(error.code, error.message);
  }
};

export const sendSmsVerificationCode = (phone) => async (dispatch) => {
  try {
    const sendSmsCode = firebase.functions().httpsCallable("sendCode");
    await sendSmsCode({ phone });
  } catch (err) {
    console.error(err);
  }
};

export const smsVerification = (code, setFlag) => async (dispatch) => {
  try {
    const verifyCode = firebase.functions().httpsCallable("verifyCode");
    await verifyCode({ code, setFlag });
    dispatch({ type: PHONE_VERIFIED });
  } catch (error) {
    if (error.code) {
      if (error.code.includes(INVALID_ARGUMENT)) {
        throw new Error("Code is not valid");
      }
      if (error.code.includes(NOT_FOUND)) {
        throw new Error("Your phone number is not found.");
      }
      if (error.code.includes(UNAUTHENTICATED)) {
        throw new Error(
          "Please, log in before trying to send SMS verification code."
        );
      }
      if (error.code.includes(INTERNAL)) {
        throw new Error(
          "Your verification code has expired. Please try again."
        );
      }
    }
    console.error(error);
    throw new Error("Something went wrong. Please try again.");
  }
};

//Async reset password action, that will call firebase auth api
//On success api will send email with reset password link
//On fail will throw Error
export const resetPasswordRequest = (email) => async () => {
  try {
    await firebase.auth().sendPasswordResetEmail(email);
    localStorage.setItem("recoverEmail", email);
    history.push("/login/forgotten-password-sent");
  } catch (error) {
    if (error.code) {
      if (error.code.includes(USER_NOT_FOUND)) {
        throw new Error(`User with password ${email} not found`);
      } else if (error.code.includes(TOO_MANY_REQUESTS)) {
        throw new Error("Too many requests. Please try later");
      }
    }
    console.error(error.code, error.message);
  }
};

//Async reset password action, that will call firebase auth api
//On success api will send email with reset password link
//On fail will throw Error
export const setNewPassword = (code, password) => async () => {
  try {
    await firebase.auth().confirmPasswordReset(code, password);
    localStorage.removeItem("recoverEmail");
    history.push("/login");
  } catch (error) {
    if (error.code) {
      if (error.code.includes(EXPIRED_ACTION_CODE)) {
        throw new Error(`Your reset password token has expired`);
      } else if (error.code.includes(INVALID_ACTION_CODE)) {
        throw new Error(`Your reset password token is invalid`);
      } else if (error.code.includes(WEAK_PASSWORD)) {
        throw new Error(`Your password is too weak`);
      } else if (error.code.includes(TOO_MANY_REQUESTS)) {
        throw new Error("Too many requests. Please try later");
      }
    }
    console.error(error.code, error.message);
  }
};

//Async reset password action, that will call firebase auth api
//On success api will send email with reset password link
//On fail will throw Error
export const logout = () => async (dispatch, getState) => {
  try {
    if (getState().auth.user) {
      await firebase
        .firestore()
        .collection("userDetails")
        .doc(getState().auth.user.uid)
        .set({ phoneCheck: false }, { merge: true });
    }
    await firebase.auth().signOut();
    dispatch({
      type: REMOVE_USER,
    });
  } catch (error) {
    console.error(error.code, error.message);
  }
};

//this action will be called when auth firebase sdk is initialized
//and will be used to detect if there is an existing session
//we will also set phone verified app state, because we do not need
//to do sms verification
export const isLoggedIn = () => async (dispatch) => {
  try {
    const currentUser = await firebase.auth().currentUser;
    if (currentUser) {
      const userDetails = await firebase
        .firestore()
        .collection("userDetails")
        .doc(currentUser.uid)
        .get();

      if (userDetails.data().phoneCheck) {
        dispatch({
          type: SET_VERIFIED_USER,
          payload: { ...currentUser, ...userDetails.data() },
        });
      } else {
        dispatch({
          type: SET_USER,
          payload: { ...currentUser, ...userDetails.data() },
        });
      }
    } else {
      dispatch(logout);
    }
  } catch (error) {
    console.error(error.code, error.message);
  }
};
