import React, { Component } from "react";
import { connect } from "react-redux";

import LoloftNavbarComputer from "./LoloftNavbarComputer";
import LoloftNavbarMobile from "./LoloftNavbarMobile";

import { logout } from "../../../store/actions/auth";
import "../../../assets/css/loloft-v2/loloft-navbar.scss";

class LoloftNavbar extends Component {
  render() {
    return (
      <div className="navbar">
        <div className="default">
          <LoloftNavbarComputer
            history={this.props.history}
            user={this.props.auth.user}
          />
        </div>
        <div className="mobile">
          <LoloftNavbarMobile
            history={this.props.history}
            user={this.props.auth.user}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { auth: state.auth };
};

export default connect(mapStateToProps, { logout })(LoloftNavbar);
