export const cities = {
  title: "Cities",
  links: [
    { title: "New York", to: "" },
    { title: "San Francisco Bay Area", to: "" },
    { title: "Los Angeles", to: "" },
    { title: "Chicago", to: "" },
    { title: "Boston", to: "" },
    { title: "Washington, D.C.", to: "" },
    { title: "Seattle", to: "" }
  ]
};

export const company = {
  title: "Company",
  links: [
    { title: "About Us", to: "" },
    { title: "Partners", to: "" },
    { title: "Careers", to: "" },
    { title: "Press", to: "" },
    { title: "Contact Us", to: "" }
  ]
};

export const forOnwers = {
  title: "For Owners",
  links: [
    { title: "Rent your Property", to: "" },
    { title: "Owner Benefits", to: "" },
    { title: "Property Requirements", to: "" },
    { title: "Landlord Resources Blog", to: "" }
  ]
};

export const policyAndTerms = {
  links: [
    { title: "Privacy policy", to: "" },
    { title: "Terms & Conditions", to: "" },
    { title: "Cookie Policy", to: "" },
    { title: "Rental Agreement", to: "" },
    { title: "Property Owners", to: "" }
  ]
};

export const stayUpdated = {
  title: "Stay Updated",
  info: "Be the first to know about Lolofts in your area",
  input: {
    placeholder: "Email Address",
    label: "Sign Up"
  }
};

export const copyright = {
  title: " Copyright © 2020 Loloft. All rights reserved."
};
