import omit from "lodash/omit";
import firebase from "../../config/firebase";

import { SET_USER } from "../types";

export const updateUserAccount = (name, data) => async (dispatch, getState) => {
  try {
    const { user } = getState().auth;
    var userData = omit(data, "errors");
    userData = omit(userData, "successMessage");

    await firebase
      .firestore()
      .collection("userDetails")
      .doc(user.uid)
      .update({ [name]: userData });
    user[name] = userData;
    dispatch({ type: SET_USER, payload: user });
  } catch (e) {
    var err = new Error();
    err.name = "error";
    err.message = "Update failed";
    throw err;
  }
};

export const updateAccountSecurity = async (email, password) => {
  try {
    const updateSecurity = firebase.functions().httpsCallable("updateSecurity");
    await updateSecurity({ email, password });
  } catch (e) {
    var err = new Error();
    err.name = "error";
    err.message = "Update failed";
    throw err;
  }
};
