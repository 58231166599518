import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce";

import { updateAccountSecurity } from "../../store/actions/account";
import LoloftErrorMessage from "../../components/common/LoloftErrorMessage";
import LoloftSuccessMessage from "../../components/common/LoloftSuccessMessage";
import LoloftSmsCodeVerification from "../../components/common/LoloftSmsCodeVerification";
import { securityData } from "../../statics/pages/account-page";
import { validateEmail, validatePassword } from "../../utils/validation";

class Security extends Component {
  state = { showSmsVerification: false, errors: {} };

  onVerification = async () => {
    await updateAccountSecurity(this.state.email, this.state.password);
    this.setState({
      successMessage: "Update Successful",
      showSmsVerification: false,
      email: "",
      password: "",
    });
    debounce(2000, () => this.setState({ successMessage: undefined }));
  };

  showSmsVerification = (value) => {
    this.setState({ showSmsVerification: value });
  };

  //returns js object with property named as field where error has occurred
  validate = () => {
    let errors = {};
    const emailError = validateEmail(this.state.email, false);
    const passwordError = validatePassword(this.state.password, false);
    if (emailError) {
      errors.email = emailError;
    }
    if (passwordError) {
      errors.password = passwordError;
    }
    return errors;
  };

  submitSecurityUpdate = (e) => {
    e.preventDefault();
    const errors = this.validate();
    if (!isEmpty(errors)) {
      return this.setState({ errors });
    }
    if (this.isAnyFieldSet()) {
      this.showSmsVerification(true);
    }
  };

  isAnyFieldSet() {
    return this.state.email || this.state.password;
  }

  //controlls input fields and removes field error message on field input change
  onChange = (e, data) => {
    const { name, value } = data;
    const errors = { ...this.state.errors, [name]: undefined, auth: undefined };
    this.setState({ [name]: value, errors });
  };

  renderSecurityForm = () => {
    const { email, password, continueButtonLabel } = securityData;
    return (
      <Form onSubmit={this.submitSecurityUpdate} success error>
        <LoloftSuccessMessage parentState={this.state} />
        <Form.Input
          onChange={this.onChange}
          label={email.label}
          name="email"
          placeholder={email.placeholder}
          value={this.state.email}
          error={
            this.state.errors.email || this.state.errors.auth ? true : false
          }
          icon="mail"
          iconPosition="left"
        />
        <LoloftErrorMessage parentState={this.state} name="email" />
        <Form.Input
          type="password"
          onChange={this.onChange}
          label={password.label}
          name="password"
          placeholder={password.placeholder}
          value={this.state.password}
          error={this.state.errors.password ? true : false}
          icon="lock"
          iconPosition="left"
        />
        <LoloftErrorMessage parentState={this.state} name="password" />
        <Form.Button
          type="submit"
          primary
          fluid
          disabled={!this.isAnyFieldSet()}
        >
          {continueButtonLabel}
        </Form.Button>
      </Form>
    );
  };

  renderSmsConfirmation = () => {
    const { updateButtonLabel } = securityData;
    return (
      <LoloftSmsCodeVerification
        setFlag="security"
        buttonLabel={updateButtonLabel}
        backAction={() => this.showSmsVerification(false)}
        onSubmit={this.onVerification}
      />
    );
  };

  render() {
    const { title } = securityData;
    let elementToRender;

    if (this.state.showSmsVerification) {
      elementToRender = this.renderSmsConfirmation();
    } else {
      elementToRender = this.renderSecurityForm();
    }
    return (
      <div>
        <h2>{title}</h2>
        {elementToRender}
      </div>
    );
  }
}

export default Security;
