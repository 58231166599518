import React from "react";
import { connect } from "react-redux";

import { creditCard } from "../../../statics/paymentInfo";
import { removePaymentInformation } from "../../../store/actions/onboarding";
import { removePaymentMethod } from "../../../store/actions/payment";

function CreditCardItem(props) {
  const { card, onClick, removePossible } = props;

  const renderRemoveLink = () => {
    if (removePossible) {
      return (
        <div className="loloft-pi-actions">
          <a href="#" onClick={() => removeAction()}>
            {creditCard.removeButtonLabel}
          </a>
        </div>
      );
    }
  };

  const removeAction = async () => {
    try {
      await removePaymentMethod(card.id);
      props.removePaymentInformation(card);
    } catch (err) {
      props.setError(err.message);
    }
  };

  const onClickAction = () => {
    if (onClick) {
      onClick();
    }
  };

  if (card) {
    return (
      <div
        className={`loloft-pi card ${props.selected ? "selected" : ""}`}
        onClick={onClickAction}
      >
        {renderRemoveLink(props)}
        <div className="loloft-pi-number">
          <label>{creditCard.cardNumberInput.label}</label>
          <h3>**** **** **** {card.last4}</h3>
        </div>
        <div className="flex inline spaced">
          <div className="loloft-pi-owner">
            <label>{creditCard.cardHolder}</label>
            <h3>{props.legalName}</h3>
          </div>
          <div className="loloft-pi-valid">
            <label>{creditCard.validThruInput.label}</label>
            <h3>
              {card.exp_month}/{card.exp_year}
            </h3>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default connect(null, { removePaymentInformation })(CreditCardItem);
