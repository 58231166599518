import React from "react";
import { Button } from "semantic-ui-react";

import history from "../../../history";
import ContactSupportButton from "./ContactSupportButton";
import { rejectedStateData } from "../../../statics/pages/account-page";

function RejectedStatus(props) {
  const { user, onboarding } = props;
  const { submitAgainButtonLabel, title, text } = rejectedStateData;
  const renderSubmitAgainButton = () => {
    if (onboarding) {
      console.log(onboarding);
      return (
        <Button onClick={() => history.push(`/member-onboarding/`)}>
          {submitAgainButtonLabel}
        </Button>
      );
    }
  };
  return (
    <div>
      <h2>
        {text} {user.firstName}
      </h2>
      <h1>{title}</h1>
      <p>{onboarding.errorMessage}</p>
      {renderSubmitAgainButton()}
      <ContactSupportButton />
    </div>
  );
}

export default RejectedStatus;
