import React, { Component } from "react";
import { connect } from "react-redux";
import eq from "lodash/eq";
import isEmpty from "lodash/isEmpty";
import { Button, Dimmer, Loader } from "semantic-ui-react";

import { loadPaymentInformation } from "../../store/actions/onboarding";
import BankAccountItem from "../../components/common/loloftPayment/BankAccountItem";
import CreditCardItem from "../../components/common/loloftPayment/CreditCardItem";
import { choosePaymentMethodData } from "../../statics/pages/booking-property-page";

class SelectPaymentMethod extends Component {
  state = { selected: undefined, loaded: false };

  componentDidMount() {
    this.props.loadPaymentInformation();
  }

  renderCreditCards = () => {
    const { creditCards } = this.state;
    if (!isEmpty(creditCards)) {
      return creditCards.map(card => {
        return (
          <CreditCardItem
            card={card}
            key={card.id}
            legalName={`${this.props.user.firstName} ${this.props.user.lastName}`}
            removePossible={false}
            onClick={() => this.setState({ selected: card.id })}
            selected={eq(this.state.selected, card.id)}
          />
        );
      });
    }
  };

  renderBankAccounts = () => {
    const { bankAccounts } = this.state;
    if (!isEmpty(bankAccounts)) {
      return bankAccounts.map(bank => {
        return (
          <BankAccountItem
            bank={bank}
            key={bank.id}
            legalName={`${this.props.user.firstName} ${this.props.user.lastName}`}
            removePossible={false}
            onClick={() => this.setState({ selected: bank.id })}
            selected={eq(this.state.selected, bank.id)}
          />
        );
      });
    }
  };

  renderContent() {
    if (this.state.loaded) {
      return (
        <div>
          {this.renderCreditCards()}
          {this.renderBankAccounts()}
          <p>
            {choosePaymentMethodData.text1} $
            {this.props.bookingData.property.fee}.
          </p>
          <p>{choosePaymentMethodData.text2}</p>
          <Button
            primary
            fluid
            onClick={() => this.props.onPaymentSelect(this.state.selected)}
            disabled={!this.state.selected}
          >
            {choosePaymentMethodData.buttonLabel}
          </Button>
        </div>
      );
    } else {
      return (
        <Dimmer active>
          <Loader size="small"></Loader>
        </Dimmer>
      );
    }
  }

  render() {
    return (
      <div>
        <h1>{choosePaymentMethodData.title}</h1>
        {this.renderContent()}
      </div>
    );
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.loaded) {
      const { creditCards, bankAccounts } = props.paymentInformation;
      if (!isEmpty(creditCards) || !isEmpty(bankAccounts)) {
        return { creditCards, bankAccounts, loaded: true };
      }
    }
    return null;
  }
}

const mapStateToProps = state => {
  return {
    paymentInformation: state.onboarding.paymentInformation,
    user: state.auth.user
  };
};

export default connect(mapStateToProps, { loadPaymentInformation })(
  SelectPaymentMethod
);
