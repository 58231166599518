export const getMinCounter = (counter, filters, dates) => {
  var minCounter = counter.count;
  var criteria = { type: "city", value: "city" };
  if (filters.bedrooms && filters.bedrooms > 0) {
    var bedroomsCount = getBedroomsCount(filters, counter);
    if (bedroomsCount < minCounter) {
      minCounter = bedroomsCount;
      criteria = { type: "bedrooms", value: "bedrooms" };
    }
  }
  if (filters.bathrooms && filters.bathrooms > 0) {
    var bathroomsCount = getBathroomsCount(filters, counter);
    if (bathroomsCount < minCounter) {
      minCounter = bathroomsCount;
      criteria = { type: "bathrooms", value: "bathrooms" };
    }
  }

  if (filters.parkingSpaces && filters.parkingSpaces > 0) {
    var parkingSpacesCount = getParkingSpacesCount(filters, counter);
    if (parkingSpacesCount < minCounter) {
      minCounter = parkingSpacesCount;
      criteria = { type: "parkingSpaces", value: "parkingSpaces" };
    }
  }
  if (
    filters.petsAllowed &&
    filters.petsAllowed.value.localeCompare("yes") === 0
  ) {
    if (parkingSpacesCount < counter.petsAllowed) {
      minCounter = counter.petsAllowed;
      criteria = { type: "parkingSpaces", value: "parkingSpaces" };
    }
  }
  if (filters.budget && filters.budget > 0) {
    var budgetCount = getBudgetCount(filters, counter);
    if (budgetCount < minCounter) {
      minCounter = budgetCount;
      criteria = { type: "price", value: "price" };
    }
  }

  if (filters.amenities && filters.amenities.length > 0) {
    var amenitiesCount = getAmenitiesCount(filters, counter);
    if (amenitiesCount.count < minCounter) {
      minCounter = amenitiesCount.count;
      criteria = { type: "amenities", value: amenitiesCount.criteria };
    }
  }

  if (filters.propertyTypes && filters.propertyTypes.length > 0) {
    var propertyTypesCount = getPropertyTypesCount(filters, counter);
    if (propertyTypesCount < minCounter) {
      minCounter = propertyTypesCount;
      criteria = {
        type: "type",
        value: "type"
      };
    }
  }

  if (dates.propertyState) {
    var propertyStateCount = getPropertyStateCount(dates, counter);
    if (propertyStateCount.count < minCounter) {
      minCounter = propertyStateCount.count;
      criteria = {
        type: "state",
        value: propertyStateCount.criteria
      };
    }
  }

  return { ...criteria, count: minCounter };
};

const getBedroomsCount = ({ bedrooms }, counter) => {
  var bedroomsCount = 0;
  Object.keys(counter.bedrooms).forEach(key => {
    if (key >= bedrooms) bedroomsCount += counter.bedrooms[key];
  });
  return bedroomsCount;
};
const getBathroomsCount = ({ bathrooms }, counter) => {
  var bathroomsCount = 0;
  Object.keys(counter.bathrooms).forEach(key => {
    if (key >= bathrooms) bathroomsCount += counter.bathrooms[key];
  });
  return bathroomsCount;
};
const getParkingSpacesCount = ({ parkingSpaces }, counter) => {
  var parkingSpacesCount = 0;
  Object.keys(counter.parkingSpaces).forEach(key => {
    if (key >= parkingSpaces) parkingSpacesCount += counter.parkingSpaces[key];
  });
  return parkingSpacesCount;
};

const getBudgetCount = ({ budget }, counter) => {
  var budgetCount = 0;
  const budgetValue = Math.floor(budget / 500);
  Object.keys(counter.price).forEach(key => {
    if (key <= budgetValue) budgetCount += counter.price[key];
  });
  return budgetCount;
};

const getPropertyTypesCount = ({ propertyTypes }, counter) => {
  var propertyTypesCount = 0;
  propertyTypes.forEach(type => {
    if (counter[type]) {
      propertyTypesCount += counter[type];
    }
  });
  return propertyTypesCount;
};
const getAmenitiesCount = ({ amenities }, counter) => {
  var amenitiesCount = Number.POSITIVE_INFINITY;
  var criteria;
  amenities.forEach(amenity => {
    if (counter[amenity] && counter[amenity] < amenitiesCount) {
      amenitiesCount = counter[amenity];
      criteria = amenity;
    }
  });
  return { criteria, count: amenitiesCount };
};

const getPropertyStateCount = ({ propertyState }, counter) => {
  var propertyStateCount = 0;
  var criteria;
  if (counter[propertyState]) {
    propertyStateCount = counter[propertyState];
    criteria = propertyState;
  }
  return { criteria, count: propertyStateCount };
};
