import {
  GET_AVAILABLE_LOCATIONS,
  GET_PROPERTIES_COUNT,
  GET_FILTERED_PROPERTIES_COUNT,
  GET_PROPERTIES,
  PROPERTY_NOT_FOUND,
  SET_BOOKING_DATA,
  SET_LOCATION,
  SET_FILTERS,
  SET_DATES,
  GET_PROPERTY,
  NEXT_PAGE,
} from "../types";
import moment from "moment";

const PAGE_SIZE = 4;

const defaultLocation = {
  title: "Miami",
  description: "",
  lat: 25.76231049999999,
  lng: -80.1896457,
  value: "Miami",
  text: "Miami",
};

const defaultDates = {
  date: moment(),
  propertyState: "furnished",
  commitmentType: "full-flexi",
};

const initialState = {
  availableLocations: [],
  location: defaultLocation,
  filters: {},
  dates: defaultDates,
  properties: [],
  propertiesToRender: [],
  count: undefined,
  filteredCount: undefined,
  hasMoreDocs: false,
  containerPage: 1,
  propertyNotFound: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_AVAILABLE_LOCATIONS:
      return { ...state, availableLocations: action.payload };
    case GET_FILTERED_PROPERTIES_COUNT:
      return { ...state, filteredCount: action.payload };
    case GET_PROPERTIES_COUNT:
      return { ...state, count: action.payload };
    case GET_PROPERTIES:
      const { properties, hasMoreDocs, lastLoadedDoc } = action.payload;
      return {
        ...state,
        properties,
        hasMoreDocs,
        lastLoadedDoc,
        propertyNotFound: false,
        propertiesToRender: getPropertiesToRender(
          properties,
          state.containerPage
        ),
      };
    case GET_PROPERTY:
      const addedProperty = [...state.properties, action.payload];
      return {
        ...state,
        properties: addedProperty,
        propertyNotFound: false,
        containerPage: 1,
      };
    case PROPERTY_NOT_FOUND:
      return {
        ...state,
        propertyNotFound: true,
      };
    case SET_BOOKING_DATA:
      return { ...state, bookingData: action.payload };
    case SET_LOCATION:
      return { ...state, location: action.payload, containerPage: 1 };
    case SET_FILTERS:
      return { ...state, filters: action.payload, containerPage: 1 };
    case SET_DATES:
      return { ...state, dates: action.payload, containerPage: 1 };
    case NEXT_PAGE:
      return {
        ...state,
        containerPage: state.containerPage + 1,
        propertiesToRender: getPropertiesToRender(
          state.properties,
          state.containerPage + 1
        ),
      };
    default:
      return state;
  }
};

const getPropertiesToRender = (properties, containerPage) => {
  if (properties) {
    if (properties.length > containerPage * PAGE_SIZE) {
      return properties.slice(0, containerPage * PAGE_SIZE);
    } else {
      return properties;
    }
  } else {
    return [];
  }
};
