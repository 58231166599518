import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";

// const firebaseConfig = {
//   apiKey: "AIzaSyBUIc4H91Y9AFPmmjaz89uxhhAxsfmIO6U",
//   authDomain: "loloft-dev.firebaseapp.com",
//   databaseURL: "https://loloft-dev.firebaseio.com",
//   projectId: "loloft-dev",
//   storageBucket: "loloft-dev.appspot.com",
//   messagingSenderId: "84119664786",
//   appId: "1:84119664786:web:4f6e0a6359d92a986c8e4b",
//   measurementId: "G-E1Y17RMJRQ"
// };

const firebaseConfig = {
  apiKey: "AIzaSyAoLennu3aTUXf5hr_WTpBf4sn_0nrqcnU",
  authDomain: "global-river-258802.firebaseapp.com",
  databaseURL: "https://global-river-258802.firebaseio.com",
  projectId: "global-river-258802",
  storageBucket: "global-river-258802.appspot.com",
  messagingSenderId: "129400892052",
  appId: "1:129400892052:web:e6c72a2ad82362a27ea281",
  measurementId: "G-T2PS07YX5B"
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();
firebase.storage();
firebase.functions();

export default firebase;
