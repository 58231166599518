import React from "react";
import { connect } from "react-redux";

import { Checkbox } from "semantic-ui-react";
import { propertiesContainerData } from "../../statics/pages/browse-properties-page";

function PropertiesAvailableCount(props) {
  return (
    <div className="headline">
      <h3>{renderFoundTitle(props)}</h3>
      <span className="ui yellow label">
        <Checkbox
          label={propertiesContainerData.availableLabel}
          onChange={onAvailableTodayChange}
        />
      </span>
    </div>
  );
}

const renderFoundTitle = props => {
  const { count } = props;
  if (count === undefined) return null;
  return `${count} Lolofts`;
};

const onAvailableTodayChange = (e, { value }) => {};

const mapStateToProps = state => {
  const { count, filters, dates } = state.properties;
  return {
    count,
    filters,
    dates
  };
};

export default connect(mapStateToProps)(PropertiesAvailableCount);
