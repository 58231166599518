import omit from "lodash/omit";
import eq from "lodash/eq";

import firebase from "../../config/firebase";

import {
  ONBOARDING_CURRENT_ADDRESS,
  ONBOARDING_EMPLOYMENT_DETAILS,
  ONBOARDING_ADD_PAYMENT_INFORMATION,
  ONBOARDING_REMOVE_PAYMENT_INFORMATION,
  ONBOARDING_LOAD_PAYMENT_INFORMATION,
  ONBOARDING_PERSONAL_INFORMATION,
  ONBOARDING_ABOUT_PROPERTY,
  ONBOARDING_PROPERTY_ADDRESS,
  ONBOARDING_PROPERTY_DETAILS,
  GET_ONBOARDING_DATA,
} from "../types";

export const submitCurrentAddress = (currentAddress) => (dispatch) => {
  dispatch({ type: ONBOARDING_CURRENT_ADDRESS, payload: currentAddress });
};
export const submitEmploymentDetails = (employmentDetails) => (dispatch) => {
  dispatch({ type: ONBOARDING_EMPLOYMENT_DETAILS, payload: employmentDetails });
};
export const submitPersonalInformation = (personalInformation) => (
  dispatch
) => {
  dispatch({
    type: ONBOARDING_PERSONAL_INFORMATION,
    payload: personalInformation,
  });
};
export const submitAboutPropertyInformation = (aboutProperty) => (dispatch) => {
  dispatch({
    type: ONBOARDING_ABOUT_PROPERTY,
    payload: aboutProperty,
  });
};

export const submitPropertyAddress = (propertyAddress) => (dispatch) => {
  dispatch({
    type: ONBOARDING_PROPERTY_ADDRESS,
    payload: propertyAddress,
  });
};

export const submitPropertyDetails = (propertyDetails) => (dispatch) => {
  dispatch({
    type: ONBOARDING_PROPERTY_DETAILS,
    payload: propertyDetails,
  });
};

export const addPaymentInformation = (paymentMethod) => (dispatch) => {
  const creditCard = eq(paymentMethod.object, "card")
    ? paymentMethod
    : undefined;
  const bankAccount = eq(paymentMethod.object, "bank_account")
    ? paymentMethod
    : undefined;
  dispatch({
    type: ONBOARDING_ADD_PAYMENT_INFORMATION,
    payload: { creditCard, bankAccount },
  });
};

export const removePaymentInformation = (paymentMethod) => (dispatch) => {
  dispatch({
    type: ONBOARDING_REMOVE_PAYMENT_INFORMATION,
    payload: paymentMethod,
  });
};

export const loadPaymentInformation = () => async (dispatch, getState) => {
  if (!getState().auth.user || !getState().auth.user.uid) return;
  const sourcesCollection = await firebase
    .firestore()
    .collection("userDetails")
    .doc(getState().auth.user.uid)
    .collection("sources")
    .get();
  var bankAccounts = [];
  var creditCards = [];
  sourcesCollection.forEach((source) => {
    if (eq(source.data().object, "card")) {
      creditCards.push(source.data());
    }
    if (eq(source.data().object, "bank_account")) {
      bankAccounts.push(source.data());
    }
  });
  dispatch({
    type: ONBOARDING_LOAD_PAYMENT_INFORMATION,
    payload: { bankAccounts, creditCards },
  });
};

export const submitPropertyOnboardingInformation = () => async (
  dispatch,
  getState
) => {
  if (!getState().auth.user || !getState().auth.user.uid) return;
  try {
    const data = getState().onboarding;
    var onboarding = {};
    onboarding.uid = getState().auth.user.uid;
    onboarding.aboutProperty = omit(data.aboutProperty, "errors");
    onboarding.propertyAddress = omit(data.propertyAddress, "errors");
    onboarding.propertyDetails = omit(data.propertyDetails, "errors");
    await firebase
      .firestore()
      .collection("propertyOnboarding")
      .doc(data.propertyDetails.propertyRef)
      .set(onboarding);
  } catch (error) {
    console.error(error);
    throw new Error("Your request failed. Please try again.");
  }
};

export const submitMemberOnboardingInformation = () => async (
  dispatch,
  getState
) => {
  if (!getState().auth.user || !getState().auth.user.uid) return;
  try {
    const data = getState().onboarding;
    var onboarding = {};
    onboarding.personalInformation = omit(data.personalInformation, "errors");
    onboarding.currentAddress = omit(data.currentAddress, "errors");
    onboarding.employmentDetails = omit(data.employmentDetails, "errors");
    //onboarding.paymentInformation = omit(data.paymentInformation, "errors");
    const onboardMember = firebase.functions().httpsCallable("onboardMember");
    await onboardMember(onboarding);
    // await firebase
    //   .firestore()
    //   .collection("userDetails")
    //   .doc(getState().auth.user.uid)
    //   .update(onboarding);
  } catch (error) {
    console.error(error);
    throw new Error("Your request failed. Please try again.");
  }
};

export const getMemberOnboardingInformation = (user) => async (dispatch) => {
  try {
    const onboardingInformation = await firebase
      .firestore()
      .collection("userDetails")
      .doc(user.uid)
      .get();
    if (onboardingInformation.exists) {
      dispatch({
        type: GET_ONBOARDING_DATA,
        payload: onboardingInformation.data(),
      });
    } else {
      console.error(`Onboarding info doesn't exist`);
    }
  } catch (error) {
    console.error(error);
  }
};
