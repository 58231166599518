import React from "react";

import { propertyPage } from "../../statics/pages/property-page";

function PropertySleepingArrangements(props) {
  if (props.property.sleepingArrangements) {
    return (
      <div className="box">
        <h3>{propertyPage.sleepingArrangements.title}</h3>
        <div>{renderSleepingArrangements(props.property)}</div>
      </div>
    );
  }
  return null;
}

//for every sleeping arangement on property object, there will be
//an object describing that arrangement in static file
//we will use those objects to render icon and text for sleeping arrangement
const renderSleepingArrangements = property => {
  const { arrangements } = propertyPage.sleepingArrangements;
  return property.sleepingArrangements.map(sa => {
    const arrangement = arrangements.find(a => {
      return a.key.localeCompare(sa.key) === 0;
    });
    if (arrangement) {
      return (
        <div className="flex image-spaced right" key={arrangement.key}>
          <img src={arrangement.image} alt="" />
          {arrangement.value}
          {arrangement.size}
        </div>
      );
    } else {
      return null;
    }
  });
};

export default PropertySleepingArrangements;
