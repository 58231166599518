import React from "react";
import { Message, Transition } from "semantic-ui-react";

//Wrapper for Message component from semantic ui
//if wrapper receives state and name where state.errors[name].text is existing
//component will render message
const LoloftSuccessMessage = (props) => {
  const { parentState } = props;
  const visible = !!(parentState && parentState.successMessage);
  const content = visible ? parentState.successMessage : "";
  return (
    <Transition visible={visible} animation="scale" duration={500}>
      <Message success content={content} />
    </Transition>
  );
};

export default LoloftSuccessMessage;
