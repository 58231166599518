import React from "react";
import { Button } from "semantic-ui-react";

import history from "../../../history";
import { pendingStateData } from "../../../statics/pages/account-page";
import ContactSupportButton from "./ContactSupportButton";

function PendingStatus(props) {
  const { text, viewPropertyButtonLabel, title } = pendingStateData;
  const renderViewPropertyButton = () => {
    const { booking } = props;
    if (booking) {
      console.log(booking);
      return (
        <Button
          onClick={() => history.push(`/properties/${booking.propertyId}`)}
        >
          {viewPropertyButtonLabel}
        </Button>
      );
    }
  };

  return (
    <div>
      <h1>{title}</h1>
      <p>{text}</p>
      {renderViewPropertyButton()}
      <ContactSupportButton />
    </div>
  );
}

export default PendingStatus;
