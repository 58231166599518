import React from "react";

import { Popup } from "semantic-ui-react";
import LoloftInfoButton from "../../../components/common/LoloftInfoButton";

const CommitmentCard = props => {
  const { card, isMobile } = props;
  return (
    <div className="commitment-card">
      <img className="ui image" src={card.image} alt="" />
      <h3>{card.title}</h3>
      <p>{card.info}</p>
      {isMobile ? null : renderFooter(card)}
    </div>
  );
};

//if commitment card has a footer text, this method will return div containing footer
const renderFooter = card => {
  let footer;
  const { additionalData } = card;
  if (additionalData) {
    footer = (
      <div className="info">
        {additionalData.text}
        {renderPopup(additionalData.popup)}
      </div>
    );
  }
  return footer;
};

//if there is popup data, semantic ui component
//popup will be returned
const renderPopup = popupData => {
  if (popupData) {
    const { title, info, asterisk } = popupData;
    return (
      <Popup trigger={<LoloftInfoButton />} basic position="bottom center">
        <h4>{title}</h4>
        <p>{info}</p>
        <small>{asterisk}</small>
      </Popup>
    );
  }
  return null;
};

export default CommitmentCard;
