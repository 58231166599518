import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid } from "semantic-ui-react";
import { parse } from "query-string";

import history from "../../history";
import { logout, emailVerification } from "../../store/actions/auth";
import { submitMemberOnboardingInformation } from "../../store/actions/onboarding";
import YourAccountStep from "../../components/common/loloft-user-profile/YourAccountStep";
import PersonalInformationStep from "../../components/common/loloft-user-profile/PersonalInformationStep";
import EmploymentDetailsStep from "../../components/common/loloft-user-profile/EmploymentDetailsStep";
import CurrentAddressStep from "../../components/common/loloft-user-profile/CurrentAddressStep";
import PaymentInformationStep from "../../components/common/loloft-user-profile/PaymentInformationStep";
import PhotoIDStep from "../../components/common/loloft-user-profile/PhotoIDStep";
import DoneStep from "../../components/common/loloft-user-profile/DoneStep";
import OnboardingStep from "../../components/common/OnboardingStep";
import LoloftProgressBar from "../../components/common/LoloftProgressBar";
import { injectOnboardingPageStyle } from "../../utils/bodyStyleInjector";

import "../../assets/css/loloft-v2/onboarding.scss";

class MemberOnboardingPage extends Component {
  state = { completedStep: 0, currentStep: 1, changeAccount: false };

  componentDidMount() {
    const params = parse(this.props.location.search);
    if (!params.token) {
      //if there is no token param present in url, redirect to login
      return history.push("/login");
    }
    this.setState({ token: params.token });
    this.shouldRedirectToAccountPage();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (!prevProps.user) {
      this.shouldRedirectToAccountPage();
    }
    try {
      if (this.state.currentStep == 7 && prevState.currentStep == 6) {
        //if user has completed onboarding submit onboarding information
        await emailVerification(this.state.token);
        await this.props.submitMemberOnboardingInformation();
      }
    } catch (err) {
      this.setState({ error: err.message });
    }
  }

  shouldRedirectToAccountPage = () => {
    const { user } = this.props;
    const emailVerified = user ? user.emailVerified : false;
    if (emailVerified) {
      history.push("/account");
    }
  };

  //this method is used to switch back to first step, if user decides to change account
  setChangeAccount = (value) => {
    if (value) {
      this.props.logout();
    }
    this.setState({ changeAccount: value });
  };

  //this method is used when user completes one step of onboarding
  setCompletedStep = (step) => {
    if (step > this.state.completedStep) {
      this.setState({ completedStep: step, currentStep: step + 1 });
    }
  };

  renderSteps = (mobile = false) => {
    const { currentStep, completedStep, changeAccount } = this.state;
    var i = 1;
    return (
      <div className="basic box">
        <LoloftProgressBar currentStep={completedStep} totalSteps={6} />
        <OnboardingStep
          mobile={mobile}
          open={currentStep === i} //i == 1
          completed={completedStep >= i}
          step={i++}
          path={history.location.pathname}
          setCompletedStep={this.setCompletedStep}
          changeAccount={changeAccount}
          setChangeAccount={this.setChangeAccount}
          owner="member-onboarding"
        >
          <YourAccountStep />
        </OnboardingStep>
        <OnboardingStep
          mobile={mobile}
          open={!changeAccount && currentStep === i} //i ==2
          completed={completedStep >= i}
          step={i++}
          setCompletedStep={this.setCompletedStep}
        >
          <PersonalInformationStep />
        </OnboardingStep>
        <OnboardingStep
          mobile={mobile}
          open={currentStep === i} //i==3
          completed={completedStep >= i}
          step={i++}
          setCompletedStep={this.setCompletedStep}
        >
          <EmploymentDetailsStep />
        </OnboardingStep>
        <OnboardingStep
          mobile={mobile}
          open={currentStep === i} //i==4
          completed={completedStep >= i}
          step={i++}
          setCompletedStep={this.setCompletedStep}
        >
          <CurrentAddressStep />
        </OnboardingStep>
        <OnboardingStep
          mobile={mobile}
          open={currentStep === i} //i==5
          completed={completedStep >= i}
          step={i++}
          setCompletedStep={this.setCompletedStep}
        >
          <PaymentInformationStep />
        </OnboardingStep>
        <OnboardingStep
          mobile={mobile}
          open={currentStep === i} //i==6
          completed={completedStep >= i}
          step={i++}
          setCompletedStep={this.setCompletedStep}
        >
          <PhotoIDStep />
        </OnboardingStep>
        <OnboardingStep
          mobile={mobile}
          open={currentStep === i} //i==7
          completed={completedStep >= i}
          step={i++}
          setCompletedStep={this.setCompletedStep}
        >
          <DoneStep />
        </OnboardingStep>
      </div>
    );
  };

  renderComputer = () => {
    return (
      <Grid stackable reversed="mobile">
        <Grid.Column verticalAlign="middle" width={8}>
          {this.renderSteps()}
        </Grid.Column>
        <Grid.Column width={8} className="login-bg"></Grid.Column>
      </Grid>
    );
  };

  render() {
    injectOnboardingPageStyle();
    return <div className="login-switch">{this.renderComputer()}</div>;
  }
}

const mapStateToProps = (state) => {
  return { user: state.auth.user };
};

export default connect(mapStateToProps, {
  logout,
  submitMemberOnboardingInformation,
})(MemberOnboardingPage);
