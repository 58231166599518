import React, { Component } from "react";
import { connect } from "react-redux";

import { Grid, Button, Container } from "semantic-ui-react";
import SearchBar from "./SearchBar";

import ModalDialog from "../modal/ModalDialog";
import SelectDates from "./SelectDates";
import Filters from "./Filters";
import PropertiesContainer from "./PropertiesContainer";
import PropertiesMap from "./PropertiesMap";

import {
  getProperties,
  getPropertiesCount
} from "../../store/actions/properties";

// Browse css
import "../../assets/css/loloft-v2/browse.scss";

import { propertiesPage } from "../../statics/pages/browse-properties-page";
import PropertiesAvailableCount from "./PropertiesAvailableCount";
import { injectBrowsePageStyle } from "../../utils/bodyStyleInjector";

class BrowsePropertiesPage extends Component {
  //two different set of states are user showSetData[Mobile] and showFilters[Mobile]
  //which will be used on mobile or desktop versions of app
  //this separation is done because otherwise modal popups would appear on mobile versions
  state = {
    showSetDate: false,
    showFilters: false,
    showMap: false
  };

  componentDidMount() {
    this.fetchPropertiesAndCount();
  }

  fetchPropertiesAndCount = () => {
    this.props.getProperties();
    this.props.getPropertiesCount();
  };

  showFilters = () => {
    this.setState({ showFilters: true });
  };

  hideFilters = () => {
    this.setState({ showFilters: false });
  };

  showSetDate = () => {
    this.setState({ showSetDate: true });
  };

  hideSetDate = () => {
    this.setState({ showSetDate: false });
  };

  toggleMap = () => {
    this.setState({ showMap: !this.state.showMap });
  };

  renderSetDatesButton = (mobile = false) => {
    return (
      <Button onClick={() => this.showSetDate(mobile)}>
        <img src="/assets/icons/icon-calendar.svg" alt="" />
        {propertiesPage.selectDatesButton}
      </Button>
    );
  };

  renderShowFilterButton = (mobile = false) => {
    return (
      <Button onClick={() => this.showFilters(mobile)}>
        <img src="/assets/icons/icon-filters.svg" alt="" />
        {propertiesPage.setFiltersButton}
      </Button>
    );
  };

  renderToggleMapButton = () => {
    return (
      <Button
        className={`single loloft-map-trigger ${this.state.showMap ? "active" : ""}`}
        onClick={() => this.toggleMap()}
      >
      </Button>
    );
  };

  renderPropertiesMap = () => {
    return <PropertiesMap />;
  };

  renderComputer = () => {
    return (
      <Container fluid className="left pushed">
        <Grid stackable className="negative">
          <Grid.Row columns={16} className="nopad">
            <div className="browse headline">
              <SearchBar onSubmit={this.fetchPropertiesAndCount} />
              <div className="actions">
                <ModalDialog
                  closeIcon
                  className="filters dates"
                  trigger={this.renderSetDatesButton(false)}
                  title={propertiesPage.selectDatesModalTitle}
                  onClose={() => this.hideSetDate(false)}
                  open={this.state.showSetDate}
                >
                  <SelectDates
                    closeAction={() => this.hideSetDate(false)}
                    onSubmit={this.fetchPropertiesAndCount}
                  />
                </ModalDialog>
                <ModalDialog
                  closeIcon
                  className="filters options"
                  trigger={this.renderShowFilterButton()}
                  title={propertiesPage.setFiltersModalTitle}
                  onClose={() => this.hideFilters()}
                  open={this.state.showFilters}
                >
                  <Filters
                    closeAction={() => this.hideFilters()}
                    onSubmit={this.fetchPropertiesAndCount}
                  />
                </ModalDialog>
                {this.renderToggleMapButton()}
              </div>
            </div>
          </Grid.Row>
          <Grid.Row columns={16} className="lowered">
            <Grid.Column width={9}>
              <PropertiesAvailableCount />
              <PropertiesContainer />
            </Grid.Column>
            <Grid.Column
              width={7}
              className={`loloft-map ${this.state.showMap ? "active" : ""}`}
            >
              {this.renderPropertiesMap()}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  };

  render() {
    injectBrowsePageStyle();
    return (
      <div className="ui mobile-negative grid">
        <div className="sixteen column row">{this.renderComputer()}</div>
      </div>
    );
  }
}

export default connect(null, { getProperties, getPropertiesCount })(
  BrowsePropertiesPage
);
