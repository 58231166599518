import React, { Component } from "react";
import { Popup, Dropdown } from "semantic-ui-react";
import { Link } from "react-router-dom";
import LoloftInfoButton from "../../components/common/LoloftInfoButton";
import { priceBreakdown } from "../../statics/commitment-types";

import LoloftDropdown from "../../components/common/LoloftDropdown";

//this component will be show as a popup when user
//clicks to see price breakdown on right side of single prperty page
class PriceBreakdownPopup extends Component {
  constructor(props) {
    super(props);
    const options = priceBreakdown.commitmentType;
    const { value, multiplier } = options[0];
    this.state = { value, multiplier };
  }

  //on dropdown item select, a new value will be set inside state for mutiplier
  //and that value will be used for calculating price
  onCommitmentChange = (e, { value }) => {
    const selectedOption = priceBreakdown.commitmentType.find(
      type => type.value.localeCompare(value) === 0
    );
    if (selectedOption) {
      this.setState({ value, multiplier: selectedOption.multiplier });
    }
  };

  calculatePrice = () => {
    const { price } = this.props.property;
    return Math.ceil(price * this.state.multiplier);
  };

  render() {
    const options = priceBreakdown.commitmentType;
    if (priceBreakdown && this.props.property) {
      return (
        <Popup
          className="breakdown"
          trigger={<LoloftInfoButton />}
          on="click"
          basic
          position="bottom center"
        >
          <div>
            <div className="header">
              <h4>{priceBreakdown.title}</h4>
              <Link to={this.props.backLink}>
                <img src="/assets/icons/icon-close.svg" />
              </Link>
            </div>
            <LoloftDropdown
              className="fluid"
              options={options}
              value={this.state.value}
              onChange={this.onCommitmentChange}
              dropdownLabel="Commitment Type"
              fluid
            />
            <div className="loloft-timeline">
              <div className="row">
                <div className="row-contents">
                  <label>{priceBreakdown.monthly}</label>
                  <strong>${this.calculatePrice()}</strong>
                </div>
              </div>
              <div className="row">
                <div className="row-contents">
                  <label>{priceBreakdown.deposit.text}{" "}</label>
                  <strong>${this.calculatePrice()}</strong>
                </div>
              </div>
              <div className="row row-info">
                {priceBreakdown.deposit.description}
              </div>
              <div className="row">
                <div className="row-contents">
                  <label>{priceBreakdown.registrationFee.text}{" "}</label>
                  <strong>${priceBreakdown.registrationFee.value}</strong>
                </div>
              </div>
              <div className="row row-info">
                {priceBreakdown.registrationFee.description}
              </div>
            </div>
          </div>
        </Popup>
      );
    }
  }
}

export default PriceBreakdownPopup;
