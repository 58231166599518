import React from "react";

import LoloftAmenityCard from "../../components/common/LoloftAmenityCard";
import { propertyPage } from "../../statics/pages/property-page";

function PropertyAmenities(props) {
  const { amenities, petsAllowed } = props.property;
  if (amenities)
    return (
      <div className="box">
        <h3>{propertyPage.amenities}</h3>
        <div className="grid wrapper">
          {amenities.map(amenity => {
            return <LoloftAmenityCard amenity={amenity} key={amenity} />;
          })}
          {renderPetsAllowed(petsAllowed)}
        </div>
      </div>
    );
}

const renderPetsAllowed = petsAllowed => {
  if (petsAllowed)
    return <LoloftAmenityCard amenity={petsAllowed} key="pets" />;
};

export default PropertyAmenities;
