import React from "react";
import { Route, Switch } from "react-router-dom";
import { Tab } from "semantic-ui-react";
import history from "../../../history";

import LoginSwitch from "./login/LoginSwitch";
import RegisterSwitch from "./register/RegisterSwitch";

//this component is wrapper for authentication view
//it includes routers for login and for registration
function Authentication(props) {
  const currentPath = history.location.pathname;
  //if there is register in url path set register as an active tab
  const activeIndex = currentPath.includes("register") ? 1 : 0;
  return (
    <Tab
      panes={getTabPanes(props)}
      activeIndex={activeIndex}
      menu={{ secondary: true }}
      onTabChange={(e, data) => onTabChange(e, data, props)}
    />
  );
}

const onTabChange = (e, data, props) => {
  if (data.activeIndex === 0) {
    history.push(`/${props.owner}/login/`);
  } else {
    history.push(`/${props.owner}/register/`);
  }
};

const getTabPanes = props => {
  const { owner } = props;
  return [
    {
      menuItem: "Login",
      render: () => (
        <Tab.Pane>
          <LoginSwitch urlPrefix={owner} onSubmit={props.onSubmit} />
        </Tab.Pane>
      )
    },
    {
      menuItem: "Register",
      render: () => (
        <Tab.Pane>
          <Switch>
            <Route path={`${owner ? `/${owner}` : ""}/register`}>
              <RegisterSwitch urlPrefix={owner} onSubmit={props.onSubmit} />
            </Route>
          </Switch>
        </Tab.Pane>
      )
    }
  ];
};

export default Authentication;
