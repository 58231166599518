import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";

import { Form, Divider } from "semantic-ui-react";

import LoloftErrorMessage from "../../LoloftErrorMessage";

import { resetPasswordRequest } from "../../../../store/actions/auth";
import { validateEmail } from "../../../../utils/validation";
import { forgottenPassword } from "../../../../statics/pages/login-page";

class ForgottenPassword extends Component {
  state = { email: "", errors: {} };

  //submit function will try to validate form data,
  //and only on successful validation will try to reset password
  //on error, component error state will be updated

  onSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    if (!isEmpty(errors)) {
      return this.setState({ errors });
    }
    try {
      await this.props.resetPasswordRequest(this.state.email);
    } catch (e) {
      const errors = { ...this.state.errors, auth: { text: e.message } };
      this.setState({ errors });
    }
  };

  //returns js object with property named as field where error has occurred
  validate = () => {
    let errors = {};
    const emailError = validateEmail(this.state.email);
    if (emailError) {
      errors.email = emailError;
    }
    return errors;
  };

  //controlls email field and removes field error message on field input change
  onChange = (e) => {
    const { name, value } = e.target;
    const errors = { ...this.state.errors, [name]: undefined, auth: undefined };
    this.setState({ [name]: value, errors });
  };

  renderTitle = () => {
    if (this.props.renderTitle) {
      return <h1>{forgottenPassword.title}</h1>;
    }
  };

  render() {
    const { emailInput, buttonText, backLink } = forgottenPassword;
    return (
      <div className="basic box">
        {this.renderTitle()}
        <Form onSubmit={this.onSubmit} error>
          <Form.Input
            onChange={this.onChange}
            label={emailInput.label}
            name="email"
            placeholder={emailInput.placeholder}
            value={this.state.email}
            error={
              this.state.errors.email || this.state.errors.auth ? true : false
            }
          />
          <LoloftErrorMessage parentState={this.state} name="email" />
          <LoloftErrorMessage parentState={this.state} name="auth" />

          <Form.Button type="submit" primary fluid>
            {buttonText}
          </Form.Button>
        </Form>
        <Divider horizontal></Divider>
        <div className="center aligned">
          <Link to={this.props.backLink}>{backLink}</Link>
        </div>
      </div>
    );
  }
}

export default connect(null, { resetPasswordRequest })(ForgottenPassword);
