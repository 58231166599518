export const aboutPropertyData = {
  title: "About The Property",
  ownerTypeDropdown: {
    label: "Owner Type",
    values: [
      { text: "Private Owner", value: "private" },
      { text: "Company Owner", value: "company" }
    ]
  },
  propertyTypeDropdown: {
    label: "Property Type",
    values: [
      { text: "Apartment", value: "apartment" },
      { text: "House", value: "house" },
      { text: "Studio", value: "studio" }
    ]
  },
  bedroomsInput: { label: "How Many Bedrooms" },
  buttonLabel: "Continue"
};

export const propertyAddressData = {
  title: "Property Address",
  addressInput: {
    label: "Address",
    placeholder: "Where is your place located?"
  },
  buttonLabel: "Continue"
};

export const propertyDetailsData = {
  title: "Property Details",
  buildingNameInput: {
    label: "Building Name",
    placeholder: "Could be auto filled"
  },
  builtYearInput: {
    label: "Built Year",
    placeholder: "YYYY"
  },
  lastRenovatedInput: {
    label: "Last Renovated",
    placeholder: "YYYY"
  },
  propertySizeInput: {
    label: "Property Size",
    placeholder: "1000",
    unit: "Sq. Ft."
  },
  images: {
    title: "Show Us A Few Rooms",
    info: "Upload your best pictures of the House",
    addMoreImagesButtonLabel: "Add More Images"
  },
  buttonLabel: "Last Step"
};

export const doneData = {
  title: "Done",
  reviewing: {
    title: "We're reviewing your property",
    info:
      "One of our consultants will be in touch as soon as possible to discuss the oportunities available for your property"
  },
  help: {
    title: "We're here to help",
    info:
      "Got questions? Give us a call at +1 800 LOLOFT or email us at hello@loloft.com"
  },
  buttonLabel: "Continue"
};
