import React from "react";
import { connect } from "react-redux";

import { bankAccount } from "../../../statics/paymentInfo";
import { removePaymentInformation } from "../../../store/actions/onboarding";
import { removePaymentMethod } from "../../../store/actions/payment";

function BankAccountItem(props) {
  const { bank, onClick, removePossible } = props;

  const renderRemoveLink = () => {
    if (removePossible) {
      return (
        <div className="loloft-pi-actions">
          <a href="#" onClick={() => removeAction()}>
            {bankAccount.removeButtonLabel}
          </a>
        </div>
      );
    }
  };

  const removeAction = async () => {
    try {
      await removePaymentMethod(bank.id);
      props.removePaymentInformation(bank);
    } catch (err) {
      props.setError(err.message);
    }
  };

  const onClickAction = () => {
    if (onClick) {
      onClick();
    }
  };

  if (bank) {
    return (
      <div
        className={`loloft-pi card ${props.selected ? "selected" : ""}`}
        onClick={onClickAction}
      >
        {renderRemoveLink()}
        <div className="loloft-pi-number">
          <label>{bankAccount.routingNumber.label}</label>
          <h3>{bank.routing_number}</h3>
        </div>
        <div className="loloft-pi-number">
          <label>{bankAccount.accountNumber.label}</label>
          <h3>Last four diggits {bank.last4}</h3>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default connect(null, { removePaymentInformation })(BankAccountItem);
