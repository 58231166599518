import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";

import { doneData } from "../../../statics/pages/member-onboarding-page";

//props
//mobile - is this a view for mobile device
//open - is this step currently in focus/ opened
//competed - is this step already completed
//setCompletedStep - will set this step as compete in parent,
//which will make parent switch to the next step
const DoneStep = props => {
  const { open, completed, mobile } = props;
  if (mobile) {
    if (open) return renderStep();
    else return null;
  } else {
    if (open) return renderStep();
    else {
      if (completed) return renderCompletedInformation();
      else return renderIncompleteInformation();
    }
  }
};

//renders specific for or data for this step, and will bi directly used
//for mobile view
const renderStep = () => {
  return (
    <Fragment>
      <h2>{doneData.title}</h2>
      <p>
        Your request has been sent to the review team. We'll get back to you
        shortly.
      </p>
      <Link to="/account">
        <Button primary>{doneData.buttonLabel}</Button>
      </Link>
    </Fragment>
  );
};

//renders information about data that user entered in this step
const renderCompletedInformation = () => {};

//renders information about data that user entered in this step
const renderIncompleteInformation = () => {
  return <h2>{doneData.title}</h2>;
};

export default DoneStep;
