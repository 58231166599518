import React from "react";
import { Link } from "react-router-dom";
import { Divider, Button } from "semantic-ui-react";

import { forgottenPasswordSent } from "../../../../statics/pages/login-page";

const ForgottenPasswordSent = props => {
  const { text, buttonText } = forgottenPasswordSent;
  return (
    <div className="basic box">
      <h1></h1>
      {renderTitle()}
      <p>{text}</p>
      <Divider horizontal></Divider>
      <Link to={`${props.urlPrefix}/login/forgotten-password`}>
        <Button primary>{buttonText}</Button>
      </Link>
    </div>
  );
};

const renderTitle = props => {
  if (props.renderTitle) {
    return <h1>{forgottenPasswordSent.title}</h1>;
  }
};

export default ForgottenPasswordSent;
