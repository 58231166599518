import React, { Component } from "react";
import { connect } from "react-redux";

import history from "../../history";

import HomePageHeader from "./home-page-header/HomePageHeader";
import CommitmentsPanel from "./commitments/CommitmentsPanel";
import IdealLocations from "./ideal-location/IdealLocations";
import Cities from "./cities/Cities";
import PropertyOwners from "./property-owners/PropertyOwners";

import { getLocations, setLocation } from "../../store/actions/properties";
import "../../assets/css/loloft-v2/home-page.scss";
import { injectHomePageStyle } from "../../utils/bodyStyleInjector";

class HomePage extends Component {
  state = { location: undefined };

  componentDidMount = () => {
    this.props.getLocations();
  };

  onDropdownLocationSelect = (e, data) => {
    const { value, options } = data;
    const selectedLocation = options.find(
      option => value.localeCompare(option.value) === 0
    );
    this.setState({ location: selectedLocation });
  };

  onFindPlacesButtonClick = () => {
    this.props.setLocation(this.state.location);
    history.push(`/properties`);
  };

  render() {
    injectHomePageStyle();
    return (
      <div>
        <HomePageHeader
          availableLocations={this.props.availableLocations}
          onDropdownSelect={this.onDropdownLocationSelect}
          onButtonClick={this.onFindPlacesButtonClick}
          selectedLocation={
            this.state.location ? this.state.location.value : undefined
          }
        />
        <CommitmentsPanel />
        <IdealLocations
          availableLocations={this.props.availableLocations}
          onDropdownSelect={this.onDropdownLocationSelect}
          onButtonClick={this.onFindPlacesButtonClick}
          selectedLocation={
            this.state.location ? this.state.location.value : undefined
          }
        />
        <Cities />
        <PropertyOwners />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { availableLocations: state.properties.availableLocations };
};

export default connect(mapStateToProps, { getLocations, setLocation })(
  HomePage
);
