import React, { Component } from "react";
import { connect } from "react-redux";
import history from "../../history";

import { LoloftMap, Marker } from "../../components/common/LoloftMap";

class PropertiesMap extends Component {
  //this action will change browser path if property id exist
  //and will show specific property page
  onPropertyMarkerClick = property => {
    if (property.id) {
      history.push(`/properties/${property.id}`);
    }
  };

  //this action will return a list of property markers
  getPropretyMarkers = () => {
    if (this.props.propertiesToRender) {
      var id = 0;
      return this.props.propertiesToRender.map(property => {
        const { lat, lng } = property.location;
        return (
          <Marker
            key={id++}
            lat={lat}
            lng={lng}
            price={property.price}
            onClick={() => {
              this.onPropertyMarkerClick(property);
            }}
          />
        );
      });
    }
  };

  render() {
    const { location } = this.props;
    const center = location ? { lat: location.lat, lng: location.lng } : null;
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <LoloftMap center={center}>{this.getPropretyMarkers()}</LoloftMap>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    location: state.properties.location,
    propertiesToRender: state.properties.propertiesToRender
  };
};

export default connect(mapStateToProps)(PropertiesMap);
