export const creditCard = {
  holder: "Credit Card Holder",
  cardHolder: "Card Holder",
  cardNumberInput: {
    label: "Credit Card Number",
    placeholder: "0000 - 0000 - 0000 - 0000"
  },
  validThruInput: { label: "Valid Thru", placeholder: "MM / YYYY" },
  cvcInput: { label: "CVC", placeholder: "3 Digits" },
  note:
    "Please note: Credit cards incur a 3% surcharge. ACH payment methods have 0% surcharge.",
  checkboxLabel:
    "By submitting your Payment Info you agree with the terms of service.",
  addButtonLabel: "Add Card",
  removeButtonLabel: "Remove"
};

export const bankAccount = {
  holder: "Account Owner",
  routingNumber: {
    label: "Routing Number",
    placeholder: "6758686970"
  },
  accountNumber: {
    label: "Account Number",
    placeholder: "68798019-02"
  },
  checkboxLabel:
    "By submitting your Payment Info you agree with the terms of service.",
  addButtonLabel: "Add Bank Account",
  removeButtonLabel: "Remove"
};
