import eq from "lodash/eq";

import firebase from "../../config/firebase";
import { getMinCounter } from "../../utils/parseCounter";
import history from "../../history";

import {
  GET_AVAILABLE_LOCATIONS,
  GET_PROPERTIES_COUNT,
  GET_FILTERED_PROPERTIES_COUNT,
  GET_PROPERTIES,
  GET_PROPERTY,
  PROPERTY_NOT_FOUND,
  SET_LOCATION,
  SET_DATES,
  SET_FILTERS,
  NEXT_PAGE,
  SET_BOOKING_DATA,
} from "../types";

const FETCH_SIZE = 50;
const SOFT_COUNT_LIMIT = 50;

export const getLocations = () => async (dispatch) => {
  try {
    const response = await firebase.firestore().collection("cities").get();
    let locationMap = new Map();
    response.docs.forEach((c) => {
      const { city, lat, lng } = c.data();
      locationMap.set(city, {
        lat,
        lng,
      });
    });
    //creates a list of objects for displaying data in
    //semantic ui search dropdown
    const locations = [];
    locationMap.forEach((value, key) => {
      locations.push({
        title: key,
        description: "",
        lat: value.lat,
        lng: value.lng,
        value: key,
        text: key,
      });
    });
    dispatch({ type: GET_AVAILABLE_LOCATIONS, payload: locations });
  } catch (error) {
    console.error(error);
  }
};

//method that fetches single property by id
//this method will dispatch GET_PROPERTY action if property was found
//and PROPERTY_NOT_FOUND action if not
export const getPropertiesById = (id) => async (dispatch) => {
  try {
    const property = await firebase
      .firestore()
      .collection("properties")
      .doc(id)
      .get();
    if (property.exists) {
      dispatch({ type: GET_PROPERTY, payload: property.data() });
    } else {
      dispatch({ type: PROPERTY_NOT_FOUND, payload: id });
    }
  } catch (error) {
    console.error(error);
  }
};

//this method will fetch properties
export const getProperties = (loadMore = false) => async (
  dispatch,
  getState
) => {
  try {
    const {
      filters,
      location,
      dates,
      lastLoadedDoc,
      properties,
    } = getState().properties;
    var query = getPropertiesQuery(filters, dates, location);
    if (loadMore && lastLoadedDoc) {
      //if there were already loaded properties,
      //we will load a next fetch starting from the last doc that was loaded
      query = query.startAt(lastLoadedDoc);
    }
    const querySnapshot = await query
      .limit(FETCH_SIZE + 1) //one more document is fetched then needed
      .get(); //to check if this is the last page
    const result = querySnapshot.docs.map((doc) => {
      return doc.data();
    });
    const hasMoreDocs = result.length === FETCH_SIZE + 1 ? true : false;
    var newProperties = hasMoreDocs ? result.slice(0, FETCH_SIZE) : result;
    newProperties = filterProperties(newProperties, filters, dates);
    if (loadMore) {
      newProperties = [...properties, ...newProperties];
    }
    //if there is next page, we will store last document in app state
    //and next page will be loaded starting with that doc, otherwise,
    //we will set it to null
    const lastDoc = hasMoreDocs ? querySnapshot.docs[FETCH_SIZE] : null;

    dispatch({
      type: GET_PROPERTIES,
      payload: {
        properties: newProperties,
        hasMoreDocs,
        lastLoadedDoc: lastDoc,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

//method for fetching properties count
//first tries to fetch count from cities/counters collection
//if there is less properties then soft limit, method will fetch real
//properties and then filter them to get precise count
export const getPropertiesCount = (tempFilters, tempDates) => async (
  dispatch,
  getState
) => {
  try {
    const { filters, location, dates } = getState().properties;
    if (!location) return;
    const fbCounter = await firebase
      .firestore()
      .collection("cities")
      .doc(location.value)
      .collection("counters")
      .doc("counter")
      .get();
    const counterFilters = tempFilters || filters;
    const counterDates = tempDates || dates;
    const counter = getMinCounter(
      fbCounter.data(),
      counterFilters,
      counterDates
    );
    let count;
    //if under SOFT_COUNT_LIMIT send requests for real property documents
    if (counter.count < SOFT_COUNT_LIMIT) {
      var query = getPropertiesForCountingQuery(
        location,
        counter,
        counterFilters,
        counterDates
      );
      const res = await query.get();
      const filteredProperties = filterProperties(
        res.docs.map((doc) => doc.data()),
        counterFilters,
        counterDates
      );
      count = filteredProperties.length;
    } else {
      count = counter.count;
    }
    //if action is called from set dates or set filters with temp values,
    //action for setting temp count will be dispatched
    if (tempFilters || tempDates) {
      dispatch({ type: GET_FILTERED_PROPERTIES_COUNT, payload: count });
    } else {
      dispatch({ type: GET_PROPERTIES_COUNT, payload: count });
    }
  } catch (error) {
    console.error(error);
  }
};

export const setBookingData = (property) => (dispatch, getState) => {
  const { dates } = getState().properties;
  var bookingData = { property, dates };
  dispatch({ type: SET_BOOKING_DATA, payload: bookingData });
  history.push("/booking/login");
};

export const setLocation = (location) => (dispatch) => {
  dispatch({ type: SET_LOCATION, payload: location });
};

export const setFilters = (filters) => (dispatch) => {
  dispatch({ type: SET_FILTERS, payload: filters });
};

export const setDates = (dates) => (dispatch) => {
  dispatch({ type: SET_DATES, payload: dates });
};

export const showNextPage = () => (dispatch) => {
  dispatch({ type: NEXT_PAGE });
};

//this method will fetch properties for
//precise counting purposes
const getPropertiesForCountingQuery = (
  location = {},
  counter,
  filters,
  dates
) => {
  var query = firebase.firestore().collection("properties");
  if (location.value) {
    query = query.where("location.city", "==", location.title);
  }
  if (counter) {
    if (eq(counter.type, "bedrooms")) {
      query = query.where("bedrooms", ">=", filters.bedrooms);
    }
    if (eq(counter.type, "bathrooms")) {
      query = query.where("bathrooms", ">=", filters.bathrooms);
    }
    if (eq(counter.type, "parkingSpaces")) {
      query = query.where("parkingSpaces", ">=", filters.parkingSpaces);
    }
    if (eq(counter.type, "petsAllowed")) {
      query = query.where("petsAllowed.value", "==", "pets");
    }
    if (eq(counter.type, "price")) {
      query = query.where("price", "<=", Number(filters.budget));
    }
    if (eq(counter.type, "amenities")) {
      const amenity = counter.value;
      query = query.where("amenities", "array-contains", amenity);
    }
    if (eq(counter.type, "type")) {
      query = query.where("type", "in", filters.propertyTypes);
    }
    if (eq(counter.type, "state")) {
      query = query.where("state", "==", dates.propertyState);
    }
  }
  return query;
};

//this method will fetch properties for displaying
const getPropertiesQuery = (filters, dates, location = {}) => {
  var query = firebase.firestore().collection("properties");
  if (location.value) {
    query = query.where("location.city", "==", location.title);
  }
  if (filters) {
  }
  if (dates) {
    if (dates.propertyState) {
      query = query.where("state", "==", dates.propertyState);
    }
  }
  return query;
};

const filterProperties = (properties = [], filters, dates) => {
  var filteredProperties = [...properties];
  if (filters) {
    if (filters.amenities) {
      filteredProperties = filteredProperties.filter((prop) => {
        for (var i = 0; i < filters.amenities; i++) {
          if (!prop.include(filters.amenities[i])) return false;
        }
        return true;
      });
    }
    if (filters.propertyType) {
      filteredProperties = filteredProperties.filter((prop) => {
        for (var i = 0; i < filters.propertyType; i++) {
          if (prop.include(filters.propertyType[i])) return true;
        }
        return false;
      });
    }
    if (filters.bathrooms) {
      filteredProperties = filteredProperties.filter((prop) => {
        return prop.bathrooms >= filters.bathrooms;
      });
    }
    if (filters.bedrooms) {
      filteredProperties = filteredProperties.filter((prop) => {
        return prop.bedrooms >= filters.bedrooms;
      });
    }
    if (filters.parkingSpaces) {
      filteredProperties = filteredProperties.filter((prop) => {
        return prop.parkingSpaces >= filters.parkingSpaces;
      });
    }
    if (filters.petsAllowed) {
      filteredProperties = filteredProperties.filter((prop) => {
        return prop.petsAllowed !== false || prop.petsAllowed !== undefined;
      });
    }
    if (filters.budget) {
      filteredProperties = filteredProperties.filter((prop) => {
        return prop.price <= filters.budget;
      });
    }
  }
  if (dates) {
    if (dates.propertyState) {
      filteredProperties = filteredProperties.filter((prop) => {
        return eq(prop.state, dates.propertyState);
      });
    }
    //TO DO add start date filter
    //TO DO filter by map
  }
  return filteredProperties;
};
