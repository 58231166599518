import debounce from "lodash.debounce";
import React, { Component } from "react";
import { Search } from "semantic-ui-react";

const autocompleteService = new window.google.maps.places.AutocompleteService();

export default class LoloftCityInput extends Component {
  componentDidMount() {
    const { value } = this.props;
    if (value) {
      this.setState({ value });
    }
  }

  componentWillMount() {
    this.resetComponent();
  }

  resetComponent = () =>
    this.setState({ isLoading: false, results: [], value: "" });

  handleResultSelect = (e, { result }) => {
    this.setState({ value: result.title, selectedPlace: result });
    const state = result.description.split(",")[0].trim();
    this.props.onSelect(result.title, state);
  };

  handleSearchChange = (e, { value }) => {
    if (value.length === 0) {
      return this.resetComponent();
    }

    this.setState({ isLoading: true, value });
    autocompleteService.getPlacePredictions(
      {
        input: value,
        types: ["(cities)"],
        componentRestrictions: { country: "us" },
      },
      this.handleAutocompleteResult
    );
  };

  handleAutocompleteResult = (predictions, status) => {
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      this.setState({
        isLoading: false,
        results: predictions.map((prediction) => {
          return {
            key: prediction.id,
            title: prediction.structured_formatting.main_text,
            description: prediction.structured_formatting.secondary_text,
            source: prediction,
          };
        }),
      });
    }
  };

  render() {
    const { isLoading, value, results } = this.state;
    return (
      <Search
        className="field"
        placeholder={this.props.placeholder}
        loading={isLoading}
        onResultSelect={this.handleResultSelect}
        onSearchChange={debounce(this.handleSearchChange, 500, {
          leading: true,
        })}
        results={results}
        value={value}
      />
    );
  }
}
