import React from "react";

const LoloftButton = function(props) {
  return (
    <button className="ui primary button" onClick={props.onClick}>
      {props.children}
    </button>
  );
};

export default LoloftButton;
