import React, { Component } from "react";
import { propertyPage } from "../../statics/pages/property-page";

class PropertyDescription extends Component {
  constructor(props) {
    super(props);
    const { description } = this.props.property;
    //if lenght of description is long, it will be shown
    //in shorther form and show more button will be rendered
    const showMore = description.length < 210;
    this.state = { showMore };
  }

  //if show more state is false, this method will reder button
  renderShowMoreButton = () => {
    if (!this.state.showMore) {
      return (
        <div className="flex more padded top image-spaced right"
          onClick={() => {
            this.setState({ showMore: true });
          }}
        >
          <img src="/assets/icons/icon-arrow-down.svg" width="21" alt="" /> Show More
        </div>
      );
    }
    return null;
  };

  //this method will render description text
  //if description
  renderText = () => {
    const { description } = this.props.property;
    var desc;
    if (this.state.showMore) {
      desc = description;
    } else {
      desc = `${description.substring(0, 210)} ...`;
    }
    return (
      <div className="box">
        <div>{desc}</div>
        {this.renderShowMoreButton()}
      </div>
    );
  };

  render() {
    if (this.props.property.description) {
      return (
        <div>
          <h3>{propertyPage.description}</h3>
          {this.renderText()}
        </div>
      );
    }
    return null;
  }
}

export default PropertyDescription;
