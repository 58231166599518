/** LIBRARIES */
import React from "react";
import GoogleMapReact from "google-map-react";
import { mapStyle, mapKey } from "../../config/google-map";
import "../../assets/css/loloft/map.scss";

export function LoloftMap(props) {
  const defaultCenter = {
    lat: 25.76231049999999,
    lng: -80.1896457
  };
  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: mapKey }}
      center={props.center || defaultCenter}
      defaultZoom={props.zoom || 11}
      options={{ styles: mapStyle }}
    >
      {props.children || ""}
    </GoogleMapReact>
  );
}

export function Pointer(props) {
  return <div lat={props.lat} lng={props.lng} className="mapMakerIcon" />;
}

export function Marker(props) {
  return (
    <div onClick={props.onClick} lat={props.lat} lng={props.lng}>
      <h4>${props.price}</h4>
    </div>
  );
}
