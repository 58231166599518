import React, { Component } from "react";
import { Dropdown, Button } from "semantic-ui-react";

import "../../assets/css/loloft-v2/loloft-dropdown-with-button.scss";

//composite component, that is used for grouping of dropdown and button components
class LoloftDropdownWithButton extends Component {
  render() {
    const {
      primary,
      secondary,
      disabled,
      small,
      fluid,
      value,
      dropdownLabel,
      onChange,
      defaultValue,
      options,
      onClick,
      buttonLabel
    } = this.props;

    return (
      <div className="dropdown-with-button">
        <div className="dropdown-with-button--dropdown">
          <span className="sm-label">{dropdownLabel}</span>
          <Dropdown
            onChange={onChange}
            placeholder={defaultValue}
            fluid={fluid}
            search
            selection
            options={options}
            value={value}
          />
        </div>
        <div className="dropdown-with-button--button">
          <Button
            primary={primary}
            secondary={secondary}
            disabled={disabled}
            size={small ? "small" : "medium"}
            onClick={onClick}
            fluid={fluid}
          >
            {buttonLabel}
          </Button>
        </div>
      </div>
    );
  }
}

export default LoloftDropdownWithButton;
