import React from "react";
import { Route, Switch } from "react-router-dom";
import ForgottenPassword from "./ForgottenPassword";
import ResetPassword from "./ResetPassword";
import LoginForm from "./LoginForm";
import ForgottenPasswordSent from "./ForgottenPasswordSent";
import LoloftSmsCodeVerification from "../../LoloftSmsCodeVerification";

function LoginSwitch(props) {
  //if there is prefix url that means that login is rendered inside some other
  //view - not on login page - and we have to set different routers
  const urlPrefix = props.urlPrefix ? `/${props.urlPrefix}/login` : "/login";

  //if prefix url is set, then we do not want to display default title for compoentns
  //wraper page will provide it's own title
  const renderTitle = props.urlPrefix ? false : true;
  return (
    <Switch>
      <Route path={`${urlPrefix}/forgotten-password-sent`}>
        <ForgottenPasswordSent
          urlPrefix={urlPrefix}
          renderTitle={renderTitle}
        />
      </Route>
      <Route path={`${urlPrefix}/forgotten-password`}>
        <ForgottenPassword
          urlPrefix={urlPrefix}
          renderTitle={renderTitle}
          backLink={`${urlPrefix}`}
        />
      </Route>
      <Route path={`${urlPrefix}/sms-verification`}>
        <LoloftSmsCodeVerification
          backLink={`${urlPrefix}`}
          renderTitle={renderTitle}
          onSubmit={props.onSubmit}
        />
      </Route>
      <Route path={`${urlPrefix}/reset-password`}>
        <ResetPassword urlPrefix={urlPrefix} renderTitle={renderTitle} />
      </Route>
      <Route exact path={`${urlPrefix}`}>
        <LoginForm urlPrefix={urlPrefix} renderTitle={renderTitle} />
      </Route>
    </Switch>
  );
}

export default LoginSwitch;
