import React from "react";
import LoloftPaginationDot from "./LoloftPaginationDot";

import "../../../assets/css/loloft-v2/loloft-carousell.scss";

class LoloftPagination extends React.Component {
  handleClick = index => {
    this.props.onChangeIndex(index);
  };

  render() {
    const { index, dots } = this.props;

    const children = [];

    for (let i = 0; i < dots; i += 1) {
      children.push(
        <LoloftPaginationDot
          key={i}
          index={i}
          active={i === index}
          onClick={this.handleClick}
        />
      );
    }

    return <div className="pagination">{children}</div>;
  }
}

export default LoloftPagination;
