import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import history from "../../../history";
import Authentication from "../loloft-auth/Authentication";
import { yourAccountData } from "../../../statics/pages/member-onboarding-page";

class YourAccountStep extends Component {
  componentDidMount() {
    //add login to url so login part of authentication is displayed
    history.push(`/${this.props.owner}/login`);
  }

  componentDidUpdate(prevProps) {
    const phoneWasNotVerified =
      !prevProps.auth.user || !prevProps.auth.user.phoneCheck;
    const phoneIsVerified =
      this.props.auth.user && this.props.auth.user.phoneCheck;
    if (phoneWasNotVerified && phoneIsVerified) {
      this.onSuccessAuth();
    }
  }

  onSuccessAuth = () => {
    this.props.setChangeAccount(false);
    if (this.props.setCompletedStep && this.props.step)
      this.props.setCompletedStep(this.props.step);
    history.push(`/${this.props.owner}/`);
  };

  //renders information about data that user entered in this step
  renderCompleted = () => {
    const { user } = this.props.auth;
    if (user) {
      return (
        <div>
          <h2>{yourAccountData.title}</h2>
          <p>
            Welcome {user.firstName} {user.lastName}
            <Link
              to={`/${this.props.owner}/login`}
              onClick={() => this.props.setChangeAccount(true)}
            >
              {yourAccountData.changeAccount}
            </Link>
          </p>
        </div>
      );
    } else {
      return null;
    }
  };

  //renders information about data that user entered in this step
  renderIncomplete = () => {
    return null;
  };

  //renders specific for or data for this step, and will bi directly used
  //for mobile view
  renderStep = () => {
    return (
      <Fragment>
        <h2>{yourAccountData.title}</h2>
        <Authentication
          owner={`${this.props.owner}`}
          onSubmit={this.onSuccessAuth}
        />
      </Fragment>
    );
  };

  //props
  //mobile - is this a view for mobile device
  //open - is this step currently in focus/ opened
  //competed - is this step already completed
  //setCompletedStep - will set this step as compete in parent,
  //which will make parent switch to the next step
  //changeAccount- if true, component will be shown for
  //purposes of changing account

  render() {
    const { open, completed, mobile, changeAccount } = this.props;
    if (mobile) {
      if (open || changeAccount) return this.renderStep();
      else return null;
    } else {
      if (open || changeAccount) return this.renderStep();
      else {
        if (completed) return this.renderCompleted();
        else return this.renderIncomplete();
      }
    }
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

export default connect(mapStateToProps)(YourAccountStep);
