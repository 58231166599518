import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import eq from "lodash/eq";
import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce";
import { Form } from "semantic-ui-react";

import LoloftYearPicker from "../LoloftYearPicker";
import LoloftErrorMessage from "../LoloftErrorMessage";
import LoloftSuccessMessage from "../LoloftSuccessMessage";
import { submitEmploymentDetails } from "../../../store/actions/onboarding";
import {
  validateStringLength,
  validateRequired,
} from "../../../utils/validation";
import { employmentDetailsData } from "../../../statics/pages/member-onboarding-page";
import LoloftDropdown from "../LoloftDropdown";

class EmploymentDetailsStep extends Component {
  unemployedState = {
    employmentStatus: "unemployed",
    employerName: "",
    jobTitle: "",
    employedSince: "",
  };

  state = {
    ...this.unemployedState,
    errors: {},
  };

  //if component has data that is passed from parent,
  //set data to input fields
  componentWillMount() {
    const { data } = this.props;
    if (data) {
      this.setState(data);
    }
  }

  isEmployed = (employmentStatus = this.state.employmentStatus) => {
    return eq(employmentStatus, "employed");
  };

  //controlls input fields and removes field error message on field input change
  onChange = (e, data) => {
    const { name, value } = data;
    const errors = { ...this.state.errors, [name]: undefined, auth: undefined };
    this.setState({ [name]: value, errors });
  };

  //returns js object with property named as field where error has occurred
  validate = () => {
    let errors = {};
    const isEmployed = this.isEmployed();
    const employerNameError = validateStringLength(
      this.state.employerName,
      "Employer Name",
      3,
      200,
      isEmployed
    );
    const jobTitleError = validateStringLength(
      this.state.jobTitle,
      "Job Title",
      6,
      200,
      isEmployed
    );
    const employedSinceError = validateRequired(
      this.state.employedSince,
      "Employed Since",
      isEmployed
    );

    if (employerNameError) {
      errors.employerName = employerNameError;
    }
    if (jobTitleError) {
      errors.jobTitle = jobTitleError;
    }
    if (employedSinceError) {
      errors.employedSince = employedSinceError;
    }

    return errors;
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    if (!isEmpty(errors)) {
      return this.setState({ errors });
    }
    try {
      this.props.submitEmploymentDetails(this.state);
      if (this.props.setCompletedStep) {
        this.props.setCompletedStep(this.props.step);
      }
      if (this.props.onUserAccountUpdate) {
        const employmentData = this.isEmployed()
          ? this.state
          : this.unemployedState;
        await this.props.onUserAccountUpdate(
          "employmentDetails",
          employmentData
        );
        this.setState({ successMessage: "Update Successful" });
        debounce(2000, () => this.setState({ successMessage: undefined }));
      }
    } catch (e) {
      const errors = { ...this.state.errors, [e.name]: { text: e.message } };
      this.setState({ errors });
    }
  };

  //renders information about data that user entered in this step
  renderCompletedInformation = () => {
    const ed = this.props.employmentDetails;
    if (ed) {
      if (this.isEmployed(ed.employmentStatus)) {
        return (
          <div>
            <h2>{employmentDetailsData.title}</h2>
            <p>
              {ed.jobTitle} at {ed.employerName} since {ed.employedSince}
            </p>
          </div>
        );
      } else {
        return (
          <div>
            <h2>{employmentDetailsData.title}</h2>
            <p>Unemployed</p>
          </div>
        );
      }
    }
    return null;
  };

  //renders information about data that user entered in this step
  renderIncompleteInformation = () => {
    return <h2>{employmentDetailsData.title}</h2>;
  };

  renderEmploymentFields = () => {
    if (this.isEmployed()) {
      const {
        employedSinceInput,
        jobTitleInput,
        employerNameInput,
      } = employmentDetailsData;
      return (
        <Fragment>
          <Form.Input
            onChange={this.onChange}
            label={employerNameInput.label}
            name="employerName"
            placeholder={employerNameInput.placeholder}
            value={this.state.employerName}
            error={this.state.errors.employerName ? true : false}
          />
          <LoloftErrorMessage parentState={this.state} name="employerName" />
          <Form.Input
            onChange={this.onChange}
            label={jobTitleInput.label}
            name="jobTitle"
            placeholder={jobTitleInput.placeholder}
            value={this.state.jobTitle}
            error={this.state.errors.jobTitle ? true : false}
          />
          <LoloftErrorMessage parentState={this.state} name="jobTitle" />
          <LoloftYearPicker
            className="fluid search selection"
            label={employedSinceInput.label}
            name="employedSince"
            placeholder={employedSinceInput.placeholder}
            onChange={this.onChange}
            value={this.state.employedSince}
            error={this.state.errors.employedSince ? true : false}
            fluid
          />
          <LoloftErrorMessage parentState={this.state} name="employedSince" />
        </Fragment>
      );
    }
    return null;
  };

  //renders specific for or data for this step, and will bi directly used
  //for mobile view
  renderStep = () => {
    const {
      buttonLabel,
      dropdown,
      title,
      accountViewButtonLabel,
    } = employmentDetailsData;
    return (
      <Fragment>
        <h2>{title}</h2>
        <Form onSubmit={this.onSubmit} error success>
          <LoloftSuccessMessage parentState={this.state} />
          <LoloftErrorMessage parentState={this.state} name="error" />
          <div className="field">
            <LoloftDropdown
              name="employmentStatus"
              onChange={this.onChange}
              value={this.state.employmentStatus}
              defaultValue={this.state.employmentStatus}
              options={dropdown.values}
              dropdownLabel={dropdown.label}
              fluid
            />
          </div>
          {this.renderEmploymentFields()}
          <Form.Button type="submit" primary fluid>
            {this.props.accountView ? accountViewButtonLabel : buttonLabel}
          </Form.Button>
        </Form>
      </Fragment>
    );
  };

  //props
  //mobile - is this a view for mobile device
  //open - is this step currently in focus/ opened
  //competed - is this step already completed
  //setCompletedStep - will set this step as compete in parent,
  //which will make parent switch to the next step
  render() {
    const { open, completed, mobile } = this.props;
    if (mobile) {
      if (open) return this.renderStep();
      else return null;
    } else {
      if (open) return this.renderStep();
      else {
        if (completed) return this.renderCompletedInformation();
        else return this.renderIncompleteInformation();
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    employmentDetails: state.onboarding.employmentDetails,
  };
};

export default connect(mapStateToProps, { submitEmploymentDetails })(
  EmploymentDetailsStep
);
