import React, { Component } from "react";

import LoloftDropdownWithButton from "../../../components/common/LoloftDropdownWithButton";

import { idealLocations } from "../../../statics/pages/home-page";

class IdealLocations extends Component {
  state = { tabs: idealLocations.tabs };

  //this method is triggered every time user clicks on tab
  //key parameter is identifier of clicked tab
  //new state will have active property set to true on clicked tab,
  //and all other tabs will have active property set to false
  onTabClick = key => {
    const newState = this.state.tabs.map(tab => {
      return { ...tab, active: key.localeCompare(tab.key) === 0 };
    });
    this.setState({ tabs: newState });
  };

  //returns data of selected tab
  getSelectedTab = () => {
    return this.state.tabs.find(tab => tab.active);
  };

  //return list of tabs wrapped in vertical semantic menu
  //className active item will be set only if that item has
  //an active property in component state
  getTabs = () => {
    return (
      <div className="ui fluid vertical menu">
        {this.state.tabs.map(tab => {
          return (
            <a
              className={`${tab.active ? "active" : ""} item`}
              key={tab.key}
              onClick={() => this.onTabClick(tab.key)}
            >
              {tab.title}
            </a>
          );
        })}
      </div>
    );
  };

  renderLocations = () => {
    return (
      <div className="ui container">
        <div className="ui stackable grid">
          <div className="six wide middle aligned column">
            <div className="pad right">
              <h2>{idealLocations.title}</h2>
              <div className="section-info">{idealLocations.info}</div>
            </div>
            {this.getTabs()}
            <div className="pad right">
              <div className="ideal-locations--dropdown-with-button">
                <LoloftDropdownWithButton
                  defaultValue="All"
                  dropdownLabel="Choose City"
                  buttonLabel="Find Places"
                  options={this.props.availableLocations}
                  primary
                  fluid
                  onChange={this.props.onDropdownSelect}
                  onClick={this.props.onButtonClick}
                  value={this.props.selectedLocation}
                />
              </div>
            </div>
          </div>
          <div className="stretched ten wide column">
            <div className="ui active tab">
              <img
                src={this.getSelectedTab().image}
                className="ui image"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="locations on-white section">{this.renderLocations()}</div>
    );
  }
}

export default IdealLocations;
