import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Button, Input, Message } from "semantic-ui-react";

import history from "../../history";

import {
  smsVerification,
  sendSmsVerificationCode,
} from "../../store/actions/auth";
import { smsVerificationData } from "../../statics/smsCodeVerification";

//this component will allow user to enter 4 digit code that is send via sms
class LoloftSmsCodeVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstDigit: "",
      secondDigit: "",
      thirdDigit: "",
      fourthDigit: "",
      error: undefined,
    };
    //references for input fields
    //using them we can change focus from one to another dynamically
    this.firstDigit = React.createRef();
    this.secondDigit = React.createRef();
    this.thirdDigit = React.createRef();
    this.fourthDigit = React.createRef();
  }

  //when component is rendered first time, set focus on first input field
  //if no auth user present in state, it will redirect to browse properties page
  async componentDidMount() {
    //if no auth state (user reloaded page) return to the login page
    if (!this.props.auth || !this.props.auth.user) {
      await setTimeout(500, () => {
        console.log("Auth user is not loaded on time. Waith 500 ms");
      });
      if (!this.props.auth || !this.props.auth.user) {
        return history.push(this.props.backLink);
      }
    }
    this.sendCode();
    this.firstDigit.focus();
  }

  //if any of inputs is empty, set error state
  //this state will be used to render error message and show error in fields
  //if there is no error, error state property will be removed
  verifyForm = () => {
    const { firstDigit, secondDigit, thirdDigit, fourthDigit } = this.state;
    if (
      firstDigit === undefined ||
      firstDigit === "" ||
      secondDigit === undefined ||
      secondDigit === "" ||
      thirdDigit === undefined ||
      thirdDigit === "" ||
      fourthDigit === undefined ||
      fourthDigit === ""
    ) {
      return false;
    }
    return true;
  };

  //if error is defined as component state, message will be returned
  //otherwise, response will be null
  renderError = () => {
    const { error } = this.state;
    if (error) {
      return <Message error content={error.text ? error.text : error} />;
    } else {
    }
    return null;
  };

  //code verification method
  verifyCode = async () => {
    if (!this.verifyForm()) {
      return this.setState({ error: smsVerificationData.error });
    }
    this.setState({ error: undefined });
    const { firstDigit, secondDigit, thirdDigit, fourthDigit } = this.state;
    const code =
      "" + firstDigit + "" + secondDigit + "" + thirdDigit + "" + fourthDigit;
    try {
      await this.props.smsVerification(code, this.props.setFlag);
      if (this.props.onSubmit) {
        //if there is addional method passed for authenification,
        //for exemple for booking or onboarding, execute it
        await this.props.onSubmit();
      }
    } catch (error) {
      this.setState({ error: error.message });
    }
  };

  sendCode = () => {
    this.props.sendSmsVerificationCode(
      this.props.auth.user.phone,
      this.props.setFlag
    );
  };

  //method that connects input fields and state
  //rejects negative numbers and replace existing digit on new input
  onChange = (e) => {
    if (isNaN(e.target.value)) return;
    if (Number.parseInt(e.target.value) < 0) return;
    //handling 00 case and numbers larger then 9
    var value = e.target.value;
    if (value.length > 1) {
      value = value[value.length - 1];
    }
    this.setState({
      [e.target.name]: value,
      error: undefined,
    });

    //when user inputs a digit in input field,
    //focus will be automaticly switched to the next one
    //when input is completed, fourth input will be blured
    switch (e.target.name) {
      case "firstDigit":
        return this.secondDigit.focus();
      case "secondDigit":
        return this.thirdDigit.focus();
      case "thirdDigit":
        return this.fourthDigit.focus();
      case "fourthDigit":
        return this.fourthDigit.inputRef.current.blur();
    }
  };

  renderTitle = () => {
    if (this.props.renderTitle) {
      return <h1>{smsVerificationData.title}</h1>;
    }
  };

  renderBackButton = () => {
    const { backButtonText } = smsVerificationData;
    const { backLink, backAction } = this.props;
    if (backLink) {
      return (
        <Link to={backLink} className="ui white button">
          {backButtonText}
        </Link>
      );
    } else if (backAction) {
      return (
        <Button onClick={backAction} className="ui white button">
          {backButtonText}
        </Button>
      );
    } else {
      return null;
    }
  };

  render() {
    //if no auth state, return null
    if (!this.props.auth || !this.props.auth.user) {
      return null;
    }
    const { lineOne, lineTwo, lineThree, buttonText } = smsVerificationData;
    const error = this.state.error ? true : false;
    return (
      <div className="basic box">
        {this.renderTitle()}
        <div>{this.renderBackButton()}</div>
        {
          //form was not used because there is a problem with
          //setting refs for FormControlled inputs,
          //and there is also a problem setting errors
          //if inputs are inside form, and are not form controlled
        }
        <div className="four inputs">
          <Input
            error={error}
            ref={(ref) => (this.firstDigit = ref)}
            name="firstDigit"
            onChange={this.onChange}
            value={this.state.firstDigit}
          />
          <Input
            error={error}
            ref={(ref) => (this.secondDigit = ref)}
            name="secondDigit"
            onChange={this.onChange}
            value={this.state.secondDigit}
          />
          <Input
            error={error}
            ref={(ref) => (this.thirdDigit = ref)}
            name="thirdDigit"
            onChange={this.onChange}
            value={this.state.thirdDigit}
          />
          <Input
            error={error}
            ref={(ref) => (this.fourthDigit = ref)}
            name="fourthDigit"
            onChange={this.onChange}
            value={this.state.fourthDigit}
          />
        </div>
        {this.renderError()}
        <p>
          {lineOne.text}
          {this.props.auth.user.phone}. {lineTwo.text}
        </p>
        <p>
          {lineThree.text}
          <a href="#" onClick={this.sendCode}>
            {lineThree.linkText}
          </a>
        </p>

        <Form.Button primary fluid onClick={this.verifyCode}>
          {this.props.buttonLabel || buttonText}
        </Form.Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

export default connect(mapStateToProps, {
  sendSmsVerificationCode,
  smsVerification,
})(LoloftSmsCodeVerification);
