import React, { Component } from "react";
import { Form, Checkbox } from "semantic-ui-react";

import { propertyStates, label } from "../../statics/property-states";

class LoloftPropertyStates extends Component {
  render() {
    const { propertyState, onChange } = this.props;
    return (
      <div className="box">
        <h4>{label}</h4>
        <div>
          <Form.Field>
            {propertyStates.map(state => {
              //maps over property state array and returns a checkbox for each one
              return (
                <Checkbox
                  key={state.value}
                  radio
                  label={state.title}
                  name="propertyState"
                  value={state.value}
                  checked={propertyState === state.value}
                  onChange={onChange}
                />
              );
            })}
          </Form.Field>
        </div>
      </div>
    );
  }
}

export default LoloftPropertyStates;
