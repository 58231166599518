import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store/store";

import AppWrapper from "./AppWrapper";

import "./assets/css/loloft/loloft-components.scss";
import "./assets/css/loloft/effects.scss";
import "./assets/css/loloft-v2/loloft.scss";

ReactDOM.render(
  <Provider store={store}>
    <AppWrapper />
  </Provider>,
  document.getElementById("root")
);
