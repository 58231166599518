import React, { Component } from "react";
import SwipeableViews from "react-swipeable-views";
import LoloftPagination from "./LoloftPagination";
import { autoPlay } from "react-swipeable-views-utils";

import "../../../assets/css/loloft-v2/loloft-carousell.scss";

class LoloftCarousell extends Component {
  state = {
    index: 0,
    hover: false
  };

  handleChangeIndex = index => {
    const { length } = this.props.children;
    var newIndex = index;
    if (newIndex >= length) newIndex = 0;
    if (newIndex < 0) newIndex = length - 1;
    this.setState({
      index: newIndex
    });
  };

  renderCarousellMobile = () => {
    const { index } = this.state;
    const { children } = this.props;
    return (
      <div className="carousell">
        <SwipeableViews
          enableMouseEvents={true}
          index={this.state.index}
          onChangeIndex={this.handleChangeIndex}
        >
          {this.props.children}
        </SwipeableViews>
        <LoloftPagination
          dots={children.length}
          index={index}
          onChangeIndex={this.handleChangeIndex}
        />
      </div>
    );
  };

  onHover = hovering => {
    this.setState({ hover: hovering });
  };

  renderCarousellDesktop = () => {
    const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
    const { index } = this.state;
    const { children } = this.props;
    return (
      <div
        className="carousell"
        onMouseEnter={() => this.onHover(true)}
        onMouseLeave={() => this.onHover(false)}
      >
        <AutoPlaySwipeableViews
          index={this.state.index}
          onChangeIndex={this.handleChangeIndex}
          autoplay={this.state.hover}
          interval={1000}
        >
          {children}
        </AutoPlaySwipeableViews>
        <LoloftPagination
          dots={children.length}
          index={index}
          onChangeIndex={this.handleChangeIndex}
        />
      </div>
    );
  };

  //if component is rendered on mobile device, it will be shown with
  //dots beneath, and it will be swipeable
  //if it is rendered on desktop, images will be swiped on hover
  render() {
    const { children } = this.props;
    if (children) {
      return (
        <div className="ui grid">
          <div className="sixteen column computer tablet only row">
            {this.renderCarousellDesktop()}
          </div>
          <div className="sixteen column mobile only row">
            {this.renderCarousellMobile()}
          </div>
        </div>
      );
    }
    return null;
  }
}

export default LoloftCarousell;
