export const registrationForm = {
  firstName: {
    label: "Guest First Name",
    placeholder: "e.g. John"
  },
  lastName: {
    label: "Guest Last Name",
    placeholder: "e.g. Wick"
  },
  email: {
    label: "Email",
    placeholder: "Enter Your Email"
  },
  password: {
    label: "Password",
    placeholder: "Enter Your Password"
  },
  phone: {
    label: "Phone Number",
    placeholder: "+1"
  },
  companyName: {
    label: "Company Name",
    placeholder: "e.g. Company Ltd."
  },
  companyAddress: {
    label: "Company Address",
    placeholder: "Company Address"
  },
  companyCheckbox: {
    label: "This is paid by a company"
  },
  termsAndServiceCheckbox: {
    label:
      "By submitting your application you agree with the <b>terms of service</b>."
  },
  buttonText: "Request to Book"
};
