import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import eq from "lodash/eq";
import debounce from "lodash/debounce";

import { Form, Checkbox } from "semantic-ui-react";

import LoloftErrorMessage from "../LoloftErrorMessage";
import LoloftSuccessMessage from "../LoloftSuccessMessage";
import { submitPersonalInformation } from "../../../store/actions/onboarding";
import { personalInformationData } from "../../../statics/pages/member-onboarding-page";
import { formatDate } from "../../../utils/formatDate";
import {
  validateStringLength,
  validateSSN,
  validateRequired,
} from "../../../utils/validation";

class PersonalInformationStep extends Component {
  state = {
    termsAndService: false,
    fullName: "",
    ssn: "",
    dateOfBirth: "",
    errors: {},
  };

  componentWillMount() {
    const { data } = this.props;
    if (data) {
      this.setState({ ...data, termsAndService: false });
    }
  }

  //controlls input fields and removes field error message on field input change
  onChange = (e, data) => {
    const { name, value, checked } = data;
    const errors = { ...this.state.errors, [name]: undefined, auth: undefined };
    //company and termsAndService are checkbox fields, and they return thair value as checked property
    if (eq(name, "termsAndService")) {
      this.setState({ [name]: checked, errors });
    } else {
      this.setState({ [name]: value, errors });
    }
  };

  //returns js object with property named as field where error has occurred
  validate = () => {
    let errors = {};
    const fullNameError = validateStringLength(
      this.state.fullName,
      "Full Name",
      6,
      200
    );
    //if this component is part of account page, ssn will not be validated
    const ssnError = validateSSN(this.state.ssn, !this.props.accountView);
    const dateOfBirthError = validateRequired(
      this.state.dateOfBirth,
      "Date Of Birth"
    );

    if (fullNameError) {
      errors.fullName = fullNameError;
    }
    if (ssnError) {
      errors.ssn = ssnError;
    }
    if (dateOfBirthError) {
      errors.dateOfBirth = dateOfBirthError;
    }
    return errors;
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    if (!isEmpty(errors)) {
      return this.setState({ errors });
    }
    try {
      this.props.submitPersonalInformation(this.state);
      if (this.props.setCompletedStep) {
        this.props.setCompletedStep(this.props.step);
      }
      if (this.props.onUserAccountUpdate) {
        await this.props.onUserAccountUpdate("personalInformation", this.state);
        this.setState({ successMessage: "Update Successful" });
        debounce(2000, () => this.setState({ successMessage: undefined }));
      }
    } catch (e) {
      const errors = { ...this.state.errors, [e.name]: { text: e.message } };
      this.setState({ errors });
    }
  };

  //if this component is rendered as part of account page,
  //ssn will not be rendered
  renderSsn = () => {
    if (this.props.accountView) {
      return null;
    } else {
      const { ssnInput } = personalInformationData;
      return (
        <Fragment>
          <Form.Input
            onChange={this.onChange}
            label={ssnInput.label}
            name="ssn"
            placeholder={ssnInput.placeholder}
            value={this.state.ssn}
            error={this.state.errors.ssn ? true : false}
          />
          <LoloftErrorMessage parentState={this.state} name="ssn" />
        </Fragment>
      );
    }
  };

  //renders specific for or data for this step, and will bi directly used
  //for mobile view
  renderStep = () => {
    const {
      buttonLabel,
      checkboxLabel,
      fullNameInput,
      dateOfBirthInput,
    } = personalInformationData;
    return (
      <Fragment>
        <h2>{personalInformationData.title}</h2>
        <Form onSubmit={this.onSubmit} error>
          <LoloftSuccessMessage parentState={this.state} />
          <Form.Input
            onChange={this.onChange}
            label={fullNameInput.label}
            name="fullName"
            placeholder={fullNameInput.placeholder}
            value={this.state.fullName}
            error={this.state.errors.fullName ? true : false}
          />
          <LoloftErrorMessage parentState={this.state} name="fullName" />
          {this.renderSsn()}
          <Form.Input
            onChange={this.onChange}
            label={dateOfBirthInput.label}
            type="date"
            name="dateOfBirth"
            placeholder={dateOfBirthInput.placeholder}
            value={this.state.dateOfBirth}
            error={this.state.errors.dateOfBirth ? true : false}
          />
          <LoloftErrorMessage parentState={this.state} name="dateOfBirth" />
          <Checkbox
            className="full"
            label={checkboxLabel}
            name="termsAndService"
            checked={this.state.termsAndService}
            onChange={this.onChange}
          />
          <Form.Button
            disabled={!this.state.termsAndService}
            type="submit"
            primary
            fluid
          >
            {buttonLabel}
          </Form.Button>
        </Form>
      </Fragment>
    );
  };

  //renders information about data that user entered in this step
  renderCompletedInformation = () => {
    const pi = this.props.personalInformation;
    if (pi) {
      return (
        <div>
          <h2>{personalInformationData.title}</h2>
          <p>
            {pi.fullName}, born {formatDate(pi.dateOfBirth)}, {pi.ssn}
          </p>
        </div>
      );
    }
    return null;
  };

  //renders information about data that user entered in this step
  renderIncompleteInformation = () => {
    return <h2>{personalInformationData.title}</h2>;
  };

  //props
  //mobile - is this a view for mobile device
  //open - is this step currently in focus/ opened
  //competed - is this step already completed
  //setCompletedStep - will set this step as compete in parent,
  //which will make parent switch to the next step
  render() {
    const { open, completed, mobile } = this.props;
    if (mobile) {
      if (open) return this.renderStep();
      else return null;
    } else {
      if (open) return this.renderStep();
      else {
        if (completed) return this.renderCompletedInformation();
        else return this.renderIncompleteInformation();
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    personalInformation: state.onboarding.personalInformation,
  };
};

export default connect(mapStateToProps, { submitPersonalInformation })(
  PersonalInformationStep
);
