import React, { Component } from "react";
import { Checkbox } from "semantic-ui-react";
import { amenities, label } from "../../statics/amenities";

class LoloftAmenities extends Component {
  //if selected amenity is in amenities array, it should be selected
  isSelected = value => {
    return this.props.amenities.includes(value);
  };

  //whenever checkbox is selected, this function will be called
  onSelect = (e, { value }) => {
    const { amenities, onChange } = this.props;
    var amenitiesState;
    //if selected amenity is in amenities array, new array will be created
    //by filtering all elements of the old one, excluding the selected one
    if (amenities.includes(value)) {
      amenitiesState = amenities.filter(amenity => amenity !== value);
    } else {
      //otherwise, we will append selected amenity to the array of amenities
      amenitiesState = [...amenities, value];
    }
    onChange(amenitiesState);
  };

  render() {
    return (
      <div class="box">
        <h4>{label}</h4>
        {amenities.map(amenity => {
          if (amenity.notACheckbox) return null;
          return (
            <Checkbox
              key={amenity.value}
              checked={this.isSelected(amenity.value)}
              name="amenities"
              label={amenity.label}
              value={amenity.value}
              onChange={this.onSelect}
            />
          );
        })}
      </div>
    );
  }
}

export default LoloftAmenities;
