import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";

import { Visibility, Menu } from "semantic-ui-react";
import gravatar from "gravatar";

import { isUserLoggedIn } from "../../../utils/userUtility";
import { links } from "../../../statics/navbar";

class LoloftNavbarComputer extends Component {
  state = {};

  //will be triggered when user scrolls back to the top of the page
  unsetStickyMenu = () => {
    this.setState({ sticky: false });
  };
  //will be triggered when user scrolls down from the top of the page
  setStickyMenu = () => {
    this.setState({ sticky: true });
  };

  //return list of links generated from statics file
  renderLinks = () => {
    return (
      <Fragment>
        {links.map(link => {
          return (
            <Menu.Item key={link.title}>
              <Link to={link.to}>{link.title}</Link>
            </Menu.Item>
          );
        })}
      </Fragment>
    );
  };

  //return loggin link
  //if user is logged in, show user profile
  renderProfileButtons = () => {
    let link;
    if (isUserLoggedIn(this.props.user)) {
      link = (
        <Link to="/account">
          Account 
          <img 
            className="ui circular avatar image"
            src={gravatar.url(this.props.user.email)} alt="" />
        </Link>
      );
    } else {
      link = <Link to="/login">Login</Link>;
    }

    return <Menu.Item position="right">{link}</Menu.Item>;
  };

  //renders loloft icon
  renderIcon = () => {
    return (
      <Menu.Item className="permanent">
        <Link to="/">
          <img
            src="/assets/img/loloft-logo.svg"
            className="navbar--logo"
            alt=""
          />
        </Link>
      </Menu.Item>
    );
  };

  render() {
    return (
      //visibility is semantic ui component that detects when user scrolls from the top of the page
      //in our case, it will trigger changing of styling of navbar
      <Visibility
        once={false}
        onOffScreen={this.setStickyMenu}
        onOnScreen={this.unsetStickyMenu}
        continuous={true}
      >
        <Menu fixed="top">
          {this.renderIcon()}
          {this.renderLinks()}
          {this.renderProfileButtons()}
        </Menu>
      </Visibility>
    );
  }
}

export default LoloftNavbarComputer;
