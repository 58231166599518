import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { submitPropertyOnboardingInformation } from "../../store/actions/onboarding";
import { doneData } from "../../statics/pages/property-onboarding-page";

//props
//mobile - is this a view for mobile device
//open - is this step currently in focus/ opened
//competed - is this step already completed
//setCompletedStep - will set this step as compete in parent,
//which will make parent switch to the next step
const DoneStep = props => {
  const { open, completed, mobile } = props;
  if (mobile) {
    if (open) return renderStep(props);
    else return null;
  } else {
    if (open) return renderStep(props);
    else {
      if (completed) return renderCompletedInformation();
      else return renderIncompleteInformation();
    }
  }
};

//renders specific for or data for this step, and will bi directly used
//for mobile view
const renderStep = props => {
  return (
    <Fragment>
      <h2>{doneData.title}</h2>
      <div className="inline flex list">
        <img src="/assets/icons/icon-check-fill.svg" />
        <div className="content">
          <h4>{doneData.reviewing.title}</h4>
          <p>{doneData.reviewing.info}</p>
        </div>
      </div>
      <div className="inline flex list">
        <img src="/assets/icons/icon-check-fill.svg" />
        <div className="content">
          <h4>{doneData.help.title}</h4>
          <p>{doneData.help.info}</p>
        </div>
      </div>

      <Link to="/account">
        <Button onClick={() => onButtonClick(props)} primary fluid>
          {doneData.buttonLabel}
        </Button>
      </Link>
    </Fragment>
  );
};

//renders information about data that user entered in this step
const renderCompletedInformation = () => {};

//renders information about data that user entered in this step
const renderIncompleteInformation = () => {
  return <h2>{doneData.title}</h2>;
};

const onButtonClick = props => {
  props.submitPropertyOnboardingInformation();
};

export default connect(null, { submitPropertyOnboardingInformation })(DoneStep);
