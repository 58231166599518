export const header = {
  title: "Find Your Next Home",
  selectLabel: "Choose City",
  selectDefaultOption: "All",
  buttonText: "Find Places"
};

export const commitmentsData = {
  title: "No Leases",
  info:
    "Never sign another lease. Become a Loloft member and use any property in our network. Choose from one of our flexible plans",
  cards: [
    {
      key: "full-flexi",
      title: "Full-Flexi",
      info:
        "Gives you unlimited property switches after a 30 days minimum stay. You can also extend your stay at any time.",
      image: "/assets/img/homepage/card-full-flexi.png",
      additionalData: {
        text: "12 month term",
        popup: {
          title: "Live on Your Terms",
          info:
            "With Loloft you never have to sign another lease again. You simply commit to our 12 month subscription* and you're free to use any of the properties within our network. Prices vary depending on size and location.",
          asterisk:
            "* A Loloft subscription fee is the equivalent of a normal monthly rent payment. Instead of paying rent you pay your subscription fee each month."
        }
      }
    },
    {
      key: "flexi",
      title: "Flexi",
      info:
        "Switch properties twice per year. You can also extend your stay at any time.",
      image: "/assets/img/homepage/card-flexi.png"
    },

    {
      key: "fixed",
      title: "Fixed",
      info:
        "Found the apartment of your dreams? Lock it down so nobody else can get their hands on it.",
      image: "/assets/img/homepage/card-fixed.png"
    }
  ]
};

export const idealLocations = {
  title: "Ideal Locations",
  info:
    "Our expert curators pick the most ideal places in the most popular areas around the country.",
  tabs: [
    {
      active: true,
      key: "vibrant",
      title: "Vibrant",
      image: "/assets/img/shot-vibrant.jpg"
    },
    {
      key: "business",
      title: "Business",
      image: "/assets/img/shot-work.jpg"
    },
    {
      key: "night-life",
      title: "Night Life",
      image: "/assets/img/shot-night.jpg"
    }
  ]
};

export const cities = {
  title: "Our Cities",
  info: "Loloft covers most of the US",
  buttonLabel: "Find Places",
  cities: [
    {
      key: "san-francisco",
      title: "San Francisco",
      image: "/assets/img/cities/san-francisco.png"
    },
    {
      key: "miami",
      title: "Miami",
      image: "/assets/img/cities/miami.png"
    },
    {
      key: "los-angeles",
      title: "Los Angeles",
      image: "/assets/img/cities/angeles.png"
    },
    {
      key: "washington-dc",
      title: "Washington DC",
      image: "/assets/img/cities/washington.png"
    }
  ]
};

export const propertyOwners = {
  title: "Property Owners",
  info: "Rent through Loloft and get paid every month. Guaranteed.",
  buttonLabel: "Rent Your Place",
  items: [
    {
      title: "Peace Of Mind",
      info:
        "No headaches over vacancy and legal issues. We guarantee you’ll get paid on time every month — no matter what.",
      image: "/assets/img/homepage/peace-of-mind.png"
    },
    {
      title: "High Quality Residents",
      info:
        "We find the most suitable residents by partnering with trusted credit and background screening providers.",
      image: "/assets/img/homepage/high-quality-residents.png"
    },
    {
      title: "Guaranteed Care",
      info:
        "We ensure your property is well cared for. Our local field teams arrange cleaning, maintenance and repairs.",
      image: "/assets/img/homepage/guaranteed-care.png"
    }
  ]
};
