import React from "react";
import { Checkbox } from "semantic-ui-react";

import { Popup } from "semantic-ui-react";

import { commitmentTypes, label } from "../../statics/commitment-types";
import LoloftInfoButton from "./LoloftInfoButton";

const LoloftCommitmentTypes = props => {
  return (
    <div className="box">
      <h4>{label}</h4>
      {commitmentTypes.map(type => {
        //maps over commitment type array and returns a checkbox for each one
        return (
          <div className="commitment filter card" key={type.value}>
            <Checkbox
              radio
              name="commitmentType"
              value={type.value}
              checked={props.commitmentType === type.value}
              onChange={props.onChange}
            />
            <div className="contents">
              <div className="info">
                <h4>{type.title}</h4>
                <span>{type.excerpt}</span>
                <strong>{renderPrice(props, type)}</strong>
              </div>
              <Popup
                trigger={<LoloftInfoButton />}
                basic
                position="bottom center"
              >
                <div>{type.description}</div>
              </Popup>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const renderPrice = (props, commitmentType) => {
  if (props.price && commitmentType.multiplier) {
    return <div>{Math.ceil(props.price * commitmentType.multiplier)}</div>;
  }
};

export default LoloftCommitmentTypes;
