import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";

import { Form, Divider } from "semantic-ui-react";

import LoloftErrorMessage from "../../LoloftErrorMessage";
import history from "../../../../history";
import { login } from "../../../../store/actions/auth";
import { validateEmail, validatePassword } from "../../../../utils/validation";
import { loginForm } from "../../../../statics/pages/login-page";

class LoginForm extends Component {
  state = {
    email: "",
    password: "",
    errors: {},
  };

  //submit function will try to validate form data,
  //and only on successful validation will try to login user
  //on error, component error state will be updated
  onSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    if (!isEmpty(errors)) {
      return this.setState({ errors });
    }
    try {
      await this.props.login(this.state.email, this.state.password);
      history.push(`${this.props.urlPrefix}/sms-verification`);
    } catch (e) {
      const errors = { ...this.state.errors, auth: { text: e.message } };
      this.setState({ errors });
    }
  };

  //returns js object with property named as field where error has occurred
  validate = () => {
    let errors = {};
    const emailError = validateEmail(this.state.email);
    const passwordError = validatePassword(this.state.password);
    if (emailError) {
      errors.email = emailError;
    }
    if (passwordError) {
      errors.password = passwordError;
    }
    return errors;
  };

  //controlls input fields and removes field error message on field input change
  onChange = (e) => {
    const { name, value } = e.target;
    const errors = { ...this.state.errors, [name]: undefined, auth: undefined };
    this.setState({ [name]: value, errors });
  };

  renderTitle = () => {
    if (this.props.renderTitle) {
      return <h1>{loginForm.title}</h1>;
    }
  };

  render() {
    const { emailInput, passwordInput, forgottenLink, buttonText } = loginForm;
    return (
      <div className="basic box">
        {this.renderTitle()}
        <Form onSubmit={this.onSubmit} error>
          <Form.Input
            onChange={this.onChange}
            label={emailInput.label}
            name="email"
            placeholder={emailInput.placeholder}
            value={this.state.email}
            icon="mail"
            iconPosition="left"
            error={
              this.state.errors.email || this.state.errors.auth ? true : false
            }
          />
          <LoloftErrorMessage parentState={this.state} name="email" />
          <Form.Input
            type="password"
            onChange={this.onChange}
            label={passwordInput.label}
            name="password"
            placeholder={passwordInput.placeholder}
            value={this.state.password}
            icon="lock"
            iconPosition="left"
            error={
              this.state.errors.password || this.state.errors.auth
                ? true
                : false
            }
          />
          <LoloftErrorMessage parentState={this.state} name="password" />
          <LoloftErrorMessage parentState={this.state} name="auth" />

          <div className="right aligned">
            <Link to={`${this.props.urlPrefix}/forgotten-password`}>
              {forgottenLink}
            </Link>
          </div>
          <Divider horizontal></Divider>
          <Form.Button type="submit" primary fluid>
            {buttonText}
          </Form.Button>
        </Form>
      </div>
    );
  }
}

export default connect(null, { login })(LoginForm);
