import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid } from "semantic-ui-react";

import history from "../../history";
import { logout } from "../../store/actions/auth";
import YourAccountStep from "../../components/common/loloft-user-profile/YourAccountStep";
import LoloftProgressBar from "../../components/common/LoloftProgressBar";
import OnboardingStep from "../../components/common/OnboardingStep";
import AboutThePropertyStep from "./AboutThePropertyStep";
import PropertyAddressStep from "./PropertyAddressStep";
import PropertyDetailsStep from "./PropertyDetailsStep";
import DoneStep from "./DoneStep";
import { injectOnboardingPageStyle } from "../../utils/bodyStyleInjector";

class PropertyOnboardingPage extends Component {
  state = { completedStep: 0, currentStep: 1, changeAccount: false };

  componentDidMount() {
    if (this.props.user) {
      this.setState({ completedStep: 1, currentStep: 2 });
    }
  }

  //this method is used to switch back to first step, if user decides to change account
  setChangeAccount = (value) => {
    if (value) {
      this.props.logout();
    }
    this.setState({ changeAccount: value });
  };

  //this method is used when user completes one step of onboarding
  setCompletedStep = (step) => {
    if (step > this.state.completedStep) {
      this.setState({ completedStep: step, currentStep: step + 1 });
    }
  };

  renderSteps = (mobile = false) => {
    const { currentStep, completedStep, changeAccount } = this.state;
    var i = 1;
    return (
      <div className="basic box">
        <LoloftProgressBar currentStep={completedStep} totalSteps={6} />
        <OnboardingStep
          mobile={mobile}
          open={currentStep === i} //i == 1
          completed={completedStep >= i}
          step={i++}
          path={history.location.pathname}
          setCompletedStep={this.setCompletedStep}
          changeAccount={changeAccount}
          setChangeAccount={this.setChangeAccount}
          owner="property-onboarding"
        >
          <YourAccountStep />
        </OnboardingStep>
        <OnboardingStep
          mobile={mobile}
          open={currentStep === i}
          completed={completedStep >= i}
          step={i++}
          path={history.location.pathname}
          setCompletedStep={this.setCompletedStep}
        >
          <AboutThePropertyStep />
        </OnboardingStep>
        <OnboardingStep
          mobile={mobile}
          open={currentStep === i}
          completed={completedStep >= i}
          step={i++}
          path={history.location.pathname}
          setCompletedStep={this.setCompletedStep}
        >
          <PropertyAddressStep />
        </OnboardingStep>
        <OnboardingStep
          mobile={mobile}
          open={currentStep === i}
          completed={completedStep >= i}
          step={i++}
          path={history.location.pathname}
          setCompletedStep={this.setCompletedStep}
        >
          <PropertyDetailsStep />
        </OnboardingStep>
        <OnboardingStep
          mobile={mobile}
          open={currentStep === i}
          completed={completedStep >= i}
          step={i++}
          path={history.location.pathname}
        >
          <DoneStep />
        </OnboardingStep>
      </div>
    );
  };

  renderComputer = () => {
    return (
      <Grid stackable reversed="mobile">
        <Grid.Column verticalAlign="middle" width={8}>
          {this.renderSteps()}
        </Grid.Column>
        <Grid.Column width={8} className="login-bg"></Grid.Column>
      </Grid>
    );
  };

  render() {
    injectOnboardingPageStyle();
    return <div className="login-switch">{this.renderComputer()}</div>;
  }
}

const mapStateToProps = (state) => {
  return { user: state.auth.user };
};

export default connect(mapStateToProps, { logout })(PropertyOnboardingPage);
