import React from "react";

import { Popup } from "semantic-ui-react";

import LoloftInfoButton from "../../components/common/LoloftInfoButton";

import { bookingPropertyPageData } from "../../statics/pages/booking-property-page";
import {
  commitmentTypes,
  priceBreakdown,
} from "../../statics/commitment-types";

function BookingData(props) {
  const { property, dates } = props.bookingData;
  const { commitmentType, date } = dates;
  const { price, deposit } = property;
  return (
    <div className="loloft-timeline card">
      <div className="row">
        <div className="row-prefix">
          <img src="/assets/icons/icon-arrow-right.svg" alt="" />
        </div>
        <div className="row-contents">
          <label>{bookingPropertyPageData.moveIn}</label>
          <strong>{renderMoveInDate(date)}</strong>
        </div>
      </div>
      <div className="row">
        <div className="row-prefix">
          <img src="/assets/icons/icon-arrow-left.svg" alt="" />
        </div>
        <div className="row-contents">
          <label>{bookingPropertyPageData.moveOut}</label>
          <strong>{renderMoveOutDate(date, commitmentType)}</strong>
        </div>
      </div>
      <div className="row row-info">{renderCommitmentType(commitmentType)}</div>
      <div className="row">
        <div className="row-prefix">
          <img src="/assets/icons/icon-dollar-sign.svg" alt="" />
        </div>
        <div className="row-prefix-inside">
          <div className="row">
            <div className="row-contents">
              <label>{bookingPropertyPageData.monthly}</label>
              <strong>${renderPrice(commitmentType, price)}</strong>
            </div>
          </div>
          <div className="row">
            <div className="row-contents">
              <label>{bookingPropertyPageData.deposit.title}</label>
              <strong>${deposit}</strong>
            </div>
          </div>
          <div className="row row-info">
            <small>{bookingPropertyPageData.deposit.info}</small>
          </div>
          <div className="row">
            <div className="row-contents">
              <label>{bookingPropertyPageData.fee.title}</label>
              <strong>${priceBreakdown.registrationFee.value}</strong>
            </div>
          </div>
          <div className="row row-info">
            <small>{bookingPropertyPageData.fee.info}</small>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="row-prefix">
          <img src="/assets/icons/icon-check.svg" alt="" />
        </div>
        <div className="row-contents">
          <label>{bookingPropertyPageData.dueToday}</label>
          <strong>{getTotalPrice(commitmentType, price, deposit)}</strong>
        </div>
      </div>
    </div>
  );
}

const renderCommitmentType = (commitmentType) => {
  const type = getCommitmentTypeObject(commitmentType);
  return (
    <div className="commitment">
      <span className="ui yellow label">{type.title}</span>
      <Popup trigger={<LoloftInfoButton />} basic position="bottom center">
        <h4>{type.title}</h4>
        <p>{type.description}</p>
      </Popup>
    </div>
  );
};

const renderMoveInDate = (date) => {
  return date.format("MMMM D, YYYY");
};

const renderMoveOutDate = (date, commitmentType) => {
  const type = getCommitmentTypeObject(commitmentType);
  return date.add(type.months, "months").format("MMMM D, YYYY");
};

const renderPrice = (commitmentType, price) => {
  const priceBreakdownObject = getPriceBreakdownObject(commitmentType);
  return Math.ceil(priceBreakdownObject.multiplier * price);
};

const getCommitmentTypeObject = (commitmentType) => {
  return commitmentTypes.find(
    (type) => type.value.localeCompare(commitmentType) === 0
  );
};

const getPriceBreakdownObject = (commitmentType) => {
  return priceBreakdown.commitmentType.find(
    (priceBreakdown) => priceBreakdown.value.localeCompare(commitmentType) === 0
  );
};

const getTotalPrice = (commitmentType, price, deposit) => {
  const priceBreakdownObject = getPriceBreakdownObject(commitmentType);
  const totalPrice =
    Math.ceil(priceBreakdownObject.multiplier * price) +
    deposit +
    Number(priceBreakdown.registrationFee.value);
  return totalPrice;
};

export default BookingData;
