import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import eq from "lodash/eq";
import { Form } from "semantic-ui-react";

import { submitAboutPropertyInformation } from "../../store/actions/onboarding";
import { aboutPropertyData } from "../../statics/pages/property-onboarding-page";
import LoloftPlusMinusInput from "../../components/common/LoloftPlusMinusInput";

import LoloftDropdown from "../../components/common/LoloftDropdown";

class AboutThePropertyStep extends Component {
  state = {
    ownerType: "private",
    propertyType: "apartment",
    bedrooms: 1,
  };

  //controlls input fields and removes field error message on field input change
  onChange = (e, data) => {
    const { name, value } = data;
    const errors = { ...this.state.errors, [name]: undefined };
    this.setState({ [name]: value, errors });
  };

  //setting state on plus minus input fields
  onPlusMinusChange = (name, value) => {
    this.setState({ [name]: value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.submitAboutPropertyInformation(this.state);
    this.props.setCompletedStep(this.props.step);
  };

  //renders information about data that user entered in this step
  renderCompletedInformation = () => {
    const ap = this.props.aboutProperty;
    if (ap) {
      const ownerType = aboutPropertyData.ownerTypeDropdown.values.find(
        (type) => {
          return eq(type.value, ap.ownerType);
        }
      );
      const propertyType = aboutPropertyData.propertyTypeDropdown.values.find(
        (type) => {
          return eq(type.value, ap.propertyType);
        }
      );
      return (
        <div>
          <h2>{aboutPropertyData.title}</h2>
          <p>{`${ownerType ? ownerType.text + ", " : ""}${
            ap.bedrooms
          } Bedroom ${propertyType ? propertyType.text : ""}`}</p>
        </div>
      );
    }
    return null;
  };

  //renders information about data that user entered in this step
  renderIncompleteInformation = () => {
    return <h2>{aboutPropertyData.title}</h2>;
  };

  //renders specific for or data for this step, and will bi directly used
  //for mobile view
  renderStep = () => {
    const {
      bedroomsInput,
      buttonLabel,
      propertyTypeDropdown,
      ownerTypeDropdown,
      title,
    } = aboutPropertyData;
    return (
      <Fragment>
        <h2>{title}</h2>
        <Form error>
          <div className="field">
            <LoloftDropdown
              name="ownerType"
              onChange={this.onChange}
              value={this.state.ownerType}
              options={ownerTypeDropdown.values}
              dropdownLabel={ownerTypeDropdown.label}
              fluid
            />
          </div>
          <div className="field">
            <LoloftDropdown
              name="propertyType"
              onChange={this.onChange}
              value={this.state.propertyType}
              options={propertyTypeDropdown.values}
              dropdownLabel={propertyTypeDropdown.label}
              fluid
            />
          </div>
          <LoloftPlusMinusInput
            onPlusMinusChange={this.onPlusMinusChange}
            value={this.state.bedrooms}
            name="bedrooms"
            label={bedroomsInput.label}
            min={1}
          />
          <Form.Button type="submit" primary fluid onClick={this.onSubmit}>
            {buttonLabel}
          </Form.Button>
        </Form>
      </Fragment>
    );
  };

  //props
  //mobile - is this a view for mobile device
  //open - is this step currently in focus/ opened
  //competed - is this step already completed
  //setCompletedStep - will set this step as compete in parent,
  //which will make parent switch to the next step
  render() {
    const { open, completed, mobile } = this.props;
    if (mobile) {
      if (open) return this.renderStep();
      else return null;
    } else {
      if (open) return this.renderStep();
      else {
        if (completed) return this.renderCompletedInformation();
        else return this.renderIncompleteInformation();
      }
    }
  }
}

const mapStateToProps = (state) => {
  return { aboutProperty: state.onboarding.aboutProperty };
};

export default connect(mapStateToProps, { submitAboutPropertyInformation })(
  AboutThePropertyStep
);
