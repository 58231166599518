import { combineReducers } from "redux";

import authReducer from "./authReducer";
import propertiesReducer from "./propertiesReducer";
import sidebarReducer from "./sidebarReducer";
import onboardingReducer from "./onboardingReducer";
import bookingReducer from "./bookingReducer";

export default combineReducers({
  auth: authReducer,
  properties: propertiesReducer,
  sidebar: sidebarReducer,
  onboarding: onboardingReducer,
  booking: bookingReducer,
});
