export const sidebar = [
  {
    title: "Properties",
    url: "properties",
    type: ["owner"],
    icon: "/assets/icons/home.svg",
  },
  {
    title: "Personal Details",
    url: "personal-details-member",
    type: ["member"],
    icon: "/assets/icons/user.svg",
  },
  {
    title: "Personal Details",
    url: "personal-details-owner",
    type: ["owner"],
    icon: "/assets/icons/user.svg",
  },
  {
    title: "Company",
    url: "company",
    type: ["owner"],
    icon: "/assets/icons/user.svg",
  },
  {
    title: "Employment",
    url: "employment",
    type: ["member"],
    icon: "/assets/icons/user.svg",
  },
  {
    title: "Current Address",
    url: "current-address",
    type: ["member"],
    icon: "/assets/icons/user.svg",
  },
  {
    title: "Billing",
    url: "billing",
    type: ["owner", "member"],
    icon: "/assets/icons/icon-dollar-sign.svg",
  },
  {
    title: "Security",
    url: "security",
    type: ["owner", "member"],
    icon: "/assets/icons/icon-dollar-sign.svg",
  },
];

export const securityData = {
  title: "Account Security",
  email: {
    label: "Email",
    placeholder: "Enter Your Email",
  },
  password: {
    label: "Password",
    placeholder: "Enter Your Password",
  },
  continueButtonLabel: "Continue",
  updateButtonLabel: "Update",
};

export const pendingStateData = {
  title: "Your Application Is Under Review",
  text: "We’ll let you know once everything is approved and ready to go.",
  viewPropertyButtonLabel: "View Property",
};

export const rejectedStateData = {
  text: "Sorry, ",
  title: "Your Application Was Rejected",
  submitAgainButtonLabel: "Submit Again",
};

export const suspendedStateData = {
  title: "Your Account Is Now Suspended",
  updatePaymentMethodButtonLabel: "Update Payment Method",
};
