import React, { Fragment } from "react";
import { Grid } from "semantic-ui-react";

//abstract wrapper used for layouting onboarding steps
function OnboardingStep(props) {
  const { open, completed, mobile } = props;
  if (mobile) {
    if (open) return getChildWithProps(props);
    else return null;
  } else {
    if (open) return renderOpened(props);
    else {
      if (completed) return renderColosedCompleted(props);
      else return renderClosedIncomplete(props);
    }
  }
}

//renders member onboarding step when it is opened/in focus
const renderOpened = props => {
  return (
    <div className="active flex action-row">
      <div className="indicator">
        {props.step}
      </div>
      <div className="contents">{getChildWithProps(props)}</div>
    </div>
  );
};

//renders member onboarding step when it is not opened/in focus,
//but it's already completed
const renderColosedCompleted = props => {
  return (
    <div className="done flex action-row">
      <div className="indicator">
        {props.step}
      </div>
      <div className="contents">{getChildWithProps(props)}</div>
    </div>
  );
};

//renders member onboarding step when it is not opened/in focus,
//and it's not completed
const renderClosedIncomplete = props => {
  return (
    <div className="closed flex action-row">
      <div className="indicator">
        {props.step}
      </div>
      <div className="contents">{getChildWithProps(props)}</div>
    </div>
  );
};

//this fuction will create a child JSX element and will add
//to it all props. We use it so we do not have to pass properties to
//both onboarding step and child element
const getChildWithProps = props => {
  return (
    <Fragment>
      {React.Children.map(props.children, child =>
        React.cloneElement(child, { ...props })
      )}
    </Fragment>
  );
};

export default OnboardingStep;
