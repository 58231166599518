import React from "react";

import "../../../assets/css/loloft-v2/loloft-carousell.scss";

const LoloftPaginationDot = props => {
  const { active } = props;
  return (
    <button
      type="button"
      className="dot-button"
      onClick={() => handleClick(props)}
    >
      <div className={`dot ${active ? "dot-active" : ""}`} />
    </button>
  );
};

const handleClick = props => {
  props.onClick(props.index);
};

export default LoloftPaginationDot;
