export const propertiesPage = {
  selectDatesButton: "Select Dates",
  selectDatesModalTitle: "Select Dates",
  setFiltersButton: "Set Filters",
  setFiltersModalTitle: "Filters"
};

export const setDatesData = {
  mobileTitle: "Length Of Stay",
  calendarLabel: "Move In Date",
  buttonLabel: "Show",
  resetLabel: "Clear Dates",
  resetLabelMobile: "Clear"
};

export const setFiltersData = {
  mobileTitle: "Length Of Stay",
  livingSpace: {
    label: "Living Space",
    bedrooms: { label: "Bedrooms" },
    parkingSpaces: { label: "Parking Spaces" },
    bathrooms: { label: "Bathrooms" },
    petsAllowed: {
      label: "Pets Allowed?",
      values: [
        { text: "Maybe", value: "maybe" },
        { text: "Yes", value: "yes" },
        { text: "No", value: "no" }
      ]
    }
  },
  maximumBudget: {
    label: "Maximum Monthly Budget",
    max: 10000
  },
  buttonLabel: "Show",
  resetLabel: "Clear Filters",
  resetLabelMobile: "Clear"
};

export const propertiesContainerData = {
  availableLabel: "Available Today",
  showMoreButtonLabel: "Show More"
};
