import {
  REMOVE_USER,
  SET_USER,
  PHONE_VERIFIED,
  SET_VERIFIED_USER,
} from "../types";

const initialState = {
  user: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { user: action.payload };
    case SET_VERIFIED_USER:
      return { user: action.payload };
    case REMOVE_USER:
      return { ...initialState };
    case PHONE_VERIFIED:
      return { user: { ...state.user, phoneCheck: true } };
    default:
      return state;
  }
};
