import React from "react";

import { Popup } from "semantic-ui-react";
import {
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon
} from "react-share";

import ModalDialog from "../modal/ModalDialog";
import LoloftCarousellWithArrows from "../../components/common/loloft-carousell/LoloftCarousellWithArrows";
import { propertyPage } from "../../statics/pages/property-page";

const PropertyImage = props => {
  const { images } = props.property;
  if (!props.property) return null;
  if (images && images.length > 0)
    return (
      <div className="slider">
        {renderCarousell(images)}
        {renderButtons(props.property)}
      </div>
    );
};

const renderButtons = property => {
  return (
    <div className="property-actions">
      <Popup
        trigger={renderShareButton()}
        basic
        position="bottom center"
        on="click"
      >
        <Popup.Content>
          <FacebookShareButton
            url={`${propertyPage.share.facebook.url}${property.id}`}
            quote={propertyPage.share.facebook.title}
          >
            {propertyPage.share.facebook.buttonLabel}
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <EmailShareButton
            url={`${propertyPage.share.email.url}${property.id}`}
            subject={propertyPage.share.email.title}
            body="POPULATE A BODY WITH TEXT!!!"
            separator=" "
          >
            {propertyPage.share.email.buttonLabel}
            <EmailIcon size={32} round />
          </EmailShareButton>
        </Popup.Content>
      </Popup>
      <ModalDialog trigger={renderCameraButton()}>
        {renderCarousellAsModal(property.images)}
      </ModalDialog>
    </div>
  );
};

const renderCarousell = images => {
  return (
    <LoloftCarousellWithArrows>
      {images.map(img => (
        <img key={img.image} src={img.image} className="ui centered image" />
      ))}
    </LoloftCarousellWithArrows>
  );
};

const renderCarousellAsModal = images => {
  return (
    <LoloftCarousellWithArrows images={images}>
      {images.map(img => (
        <img key={img.image} src={img.image} />
      ))}
    </LoloftCarousellWithArrows>
  );
};

const renderShareButton = () => {
  return (
    <span>
      <img src="/assets/icons/icon-share-white.svg" />
    </span>
  );
};

const renderCameraButton = () => {
  return (
    <span>
      <img src="/assets/icons/icon-camera-white.svg" />
    </span>
  );
};

export default PropertyImage;
