import React, { Component } from "react";
import { Form, Segment, Checkbox } from "semantic-ui-react";
import isEmpty from "lodash/isEmpty";
import eq from "lodash/eq";

import history from "../../../../history";
import { register } from "../../../../store/actions/auth";
import LoloftErrorMessage from "../../LoloftErrorMessage";
import {
  validateEmail,
  validatePassword,
  validateMobilePhone,
  validateStringLength,
} from "../../../../utils/validation";
import { registrationForm } from "../../../../statics/pages/registration-page";

class RegisterForm extends Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    companyName: "",
    companyAddress: "",
    errors: {},
    company: false,
    termsAndService: false,
  };

  componentDidMount() {}

  //submit function will try to validate form data,
  //and only on successful validation will try to register user
  //on error, component error state will be updated
  onSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    if (!isEmpty(errors)) {
      return this.setState({ errors });
    }
    try {
      await register(this.state);
      history.push(`${this.props.urlPrefix}/sms-verification`);
    } catch (e) {
      const errors = { ...this.state.errors, [e.name]: { text: e.message } };
      this.setState({ errors });
    }
  };

  //returns js object with property named as field where error has occurred
  validate = () => {
    let errors = {};
    const emailError = validateEmail(this.state.email);
    const passwordError = validatePassword(this.state.password);
    const firstNameError = validateStringLength(
      this.state.firstName,
      "First Name",
      1,
      30
    );
    const lastNameError = validateStringLength(
      this.state.lastName,
      "Last Name",
      1,
      30
    );
    const phoneError = validateMobilePhone(this.state.phone);
    let companyNameError;
    let companyAddressError;
    if (this.state.company) {
      companyNameError = validateStringLength(
        this.state.companyName,
        "Company Name",
        1,
        30
      );
      companyAddressError = validateStringLength(
        this.state.companyAddress,
        "Company Address",
        1,
        30
      );
    }

    if (emailError) {
      errors.email = emailError;
    }
    if (passwordError) {
      errors.password = passwordError;
    }
    if (firstNameError) {
      errors.firstName = firstNameError;
    }
    if (lastNameError) {
      errors.lastName = lastNameError;
    }
    if (phoneError) {
      errors.phone = phoneError;
    }
    if (companyNameError) {
      errors.companyName = companyNameError;
    }
    if (companyAddressError) {
      errors.companyAddress = companyAddressError;
    }

    return errors;
  };

  //controlls input fields and removes field error message on field input change
  onChange = (e, data) => {
    const { name, value, checked } = data;
    const errors = { ...this.state.errors, [name]: undefined, auth: undefined };
    //company and termsAndService are checkbox fields, and they return thair value as checked property
    if (eq(name, "company") || eq(name, "termsAndService")) {
      this.setState({ [name]: checked, errors });
    } else {
      this.setState({ [name]: value, errors });
    }
  };

  renderCompanyFields = () => {
    const { companyAddress, companyName } = registrationForm;
    if (this.state.company) {
      return (
        <div className="block">
          <Form.Input
            onChange={this.onChange}
            label={companyAddress.label}
            name="firstName"
            placeholder={companyAddress.placeholder}
            value={this.state.companyAddress}
            error={this.state.errors.companyAddress ? true : false}
          />
          <LoloftErrorMessage parentState={this.state} name="companyAddress" />
          <Form.Input
            onChange={this.onChange}
            label={companyName.label}
            name="companyName"
            placeholder={companyName.placeholder}
            value={this.state.companyName}
            error={this.state.errors.companyName ? true : false}
          />
          <LoloftErrorMessage parentState={this.state} name="companyName" />
        </div>
      );
    }
  };

  render() {
    const {
      companyCheckbox,
      termsAndServiceCheckbox,
      email,
      firstName,
      lastName,
      password,
      phone,
      buttonText,
    } = registrationForm;

    return (
      <Segment>
        <Form onSubmit={this.onSubmit} error>
          <LoloftErrorMessage parentState={this.state} name="auth" />
          <Form.Input
            onChange={this.onChange}
            label={firstName.label}
            name="firstName"
            placeholder={firstName.placeholder}
            value={this.state.firstName}
            error={this.state.errors.firstName ? true : false}
            icon="user"
            iconPosition="left"
          />
          <LoloftErrorMessage parentState={this.state} name="firstName" />
          <Form.Input
            onChange={this.onChange}
            label={lastName.label}
            name="lastName"
            placeholder={lastName.placeholder}
            value={this.state.lastName}
            error={this.state.errors.lastName ? true : false}
            icon="user"
            iconPosition="left"
          />
          <LoloftErrorMessage parentState={this.state} name="lastName" />
          <Form.Input
            onChange={this.onChange}
            label={phone.label}
            name="phone"
            placeholder={phone.placeholder}
            value={this.state.phone}
            error={this.state.errors.phone ? true : false}
            icon="phone"
            iconPosition="left"
          />
          <LoloftErrorMessage parentState={this.state} name="phone" />
          <Form.Input
            onChange={this.onChange}
            label={email.label}
            name="email"
            placeholder={email.placeholder}
            value={this.state.email}
            error={
              this.state.errors.email || this.state.errors.auth ? true : false
            }
            icon="mail"
            iconPosition="left"
          />
          <LoloftErrorMessage parentState={this.state} name="email" />
          <Form.Input
            type="password"
            onChange={this.onChange}
            label={password.label}
            name="password"
            placeholder={password.placeholder}
            value={this.state.password}
            error={this.state.errors.password ? true : false}
            icon="lock"
            iconPosition="left"
          />
          <LoloftErrorMessage parentState={this.state} name="password" />
          <Checkbox
            className="full"
            label={companyCheckbox.label}
            name="company"
            checked={this.state.company}
            onChange={this.onChange}
          />
          {this.renderCompanyFields()}
          <Checkbox
            className="full"
            label={termsAndServiceCheckbox.label}
            name="termsAndService"
            checked={this.state.termsAndService}
            onChange={this.onChange}
          />
          <Form.Button
            disabled={!this.state.termsAndService}
            type="submit"
            primary
            fluid
          >
            {buttonText}
          </Form.Button>
        </Form>
      </Segment>
    );
  }
}

export default RegisterForm;
