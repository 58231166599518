import React from "react";
import { Button } from "semantic-ui-react";

import LoloftCarousell from "../../../components/common/loloft-carousell/LoloftCarousell";

import { propertyOwners } from "../../../statics/pages/home-page";

const renderPropertyItem = item => {
  return (
    <div className="property-owners-item">
      <img
        className="ui centered circular image"
        width="180"
        src={item.image}
        alt=""
      />
      <h3>{item.title}</h3>
      <p>{item.info}</p>
    </div>
  );
};

const renderPlusItem = () => {
  return (
    <div className="property-owners-item--plus">
      <img src="/assets/icons/round-plus-icon.svg" alt="" />
    </div>
  );
};

const renderProperyOwners = items => {
  return (
    <div className="ui stackable grid container">
      <div className="four wide column">{renderPropertyItem(items[0])}</div>
      <div className="two wide column">{renderPlusItem()}</div>
      <div className="four wide column">{renderPropertyItem(items[1])}</div>
      <div className="two wide column">{renderPlusItem()}</div>
      <div className="four wide column">{renderPropertyItem(items[2])}</div>
    </div>
  );
};

const renderProperyOwnersForMobile = items => {
  return (
    <div className="ui center aligned container">
      <LoloftCarousell>
        {renderPropertyItem(items[0])}
        {renderPropertyItem(items[1])}
        {renderPropertyItem(items[2])}
      </LoloftCarousell>
    </div>
  );
};

const PropertyOwners = () => {
  const { items, title, info, buttonLabel } = propertyOwners;
  if (items.length >= 3) {
    return (
      <div className="property-owners-panel">
        <h2>{title}</h2>
        <div className="section-info">{info}</div>
        <div className="ui stackable grid">
          <div className="sixteen column computer only row">
            {renderProperyOwners(items)}
          </div>
          <div className="sixteen column mobile only row">
            {renderProperyOwnersForMobile(items)}
          </div>
        </div>
        <div className="ui center aligned stackable grid">
          <div className="property-owners-panel--button-wrapper">
            <Button primary>{buttonLabel}</Button>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default PropertyOwners;
