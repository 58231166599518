import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Button, Grid } from "semantic-ui-react";

import { setDates, getPropertiesCount } from "../../store/actions/properties";
import LoloftCommitmentTypes from "../../components/common/LoloftCommitmentTypes";
import LoloftCalendar from "../../components/common/LoloftCalendar";
import LoloftPropertyStates from "../../components/common/LoloftPropertyStates";
import { setDatesData } from "../../statics/pages/browse-properties-page";

//moment is necessary dependancy, because date picker keeps
//values in specific format which is easily created by moment
class SelectDates extends Component {
  getInitialState = () => {
    return {
      date: moment(),
      propertyState: "furnished",
      commitmentType: "full-flexi"
    };
  };

  state = this.getInitialState();

  componentDidMount() {
    if (this.props.dates) {
      this.setState(this.props.dates);
    }
    this.props.getPropertiesCount(null, this.props.dates);
  }

  //anytime state is change, it will be done trough this function
  //and this function will also do getPropertiesCount action
  //which will try to fetch number of properties that are available for set
  //dates and filters and location
  setStateAndFetchCount = newState => {
    const state = { ...this.state, ...newState };
    this.setState(state);
    this.props.getPropertiesCount(null, state);
  };

  //on calendar selection change
  onDateChange = date => {
    this.setStateAndFetchCount({ date });
  };

  //on commitment type and property state change
  onChange = (e, { name, value }) => {
    this.setStateAndFetchCount({ [name]: value });
  };

  //restores initial state
  resetDates = () => {
    this.setStateAndFetchCount(this.getInitialState());
  };

  //this action will set dates in app state
  //and then will close modal window - if desktop
  //or return to browse properties view - if mobile
  onSubmit = () => {
    this.props.setDates(this.state);
    this.props.onSubmit();
    this.props.closeAction();
  };

  renderResetLink = label => {
    return (
      <a href="#" onClick={this.resetDates}>
        {label}
      </a>
    );
  };

  renderSubmitButton = () => {
    return (
      <Button primary
        onClick={this.onSubmit}
      >{//this is dynamicly set text that will show number of properties available on
      //on set date, with set property state, and set commitment
      `${setDatesData.buttonLabel} ${
        this.props.count !== undefined ? this.props.count : ""
      }`}</Button>
    );
  };

  renderForComputer = () => {
    return (
      <Grid stackable relaxed>
        <Grid.Column width={8}>
          <LoloftCalendar
            date={this.state.date}
            onChange={this.onDateChange}
            label={setDatesData.calendarLabel}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <LoloftPropertyStates
            onChange={this.onChange}
            propertyState={this.state.propertyState}
          />
          <LoloftCommitmentTypes
            onChange={this.onChange}
            commitmentType={this.state.commitmentType}
          />
        </Grid.Column>
        <Grid.Column width={16}>
          <div className="right actions">
            {this.renderResetLink(setDatesData.resetLabel)}
            {this.renderSubmitButton()}
          </div>
        </Grid.Column>
      </Grid>
    );
  };

  render() {
    return (
      <div className="switch">
        {this.renderForComputer()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    dates: state.properties.dates,
    count: state.properties.filteredCount
  };
};

export default connect(mapStateToProps, { setDates, getPropertiesCount })(
  SelectDates
);
