import React from "react";

const LoloftImagePagination = props => {
  const { index, images } = props;
  const children = [];

  for (let i = 0; i < images.length; i += 1) {
    children.push(
      <div key={i} style={{ display: "inline-block" }}>
        <div>{images[i].room}</div>
        <img
          width="100px"
          height="100px"
          src={images[i].image}
          index={i}
          //  active={i === index}
          onClick={() => handleClick(props, i)}
        />
      </div>
    );
  }
  return <div>{children}</div>;
};

const handleClick = (props, i) => {
  props.onChangeIndex(i);
};

export default LoloftImagePagination;
