import eq from "lodash/eq";
import {
  ONBOARDING_CURRENT_ADDRESS,
  ONBOARDING_EMPLOYMENT_DETAILS,
  ONBOARDING_ADD_PAYMENT_INFORMATION,
  ONBOARDING_REMOVE_PAYMENT_INFORMATION,
  ONBOARDING_LOAD_PAYMENT_INFORMATION,
  ONBOARDING_PERSONAL_INFORMATION,
  ONBOARDING_ABOUT_PROPERTY,
  ONBOARDING_PROPERTY_ADDRESS,
  ONBOARDING_PROPERTY_DETAILS,
  GET_ONBOARDING_DATA,
} from "../types";

const initialState = {
  currentAddress: {},
  employmentDetails: {},
  paymentInformation: { creditCards: [], bankAccounts: [] },
  personalInformation: {},
  photoID: {},
  aboutProperty: {},
  propertyAddress: {},
  propertyDetails: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ONBOARDING_CURRENT_ADDRESS:
      return { ...state, currentAddress: action.payload };
    case ONBOARDING_EMPLOYMENT_DETAILS:
      return { ...state, employmentDetails: action.payload };
    case ONBOARDING_ADD_PAYMENT_INFORMATION:
      var creditCards = state.paymentInformation.creditCards;
      var bankAccounts = state.paymentInformation.bankAccounts;
      if (action.payload.creditCard) {
        creditCards = [...creditCards, action.payload.creditCard];
      }
      if (action.payload.bankAccount) {
        bankAccounts = [...bankAccounts, action.payload.bankAccount];
      }
      var paymentInformation = { bankAccounts, creditCards };
      return { ...state, paymentInformation };
    case ONBOARDING_REMOVE_PAYMENT_INFORMATION:
      var creditCards = state.paymentInformation.creditCards;
      var bankAccounts = state.paymentInformation.bankAccounts;
      creditCards = creditCards.filter(
        (card) => !eq(card.id, action.payload.id)
      );
      bankAccounts = bankAccounts.filter(
        (bank) => !eq(bank.id, action.payload.id)
      );
      paymentInformation = { bankAccounts, creditCards };
      return { ...state, paymentInformation };
    case ONBOARDING_LOAD_PAYMENT_INFORMATION:
      var { bankAccounts, creditCards } = action.payload;
      paymentInformation = { bankAccounts, creditCards };
      return { ...state, paymentInformation };
    case ONBOARDING_PERSONAL_INFORMATION:
      return { ...state, personalInformation: action.payload };
    case ONBOARDING_ABOUT_PROPERTY: {
      return { ...state, aboutProperty: action.payload };
    }
    case ONBOARDING_PROPERTY_ADDRESS: {
      return { ...state, propertyAddress: action.payload };
    }
    case ONBOARDING_PROPERTY_DETAILS: {
      return { ...state, propertyDetails: action.payload };
    }
    case GET_ONBOARDING_DATA: {
      return { ...action.payload };
    }
    default:
      return state;
  }
};
