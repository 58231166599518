import React, { Component } from "react";
import { connect } from "react-redux";
import eq from "lodash/eq";

import { getBookedProperty } from "../../../store/actions/booking";
import { getMemberOnboardingInformation } from "../../../store/actions/onboarding";
import PendingStatus from "./PendingStatus";
import RejectedStatus from "./RejectedStatus";
import SuspendedStatus from "./SuspendedStatus";

class AccountStatus extends Component {
  componentWillMount() {
    if (this.isPendingStatus()) {
      return this.props.getBookedProperty();
    }
    if (this.isRejectedStatus()) {
      return this.props.getMemberOnboardingInformation();
    }
  }

  isPendingStatus = () => {
    const { status } = this.props.user;
    return eq(status, "pending");
  };

  isRejectedStatus = () => {
    const { status } = this.props.user;
    return eq(status, "rejected");
  };

  isSuspendedStatus = () => {
    const { status } = this.props.user;
    return eq(status, "suspended");
  };

  render() {
    if (this.isPendingStatus()) {
      return <PendingStatus booking={this.props.booking} />;
    } else if (this.isRejectedStatus()) {
      return (
        <RejectedStatus
          user={this.props.user}
          onboarding={this.props.onboarding}
        />
      );
    } else if (this.isSuspendedStatus()) {
      return <SuspendedStatus user={this.props.user} />;
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    booking: state.booking,
    onboarding: state.onboarding,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {
  getBookedProperty,
  getMemberOnboardingInformation,
})(AccountStatus);
