import React from "react";

import LoloftDropdownWithButton from "../../../components/common/LoloftDropdownWithButton";

function HomePageHeader(props) {
  return (
    <div className="loloft-hero">
      <div className="ui center aligned text container">
        <h1>Find your next home</h1>
        <div className="header-dropdown-with-button">
          <LoloftDropdownWithButton
            defaultValue="All"
            dropdownLabel="Choose City"
            buttonLabel="Find Places"
            options={props.availableLocations}
            primary
            fluid
            onChange={props.onDropdownSelect}
            onClick={props.onButtonClick}
            value={props.selectedLocation}
          />
        </div>
      </div>
    </div>
  );
}

export default HomePageHeader;
