import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";

//composite component, that is used for grouping of dropdown and button components
class LoloftDropdown extends Component {
  render() {
    const {
      fluid,
      value,
      dropdownLabel,
      onChange,
      defaultValue,
      options,
      name,
      error
    } = this.props;

    return (
      <div className="dropdown">
        <div className="dropdown-with-button--dropdown">
          <span className="sm-label">{dropdownLabel}</span>
          <Dropdown
            name={name}
            onChange={onChange}
            placeholder={defaultValue}
            fluid={fluid}
            search
            selection
            options={options}
            value={value}
            error={error}
          />
        </div>
      </div>
    );
  }
}

export default LoloftDropdown;
