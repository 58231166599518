import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import { cities } from "../../../statics/pages/home-page";

//app renders section title, info and 4 cities on the home page
class Cities extends Component {
  //renders container that holds city name and button
  //method is executed 4 times, once for each city
  renderTitleAndButton = (cityData, buttonLabel) => {
    return (
      <div className="meta">
        <h3>{cityData.title}</h3>
        <Button primary>{buttonLabel}</Button>
      </div>
    );
  };

  renderCities(cities) {
    return (
      <div className="abstract grid">
        {
          //semantic ui grid is used for displaying an uneven images,
          //in two separate columns. heights of images are in css file
        }
        {cities.cities.map(city => {
          return (
            <figure key={city.key}>
              <img src={city.image} className="ui image" alt="" />
              {this.renderTitleAndButton(city, cities.buttonLabel)}
            </figure>
          );
        })}
      </div>
    );
  }
  render() {
    return (
      <div className="cities section">
        <div className="ui center aligned text container">
          <h2>{cities.title}</h2>
          <div className="section-info">{cities.info}</div>
        </div>
        <div className="ui container">{this.renderCities(cities)}</div>
      </div>
    );
  }
}

export default Cities;
