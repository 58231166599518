export const smsVerificationData = {
  title: "Login",
  backButtonText: "Go Back",
  lineOne: {
    text: "We sent a code to"
  },
  lineTwo: {
    text: "Please enter the 4 digits code above."
  },
  lineThree: {
    text: " Didn't get the code?",
    linkText: "Send it again"
  },
  buttonText: "Verify and Continue",
  error: { text: "Please fill SMS code field", title: "Error" }
};
