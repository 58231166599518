import React from "react";
import CommitmentCard from "./CommitmentCard";
import { commitmentsData } from "../../../statics/pages/home-page";
import LoloftCarousell from "../../../components/common/loloft-carousell/LoloftCarousell";

//COMPUTER - renders a array of commitment cards, which are defined in static js array "cards"
//and for each element in array, a single card
const renderCommitmentCards = ({ cards }) => {
  if (cards) {
    return (
      <div className="cards">
        <div className="ui three column stackable grid container">
          {cards.map(card => {
            return (
              <div className="column" key={card.key}>
                <CommitmentCard card={card} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  return null;
};

//MOBILE - renders a carousell with commitment cards, which are defined in static js array "cards"
//and for each element in array, a single card
const renderCommitmentCardsForMobile = ({ cards }) => {
  if (cards) {
    return (
      <div className="commitment-panel--cards">
        <div className="ui center aligned container">
          <LoloftCarousell>
            {cards.map(card => (
              <CommitmentCard isMobile key={card.key} card={card} />
            ))}
          </LoloftCarousell>
        </div>
      </div>
    );
  }
  return null;
};

//renders a panel with title, info and commitment cards
const CommitmentsPanel = () => {
  return (
    <div className="commitment section">
      <div className="ui center aligned text container">
        <h2>{commitmentsData.title}</h2>
        <div className="section-info">{commitmentsData.info}</div>
      </div>
      <div className="ui container">
        <div className="ui stackable grid">
          <div className="sixteen column computer tablet only row">
            {renderCommitmentCards(commitmentsData)}
          </div>
          <div className="sixteen column mobile only row">
            {renderCommitmentCardsForMobile(commitmentsData)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommitmentsPanel;
