import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce";
import { Form } from "semantic-ui-react";

import LoloftCityInput from "../LoloftCityInput";
import LoloftYearPicker from "../LoloftYearPicker";
import LoloftErrorMessage from "../LoloftErrorMessage";
import LoloftSuccessMessage from "../LoloftSuccessMessage";
import { submitCurrentAddress } from "../../../store/actions/onboarding";

import {
  validateStringLength,
  validateRequired,
  validateZipCode,
} from "../../../utils/validation";
import { getStateName } from "../../../utils/getStateName";

import { currentAddressData } from "../../../statics/pages/member-onboarding-page";

class CurrentAddressStep extends Component {
  state = {
    city: "",
    state: "",
    address: "",
    zipCode: "",
    residentSince: "",
    errors: {},
  };

  //if component has data that is passed from parent,
  //set data to input fields
  componentWillMount() {
    const { data } = this.props;
    if (data) {
      this.setState(data);
    }
  }

  //controlls input fields and removes field error message on field input change
  onChange = (e, data) => {
    const { name, value } = data;
    const errors = { ...this.state.errors, [name]: undefined, auth: undefined };
    this.setState({ [name]: value, errors });
  };

  onCitySelect = (city, stateAbbr) => {
    const state = getStateName(stateAbbr);
    this.setState({ city, state });
  };

  //returns js object with property named as field where error has occurred
  validate = () => {
    let errors = {};
    const cityError = validateRequired(this.state.city, "City");
    const stateError = validateRequired(this.state.state, "State");
    const addressError = validateStringLength(
      this.state.address,
      "Address",
      6,
      500
    );
    const zipCodeError = validateZipCode(this.state.zipCode, "Zip Code");
    const residentSinceError = validateRequired(
      this.state.residentSince,
      "Resident Since"
    );

    if (cityError) {
      errors.city = cityError;
    }
    if (stateError) {
      errors.state = stateError;
    }
    if (addressError) {
      errors.address = addressError;
    }
    if (zipCodeError) {
      errors.zipCode = zipCodeError;
    }
    if (residentSinceError) {
      errors.residentSince = residentSinceError;
    }
    return errors;
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    if (!isEmpty(errors)) {
      return this.setState({ errors });
    }
    try {
      this.props.submitCurrentAddress(this.state);
      if (this.props.setCompletedStep) {
        this.props.setCompletedStep(this.props.step);
      }
      if (this.props.onUserAccountUpdate) {
        await this.props.onUserAccountUpdate("currentAddress", this.state);
        this.setState({ successMessage: "Update Successful" });
        debounce(2000, () => this.setState({ successMessage: undefined }));
      }
    } catch (e) {
      const errors = { ...this.state.errors, [e.name]: { text: e.message } };
      this.setState({ errors });
    }
  };

  //renders information about data that user entered in this step
  renderCompletedInformation = () => {
    const ca = this.props.currentAddress;
    if (ca) {
      return (
        <div>
          <h2>{currentAddressData.title}</h2>
          <p>
            {ca.address} {ca.city} {ca.zipCode}
          </p>
        </div>
      );
    }
    return null;
  };

  //renders information about data that user entered in this step
  renderIncompleteInformation = () => {
    return <h2>{currentAddressData.title}</h2>;
  };

  //renders specific for or data for this step, and will bi directly used
  //for mobile view
  renderStep = () => {
    const {
      addressInput,
      buttonLabel,
      title,
      cityInput,
      residentSinceInput,
      zipCodeInput,
      stateInput,
      accountViewButtonLabel,
    } = currentAddressData;
    return (
      <Fragment>
        <h2>{title}</h2>
        <Form onSubmit={this.onSubmit} error success>
          <LoloftSuccessMessage parentState={this.state} />
          <LoloftErrorMessage parentState={this.state} name="error" />
          <LoloftCityInput
            label={stateInput.label}
            value={this.state.city}
            onSelect={this.onCitySelect}
            placeholder={cityInput.label}
          />
          <LoloftErrorMessage parentState={this.state} name="city" />
          <Form.Input
            label={stateInput.label}
            name="state"
            placeholder={stateInput.placeholder}
            value={this.state.state}
            error={this.state.errors.state ? true : false}
            disabled={true}
          />
          <Form.Input
            onChange={this.onChange}
            label={addressInput.label}
            name="address"
            placeholder={addressInput.placeholder}
            value={this.state.address}
            error={this.state.errors.address ? true : false}
          />
          <LoloftErrorMessage parentState={this.state} name="address" />
          <Form.Input
            onChange={this.onChange}
            label={zipCodeInput.label}
            name="zipCode"
            placeholder={zipCodeInput.placeholder}
            value={this.state.zipCode}
            error={this.state.errors.zipCode ? true : false}
          />
          <LoloftErrorMessage parentState={this.state} name="zipCode" />
          <LoloftYearPicker
            label={residentSinceInput.label}
            name="residentSince"
            placeholder={residentSinceInput.placeholder}
            onChange={this.onChange}
            value={this.state.residentSince}
            error={this.state.errors.residentSince ? true : false}
          />
          <LoloftErrorMessage parentState={this.state} name="residentSince" />
          <Form.Button type="submit" primary fluid>
            {this.props.accountView ? accountViewButtonLabel : buttonLabel}
          </Form.Button>
        </Form>
      </Fragment>
    );
  };

  //props
  //mobile - is this a view for mobile device
  //open - is this step currently in focus/ opened
  //competed - is this step already completed
  //setCompletedStep - will set this step as compete in parent,
  //which will make parent switch to the next step
  render() {
    const { open, completed, mobile } = this.props;
    if (mobile) {
      if (open) return this.renderStep();
      else return null;
    } else {
      if (open) return this.renderStep();
      else {
        if (completed) return this.renderCompletedInformation();
        else return this.renderIncompleteInformation();
      }
    }
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth, currentAddress: state.onboarding.currentAddress };
};

export default connect(mapStateToProps, { submitCurrentAddress })(
  CurrentAddressStep
);
