export const SET_USER = "SET_USER";
export const REMOVE_USER = "REMOVE_USER";
export const PHONE_VERIFIED = "PHONE_VERIFIED";
export const SET_VERIFIED_USER = "SET_VERIFIED_USER";

export const OPEN_SIDEBAR = "OPEN_SIDEBAR";
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";

export const GET_AVAILABLE_LOCATIONS = "GET_AVAILABLE_LOCATIONS";
export const GET_PROPERTIES_COUNT = "GET_PROPERTIES_COUNT";
export const GET_FILTERED_PROPERTIES_COUNT = "GET_FILTERED_PROPERTIES_COUNT";

export const GET_PROPERTIES = "GET_PROPERTIES";
export const GET_PROPERTY = "GET_PROPERTY";
export const PROPERTY_NOT_FOUND = "PROPERTY_NOT_FOUND";

export const SET_BOOKING_DATA = "SET_BOOKING_DATA";
export const GET_BOOKED_DATA = "GET_BOOKIED_DATA";

export const SET_LOCATION = "SET_LOCATION";
export const SET_FILTERS = "SET_FILTERS";
export const SET_DATES = "SET_DATES";
export const NEXT_PAGE = "NEXT_PAGE";

export const ONBOARDING_PERSONAL_INFORMATION =
  "ONBOARDING_PERSONAL_INFORMATION";
export const ONBOARDING_REMOVE_PAYMENT_INFORMATION =
  "ONBOARDING_REMOVE_PAYMENT_INFORMATION";
export const ONBOARDING_ADD_PAYMENT_INFORMATION =
  "ONBOARDING_ADD_PAYMENT_INFORMATION";
export const ONBOARDING_LOAD_PAYMENT_INFORMATION =
  "ONBOARDING_LOAD_PAYMENT_INFORMATION";
export const ONBOARDING_ABOUT_PROPERTY = "ONBOARDING_ABOUT_PROPERTY";
export const ONBOARDING_PROPERTY_ADDRESS = "ONBOARDING_PROPERTY_ADDRESS";
export const ONBOARDING_PROPERTY_DETAILS = "ONBOARDING_PROPERTY_DETAILS";

export const ONBOARDING_EMPLOYMENT_DETAILS = "ONBOARDING_EMPLOYMENT_DETAILS";
export const ONBOARDING_CURRENT_ADDRESS = "ONBOARDING_CURRENT_ADDRESS";
export const ONBOARDING_PHOTO_ID = "ONBOARDING_PHOTO_ID";
export const GET_ONBOARDING_DATA = "GET_ONBOARDING_DATA";
