import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import {
  cities,
  company,
  copyright,
  forOnwers,
  policyAndTerms,
  stayUpdated
} from "../../statics/footer";
import "../../assets/css/loloft-v2/loloft-footer.scss";
import LoloftButton from "./LoloftButton";
import LoloftInput from "./LoloftInput";

class LoloftFooter extends Component {
  //state of input component used for signing up for newsletter
  state = {
    signUp: ""
  };
  //method for controlling state of input components
  onValueChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  //return an element with one of three columns of links
  //links are read from static file
  renderFooterColumn = data => {
    return (
      <Fragment>
        <h4>{data.title}</h4>
        {data.links.map(link => {
          return (
            <div key={link.title} className="item">
              <Link to={link.to}>
                {link.title}
              </Link>
            </div>
          );
        })}
      </Fragment>
    );
  };

  //return a div element with policy and terms, that were read from static file
  renderPolicyAndTerms = () => {
    const { links } = policyAndTerms;
    return (
      <div className="terms">
        {links[0].title} <span>•</span>
        {links[1].title} <span>•</span>
        {links[2].title} <span>•</span>
        {links[3].title}
        <span>•</span>
        <strong className="highlight">{links[4].title}</strong>
      </div>
    );
  };
  //return a div element with copyright text, that was read from static file
  renderFooterCopyright = () => {
    return <div className="copyright">{copyright.title}</div>;
  };

  //return element with input and button for signing up to newsletter
  renderStayUpdated = () => {
    return (
      <Fragment>
        <h4>{stayUpdated.title}</h4>
        <p>
          {stayUpdated.info}
        </p>
        <div className="ui action input">
          <LoloftInput
              secondary
              placeholder={stayUpdated.input.placeholder}
              onChange={this.onValueChange}
              value={this.state.signUp}
              name="signUp"
            />
          <LoloftButton>{stayUpdated.input.label}</LoloftButton>
        </div>
      </Fragment>
    );
  };

  //returns footer element for computer and tablet devices
  renderFooter = () => {
    return (
      <div className="contents">
        <div className="column">
          {this.renderFooterColumn(cities)}
        </div>
        <div className="column">
          {this.renderFooterColumn(company)}
        </div>
        <div className="column">
          {this.renderFooterColumn(forOnwers)}
        </div>
        <div className="wide column">{this.renderStayUpdated()}</div>
        <div className="full column">
          <img src="/assets/img/loloft-logo.svg" className="ui centered image" />
          {this.renderPolicyAndTerms()}
          {this.renderFooterCopyright()}
        </div>
      </div>
    );
  };

  //returns footer element for mobile devices
  renderFooterForMobile = () => {
    return (
      <div className="contents">
        {this.renderStayUpdated()}
        {this.renderFooterCopyright()}
        {this.renderPolicyAndTerms()}
      </div>
    );
  };

  render() {
    return (
      <footer className="loloft-footer">
        <div className="ui default container">
          {this.renderFooter()}
        </div>
        <div className="mobile">
          {this.renderFooterForMobile()}
        </div>
      </footer>
    );
  }
}

export default LoloftFooter;
