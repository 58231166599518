import React from "react";
import { Popup } from "semantic-ui-react";

import { amenities } from "../../statics/amenities";
import LoloftInfoButton from "./LoloftInfoButton";

//component that will render as loloft amenity card
//with image and label
//it deppends on value that is passed to it
//and  static information about all types of amenities
function LoloftAmenityCard(props) {
  const { amenity } = props;
  const amenityData = amenities.find(a => {
    if (amenity.key) {
      return a.value.localeCompare(amenity.key) === 0;
    }
    return a.value.localeCompare(amenity) === 0;
  });
  if (amenityData) {
    return (
      <div className="amenity box">
        {renderInfoPopup(amenity)}
        <img src={amenityData.icon} alt="" />
        <span>{amenityData.label}</span>
      </div>
    );
  }
  return null;
}

const renderInfoPopup = amenity => {
  if (amenity.additionalInfo) {
    const { title, info, asterisk } = amenity.additionalInfo;
    return (
      <Popup trigger={<LoloftInfoButton />} basic position="bottom center">
        <h4>{title}</h4>
        <p>{info}</p>
        <small>{asterisk}</small>
      </Popup>
    );
  }
  return null;
};

export default LoloftAmenityCard;
