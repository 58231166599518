import React from "react";
import { connect } from "react-redux";
import gravatar from "gravatar";

import { logout } from "../../store/actions/auth";

function ProfilePanel(props) {
  const { user } = props;

  return (
    <div>
      <img src={gravatar.url(user.email)} alt="" />
      <div>
        {user.firstName} {user.lastName}
      </div>
      <div>
        <a onClick={props.logout} href="#">
          Logout
        </a>
      </div>
    </div>
  );
}

export default connect(null, { logout })(ProfilePanel);
