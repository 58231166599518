import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Segment, Form } from "semantic-ui-react";
import qs from "qs";
import isEmpty from "lodash/isEmpty";

import LoloftErrorMessage from "../../LoloftErrorMessage";

import { setNewPassword } from "../../../../store/actions/auth";
import { validatePassword } from "../../../../utils/validation";
import { resetPassword } from "../../../../statics/pages/login-page";

class ResetPassword extends Component {
  state = { email: "", password: "", errors: {} };

  componentDidMount() {
    const { oobCode } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    const email = localStorage.getItem("recoverEmail");
    this.setState({
      oobCode,
      email,
    });
  }

  //submit function will try to validate form data,
  //and only on successful validation will try to set new password
  //on error, component error state will be updated
  onSubmit = async (e) => {
    e.preventDefault();
    const errors = this.validate();
    if (!isEmpty(errors)) {
      return this.setState({ errors });
    }
    try {
      await this.props.setNewPassword(this.state.oobCode, this.state.password);
    } catch (e) {
      const errors = { ...this.state.errors, auth: { text: e.message } };
      this.setState({ errors });
    }
  };

  //returns js object with property named as field where error has occurred
  validate = () => {
    let errors = {};
    const passwordError = validatePassword(this.state.password);
    if (passwordError) {
      errors.password = passwordError;
    }
    return errors;
  };

  //controlls input fields and removes field error message on field input change
  onChange = (e) => {
    const { name, value } = e.target;
    const errors = { ...this.state.errors, [name]: undefined, auth: undefined };
    this.setState({ [name]: value, errors });
  };

  renderTitle = () => {
    if (this.props.renderTitle) {
      return <h1>{resetPassword.title}</h1>;
    }
  };

  render() {
    const { emailInput, passwordInput, buttonText, backLink } = resetPassword;
    return (
      <Segment>
        {this.renderTitle()}
        <Form onSubmit={this.onSubmit} error>
          <Form.Input
            label={emailInput.label}
            name="email"
            placeholder={emailInput.placeholder}
            value={this.state.email}
            disabled
          />
          <Form.Input
            type="password"
            onChange={this.onChange}
            label={passwordInput.label}
            name="password"
            placeholder={passwordInput.placeholder}
            value={this.state.password}
            error={
              this.state.errors.password || this.state.errors.auth
                ? true
                : false
            }
          />
          <LoloftErrorMessage parentState={this.state} name="password" />
          <LoloftErrorMessage parentState={this.state} name="auth" />
          <Form.Button type="submit" primary>
            {buttonText}
          </Form.Button>
          <div>
            <Link to={`${this.props.urlPrefix}/login`}>{backLink}</Link>
          </div>
        </Form>
      </Segment>
    );
  }
}

export default connect(null, { setNewPassword })(ResetPassword);
