import React, { Component } from "react";
import eq from "lodash/eq";
import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";

import { injectStripe } from "react-stripe-elements";
import { Checkbox, Form } from "semantic-ui-react";

import LoloftErrorMessage from "../../common/LoloftErrorMessage";
import { registerBankAccount } from "../../../store/actions/payment";
import { addPaymentInformation } from "../../../store/actions/onboarding";
import { bankAccount } from "../../../statics/paymentInfo";
import { validateNumberLength } from "../../../utils/validation";

export class BankAccountInput extends Component {
  state = {
    termsAndService: false,
    accountNumber: "",
    routingNumber: "",
    errors: {},
  };

  //controlls input fields and removes field error message on field input change
  onChange = (e, data) => {
    const { name, value, checked } = data;
    const errors = { ...this.state.errors, [name]: undefined, auth: undefined };
    //termsAndService are checkbox fields, and they return thair value as checked property
    if (eq(name, "termsAndService")) {
      this.setState({ [name]: checked });
    } else {
      this.setState({ [name]: value, errors });
    }
  };

  //returns js object with property named as field where error has occurred
  validate = () => {
    let errors = {};
    const routingNumberError = validateNumberLength(
      this.state.routingNumber,
      "Routing Number",
      9,
      9
    );
    const accountNumberError = validateNumberLength(
      this.state.accountNumber,
      "Account Number",
      10,
      12
    );
    if (routingNumberError) {
      errors.routingNumber = routingNumberError;
    }
    if (accountNumberError) {
      errors.accountNumber = accountNumberError;
    }
    return errors;
  };

  registerCard = async (e) => {
    e.preventDefault();
    var errors = this.validate();
    if (!isEmpty(errors)) {
      return this.setState({ errors });
    }
    try {
      const bank = await registerBankAccount(
        this.props.stripe,
        this.state,
        this.props.owner,
        this.props.legalName
      );
      this.props.addPaymentInformation(bank);
      this.props.closeInput();
    } catch (err) {
      const msg = { text: err.message };
      var errors = this.state.errors;
      errors.bankValidation = msg;
      return this.setState({ errors });
    }
  };

  render() {
    const {
      holder,
      accountNumber,
      addButtonLabel,
      checkboxLabel,
      routingNumber,
    } = bankAccount;

    return (
      <div>
        <div className="flex inline dummy">
          <img src="/assets/icons/icon-user.svg" />
          <div className="contents">
            <label>{holder}</label>
            <p>{this.props.legalName}</p>
          </div>
        </div>
        <Form onSubmit={this.registerCard} error>
          <Form.Input
            type="number"
            placeholder={routingNumber.placeholder}
            label={routingNumber.label}
            name="routingNumber"
            onChange={this.onChange}
            error={this.state.errors.routingNumber ? true : false}
            value={this.state.routingNumber}
          />
          <LoloftErrorMessage parentState={this.state} name="routingNumber" />

          <Form.Input
            type="number"
            onChange={this.onChange}
            label={accountNumber.label}
            name="accountNumber"
            placeholder={accountNumber.placeholder}
            value={this.state.accountNumber}
            error={this.state.errors.accountNumber ? true : false}
          />
          <LoloftErrorMessage parentState={this.state} name="accountNumber" />
          <LoloftErrorMessage parentState={this.state} name="bankValidation" />
          <Checkbox
            className="full"
            label={checkboxLabel}
            name="termsAndService"
            checked={this.state.termsAndService}
            onChange={this.onChange}
          />
          <Form.Button
            disabled={!this.state.termsAndService}
            primary
            fluid
            type="submit"
          >
            {addButtonLabel}
          </Form.Button>
        </Form>
      </div>
    );
  }
}

export default connect(null, { addPaymentInformation })(
  injectStripe(BankAccountInput)
);
