import firebase from "../../config/firebase";

const INVALID_ARGUMENT = "invalid-argument";
const UNAUTHENTICATED = "unauthenticated";
const CANCELLED = "cancelled";

export const registerCreditCard = async (stripe, user) => {
  try {
    let { token } = await stripe.createToken();
    if (token) {
      const { card } = token;
      const registerPaymentMethod = firebase
        .functions()
        .httpsCallable("registerPaymentMethod");
      await registerPaymentMethod({
        token,
        email: user.email,
        uid: user.uid,
        alreadyACustomer: !!user.stripeId,
        cid: user.stripeId
      });
      return card;
    } else {
      var e = new Error("Could not create stripe token");
      e.code = CANCELLED;
      throw e;
    }
  } catch (err) {
    console.error(err);
    if (err.code) {
      switch (err.code) {
        case INVALID_ARGUMENT:
          throw new Error("Register payment request was not properly formated");
        case UNAUTHENTICATED:
          throw new Error(
            "You have to be authenticated to register payment method"
          );
        default:
          throw new Error("Payment method registration failed");
      }
    }
  }
};

export const registerBankAccount = async (stripe, bank, user, legalName) => {
  try {
    const bankParams = {
      country: "US",
      currency: "USD",
      account_number: bank.accountNumber,
      account_holder_name: legalName,
      account_holder_type: "individual",
      routing_number: bank.routingNumber
    };
    let { token, error } = await stripe.createToken("bank_account", bankParams);
    if (token) {
      console.log(token);
      const { bank_account } = token;
      const registerPaymentMethod = firebase
        .functions()
        .httpsCallable("registerPaymentMethod");
      await registerPaymentMethod({
        token,
        email: user.email,
        uid: user.uid,
        alreadyACustomer: !!user.stripeId,
        cid: user.stripeId
      });
      return bank_account;
    } else {
      var e = new Error(error.message);
      e.code = error.code;
      throw e;
    }
  } catch (err) {
    console.error(err);
    if (err.code) {
      switch (err.code) {
        case INVALID_ARGUMENT:
          throw new Error("Register payment request was not properly formated");
        case UNAUTHENTICATED:
          throw new Error(
            "You have to be authenticated to register payment method"
          );
        case CANCELLED:
          throw new Error("Payment method registration failed");
        default:
          throw err;
      }
    }
  }
};

export const removePaymentMethod = async paymentMethodId => {
  const removePaymentMethod = firebase
    .functions()
    .httpsCallable("removePaymentMethod");
  try {
    await removePaymentMethod({
      sourceId: paymentMethodId
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};
