import React, { Component } from "react";
import { connect } from "react-redux";

import { Grid } from "semantic-ui-react";
import LoginSwitch from "../../components/common/loloft-auth/login/LoginSwitch";
import history from "../../history";
import { isUserLoggedIn } from "../../utils/userUtility";
import "../../assets/css/loloft-v2/auth.scss";
import { injectAuthPageStyle } from "../../utils/bodyStyleInjector";

class LoginPage extends Component {
  onAccountLoaded = () => {
    history.push("/account");
  };

  renderComputer = () => {
    return (
      <Grid stackable reversed="mobile">
        <Grid.Column verticalAlign="middle" width={8}>
          <LoginSwitch onSubmit={this.onAccountLoaded} />
        </Grid.Column>
        <Grid.Column width={8} className="login-bg"></Grid.Column>
      </Grid>
    );
  };

  render() {
    injectAuthPageStyle();
    return <div className="login-switch">{this.renderComputer()}</div>;
  }

  shouldComponentUpdate(nextProps) {
    const currentUser = this.props.auth.user;
    const nextUser = nextProps.auth.user;
    if (!isUserLoggedIn(currentUser)) {
      if (isUserLoggedIn(nextUser)) {
        this.onAccountLoaded();
      }
    }

    return true;
  }
}

const mapStateToProps = state => {
  return { auth: state.auth };
};

export default connect(mapStateToProps)(LoginPage);
