import React, { Component } from "react";
import { connect } from "react-redux";
import { Router, Link } from "react-router-dom";
import { StripeProvider } from "react-stripe-elements";
import { Sidebar, Menu } from "semantic-ui-react";
import history from "./history";

import App from "./App";
import { closeSidebar } from "./store/actions/sidebar";

import {
  mobileLinks,
  mobileLinksBottom,
  mobileLinkAccount
} from "./statics/navbar";
class AppWrapper extends Component {
  renderMenuItem = item => {
    const active = history.location.pathname.localeCompare(item.to) === 0;
    const img = item.icon ? <img src={item.icon} alt="" /> : null;
    return (
      <Menu.Item key={item.title} active={active}>
        <Link to={item.to}>
          {item.title} <span className="art">{img}</span>
        </Link>
      </Menu.Item>
    );
  };

  render() {
    return (
      <StripeProvider apiKey="pk_test_EhDBT2wHwEeXtaFGaOEYegBv00RIGOFSkd">
        <Router history={history}>
          <Sidebar
            direction="right"
            as={Menu}
            animation="overlay"
            onHide={this.props.closeSidebar}
            vertical
            visible={this.props.opened}
          >
            <div className="icon close" onClick={this.props.closeSidebar}></div>
            <div className="top">
              <div className="main">
                {mobileLinks.map(link => this.renderMenuItem(link))}
              </div>
              <div className="separated account">
                {this.renderMenuItem(mobileLinkAccount)}
              </div>
            </div>
            <div className="smaller bottom items">
              {mobileLinksBottom.map(link => this.renderMenuItem(link))}
            </div>
          </Sidebar>
          <Sidebar.Pusher>
            <App />
          </Sidebar.Pusher>
        </Router>
      </StripeProvider>
    );
  }
}

const mapStateToProps = state => {
  return { opened: state.sidebar.opened };
};

export default connect(mapStateToProps, { closeSidebar })(AppWrapper);
