import React, { Component } from "react";
import SwipeableViews from "react-swipeable-views";

import "../../../assets/css/loloft-v2/loloft-carousell.scss";
import LoloftImagePagination from "./LoloftImagePagination";

class LoloftCarousell extends Component {
  state = {
    index: 0
  };

  handleChangeIndex = index => {
    const { length } = this.props.children;
    var newIndex = index;
    if (newIndex >= length) newIndex = 0;
    if (newIndex < 0) newIndex = length - 1;
    this.setState({
      index: newIndex
    });
  };

  renderCarousellWithArrows = () => {
    const { index } = this.state;
    const { children } = this.props;
    return (
      <div className="carousell">
        <SwipeableViews index={index} onChangeIndex={this.handleChangeIndex}>
          {children}
        </SwipeableViews>
        <img
          className="arrow prev" 
          src="/assets/icons/icon-arrow-prev-white.svg"
          onClick={() => {
            this.handleChangeIndex(index - 1);
          }}
        />
        <img
          className="arrow next" 
          src="/assets/icons/icon-arrow-next-white.svg"
          onClick={() => {
            this.handleChangeIndex(index + 1);
          }}
        />
        <div>{this.renderSmallImages(children, index)}</div>
      </div>
    );
  };

  //if images property is passed to carousell, small clickable images
  //will be rendered beneath carousell
  renderSmallImages = (children, index) => {
    if (this.props.images) {
      return (
        <LoloftImagePagination
          dots={children.length}
          index={index}
          onChangeIndex={this.handleChangeIndex}
          images={this.props.images}
        />
      );
    }
  };

  //component will be rendered
  //with arrow for selecting next and previous image
  //if images parameter is passed, then small clickable images
  //will be rendered beneath it
  render() {
    const { children } = this.props;
    if (children) {
      return this.renderCarousellWithArrows();
    }
    return null;
  }
}

export default LoloftCarousell;
