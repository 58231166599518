import React from "react";
import { Modal } from "semantic-ui-react";

const ModalDialog = props => {
  return (
    <Modal {...props}>
      <Modal.Header>{props.title}</Modal.Header>
      <Modal.Content>{props.children}</Modal.Content>
    </Modal>
  );
};

export default ModalDialog;
