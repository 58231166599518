export const bookingPropertyPageData = {
  title: "Reserve Your Place",
  moveIn: "Move In",
  moveOut: "Move Out",
  monthly: "Monthly",
  deposit: {
    title: "Deposit",
    info: "Returned to you at the end of your stay"
  },
  fee: {
    title: "Registration Fee",
    info: "One time payment for credit and background checks."
  },
  dueToday: "Due Today"
};

export const choosePaymentMethodData = {
  title: "Choose Payment Method",
  text1:
    "This is only for the background and credit check. We will only bill your card ",
  text2:
    "When your application has been approved you will be able to enter another method to make the monthly payments.",
  buttonLabel: "Request to Book"
};

export const placeReservedData = {
  title: "Your place is reserved!",
  textVerified:
    "Please wait and you will be notified by email when you request is proccessed.",
  buttonVerifiedLabel: "Home",
  textUnverified:
    "Before you can move in we need to get a few more details from you. This helps us to keep a safe community on loloft.",
  buttonUnverifiedLabel: "Get Started"
};
