import React from "react";
import { Route, Switch } from "react-router-dom";
import LoloftSmsCodeVerification from "../../LoloftSmsCodeVerification";
import RegisterForm from "./RegisterForm";

function RegisterSwitch(props) {
  const urlPrefix = props.urlPrefix
    ? `/${props.urlPrefix}/register`
    : "/register";
  //if prefix url is set, then we do not want to display default title for compoentns
  //wraper page will provide it's own title
  const renderTitle = props.urlPrefix ? false : true;
  return (
    <Switch>
      <Route path={`${urlPrefix}/sms-verification`}>
        <LoloftSmsCodeVerification
          backLink={`${urlPrefix}`}
          renderTitle={renderTitle}
          onSubmit={props.onSubmit}
        />
      </Route>
      <Route exact path={`${urlPrefix}`}>
        <RegisterForm urlPrefix={urlPrefix} renderTitle={renderTitle} />
      </Route>
    </Switch>
  );
}

export default RegisterSwitch;
