export const propertyPage = {
  share: {
    facebook: {
      buttonLabel: "Share On Facebook",
      url: "http://loloft.com/property/",
      title: "Loloft"
    },
    email: {
      buttonLabel: "Share Via Email",
      url: "http://loloft.com/property/",
      title: "Loloft"
    }
  },
  description: "Description",
  sleepingArrangements: {
    title: "Sleeping Arrangements",
    arrangements: [
      {
        key: "king",
        value: "King Bed",
        size: ", 70.8in/180cm",
        image: "/assets/icons/icon-king-bed.svg"
      },
      {
        key: "queen",
        value: "Queen Bed",
        size: ", 60in/152cm",
        image: "/assets/icons/icon-queen-bed.svg"
      },
      {
        key: "double",
        value: "Double Bed",
        size: ", 71in/180cm",
        image: "/assets/icons/icon-king-bed.svg"
      },
      {
        key: "single",
        value: "Single Bed",
        size: ", 40in/100cm",
        image: "/assets/icons/icon-single-bed.svg"
      },
      {
        key: "couch",
        value: "Couch",
        size: "",
        image: "/assets/icons/icon-couch.svg"
      }
    ]
  },
  amenities: "Amenities",
  location: "Location",
  other: "Other Lolofts In ",
  showMoreButton: "Show More",

  checkButton: "Check Availability",
  stayButton: "Stay Here"
};
