export const links = [
  { title: "Add Your Property", to: "/property-onboarding" },
  { title: "Browse", to: "/properties" },
  { title: "How It Works", to: "/how-it-works" }
];

export const mobileLinks = [
  { title: "Loloft Home", to: "/", icon: "/assets/icons/icon-home.svg" },
  {
    title: "Add Your Property",
    to: "/property-onboarding",
    icon: "/assets/icons/icon-protection.svg"
  },
  {
    title: "Browse Properties",
    to: "/properties",
    icon: "/assets/icons/icon-map.svg"
  },
  {
    title: "How It Works",
    to: "/how-it-works",
    icon: "/assets/icons/icon-question.svg"
  },
  {
    title: "Get In Touch",
    to: "/get-in-touch",
    icon: "/assets/icons/icon-chat.svg"
  }
];

export const mobileLinksBottom = [
  { title: "Membership Agreement", to: "/membership-agreement" },
  {
    title: "Terms and Conditions",
    to: "/terms-and-conditions"
  },
  { title: "Privacy Policy", to: "/privacy-policy" }
];

export const mobileLinkAccount = {
  title: "Your Account",
  to: "/account",
  icon: "/assets/icons/icon-user.svg"
};
