//this is not a best practice, but we need a way to inject a style in body depending on which page we are on
const HOME_PAGE = "loloft-home";
const ACCOUNT_PAGE = "loloft-account";
const ONBOARDING_PAGE = "loloft-onboarding";
const AUTH_PAGE = "loloft-auth";
const BROWSE_PAGE = "loloft-browse";
const PROPERTY_PAGE = "loloft-property";

const styles = [
  HOME_PAGE,
  ACCOUNT_PAGE,
  ONBOARDING_PAGE,
  AUTH_PAGE,
  BROWSE_PAGE,
  PROPERTY_PAGE
];

const removeStyles = () => {
  styles.forEach(style => {
    document.body.classList.remove(style);
  });
};

export const injectHomePageStyle = () => {
  removeStyles();
  document.body.classList.add(HOME_PAGE);
};

export const injectOnboardingPageStyle = () => {
  removeStyles();
  document.body.classList.add(ONBOARDING_PAGE);
};

export const injectAuthPageStyle = () => {
  removeStyles();
  document.body.classList.add(AUTH_PAGE);
};

export const injectAccountPageStyle = () => {
  removeStyles();
  document.body.classList.add(ACCOUNT_PAGE);
};

export const injectBrowsePageStyle = () => {
  removeStyles();
  document.body.classList.add(BROWSE_PAGE);
};

export const injectPropertyPageStyle = () => {
  removeStyles();
  document.body.classList.add(PROPERTY_PAGE);
}
