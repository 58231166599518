import isEmail from "validator/lib/isEmail";
import isLength from "validator/lib/isLength";
import isMobilePhone from "validator/lib/isMobilePhone";

export const validateRequired = (value, name, required = true) => {
  if (required && !value) {
    return { text: `${name} is required` };
  }
};

export const validateEmail = (email, required = true) => {
  const requiredError = validateRequired(email, "Email", required);
  if (requiredError) return requiredError;
  if (email) {
    if (!isEmail(email)) {
      return { text: "Email must be properly formatted" };
    }
  }
};

export const validatePassword = (password, required = true) => {
  const requiredError = validateRequired(password, "Password", required);
  if (requiredError) return requiredError;
  if (password) {
    if (!isLength(password, { min: 6 })) {
      return { text: "Password must be at least 6 characters long" };
    }
  }
};

export const validateMobilePhone = (phone, required = true) => {
  const requiredError = validateRequired(phone, "Phone Number", required);
  if (requiredError) return requiredError;
  if (phone) {
    if (!isMobilePhone(phone)) {
      return { text: "Phone Number must be properly formatted" };
    }
  }
};

export const validateYear = (year, name, required = true) => {
  const requiredError = validateRequired(year, name, required);
  if (requiredError) return requiredError;
  if (year) {
    if (year < 1900) return { text: `${name} must be after 1900` };
    if (year > new Date().getFullYear())
      return { text: `${name} cannot be in the future` };
  }
};

export const validatePropertySize = (size, name, required = true) => {
  const requiredError = validateRequired(size, name, required);
  if (requiredError) return requiredError;
  if (size) {
    if (size < 0) return { text: `${name} must be a positive number` };
  }
};

export const validateStringLength = (
  value,
  name,
  minLength = 6,
  maxLength = 30,
  required = true
) => {
  const requiredError = validateRequired(value, name, required);
  if (requiredError) return requiredError;
  if (value) {
    if (!isLength(value, { min: minLength })) {
      return { text: `${name} must be at least ${minLength} characters long` };
    }
    if (!isLength(value, { max: maxLength })) {
      return { text: `${name} must be shorter then ${maxLength} characters` };
    }
  }
};

export const validateSSN = (ssn, required = true) => {
  const requiredError = validateRequired(
    ssn,
    "Social Security Number",
    required
  );
  if (requiredError) return requiredError;
  if (ssn) {
    var ssnPattern = /^[0-9]{3}\-?[0-9]{2}\-?[0-9]{4}$/;
    if (!ssnPattern.test(ssn)) {
      return { text: "Social Security Number must be properly formatted" };
    }
  }
};

export const validateZipCode = (zip, required = true) => {
  const requiredError = validateRequired(zip, "Zip Code", required);
  if (requiredError) return requiredError;
  if (zip) {
    var zipPattern = /^\d{5}(?:[-\s]\d{4})?$/;
    if (!zipPattern.test(zip)) {
      return { text: "Zip Code must be properly formatted" };
    }
  }
};

export const validateNumberLength = (
  value,
  name,
  minLength = 6,
  maxLength = 30,
  required = true
) => {
  const requiredError = validateRequired(value, name, required);
  if (requiredError) return requiredError;
  const text =
    minLength === maxLength
      ? `${name} must be exactly ${minLength} digits long`
      : `${name} must be between ${minLength} and ${maxLength} digits long`;
  if (value) {
    if (!isLength(value, { min: minLength })) {
      return { text };
    }
    if (!isLength(value, { max: maxLength })) {
      return { text };
    }
  }
};
