import React, { Component } from "react";
import { connect } from "react-redux";
import escapeRegExp from "lodash/escapeRegExp";
import filter from "lodash/filter";
import { Search } from "semantic-ui-react";

import { getLocations, setLocation } from "../../store/actions/properties";

class SearchBar extends Component {
  state = { value: "", isLoading: true, searchResults: [], allResults: [] };

  //when components is mounted, it will send request for all locations
  //that will be shown in dropdown menu
  componentDidMount() {
    if (this.props.location) {
      this.setState({ value: this.props.location.value });
    }
    if (this.props.availableLocations.length === 0) {
      this.props.getLocations();
    }
  }

  //when item is selected, a function will be called
  //wich will set a location in app state
  onResultSelect = (e, { result }) => {
    this.setState({ value: result.title });
    this.props.setLocation(result);
    this.props.onSubmit();
  };

  //this method will filter trough all results using regex and
  //return only results that are match for search term
  onSearchChange = (e) => {
    const value = e.target.value;
    this.setState({ value });
    if (value.length < 1) {
      console.log(this.state.allResults);
      return this.setState({ searchResults: this.state.allResults });
    }
    const re = new RegExp(escapeRegExp(value), "i");
    const isMatch = (result) => re.test(result.title);
    this.setState({
      isLoading: false,
      searchResults: filter(this.state.allResults, isMatch),
    });
  };

  //when props are updated - and this will happen when list of all
  //available locations is fetched - inner state of this component
  //will be set
  static getDerivedStateFromProps(props, currentState) {
    if (currentState.allResults !== props.availableLocations) {
      return {
        allResults: props.availableLocations,
        searchResults: props.availableLocations,
        isLoading: false,
      };
    }
    return null;
  }

  render() {
    return (
      <Search
        input={{ icon: "search", iconPosition: "left" }}
        loading={this.state.isLoading}
        value={this.state.value}
        results={this.state.searchResults}
        onResultSelect={this.onResultSelect}
        onSearchChange={this.onSearchChange}
        minCharacters={0}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    availableLocations: state.properties.availableLocations,
    location: state.properties.location,
  };
};

export default connect(mapStateToProps, { getLocations, setLocation })(
  SearchBar
);
