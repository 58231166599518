import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { Grid } from "semantic-ui-react";

import history from "../../history";
import { updateUserAccount } from "../../store/actions/account";
import AccountPageItems from "./AccountPageItems";
import ProfilePanel from "./ProfilePanel";
import CurrentAddressStep from "../../components/common/loloft-user-profile/CurrentAddressStep";
import PaymentInformationStep from "../../components/common/loloft-user-profile/PaymentInformationStep";
import EmploymentDetailsStep from "../../components/common/loloft-user-profile/EmploymentDetailsStep";
import PersonalInformationStep from "../../components/common/loloft-user-profile/PersonalInformationStep";
import Security from "./Security";
import AccountStatus from "./account-status/AccountStatus";

import { injectAccountPageStyle } from "../../utils/bodyStyleInjector";

class AccountPage extends Component {
  onUserAccountUpdate = (name, data) => {
    this.props.updateUserAccount(name, data);
  };

  isUserReady = () => {
    const { user } = this.props;
    return !user.status;
  };

  renderAccountReady = () => {
    const { user } = this.props;
    if (this.isUserReady()) {
      return (
        <Switch>
          <Route path={"/account/properties"}></Route>
          <Route path={"/account/personal-details-member"}>
            <PersonalInformationStep
              open={true}
              data={user.personalInformation}
              accountView={true}
              onUserAccountUpdate={this.onUserAccountUpdate}
            />
          </Route>
          <Route path={"/account/personal-details-owner"}></Route>
          <Route path={"/account/company"}></Route>
          <Route path={"/account/employment"}>
            <EmploymentDetailsStep
              open={true}
              data={user.employmentDetails}
              accountView={true}
              onUserAccountUpdate={this.onUserAccountUpdate}
            />
          </Route>
          <Route path={"/account/current-address"}>
            <CurrentAddressStep
              open={true}
              data={user.currentAddress}
              accountView={true}
              onUserAccountUpdate={this.onUserAccountUpdate}
            />
          </Route>
          <Route path={"/account/billing"}>
            <PaymentInformationStep
              open={true}
              data={user.paymentInformation}
              accountView={true}
              onUserAccountUpdate={this.onUserAccountUpdate}
            />
          </Route>
          <Route path={"/account/security"}>
            <Security />
          </Route>
        </Switch>
      );
    }
  };

  renderAccountPending = () => {
    const { user } = this.props;
    if (!this.isUserReady()) {
      return <AccountStatus user={user} />;
    }
  };

  renderRightPartOfPage = () => {
    if (this.isUserReady()) {
      return null;
    } else {
      return null;
    }
  };

  render() {
    const { user } = this.props;
    if (!user) {
      history.push("/login");
      return null;
    }
    injectAccountPageStyle();
    return (
      <div>
        <Grid stackable reversed="mobile">
          <Grid.Column verticalAlign="middle" width={8}>
            <Grid>
              <Grid.Row>
                <ProfilePanel user={user} />
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6}>
                  <AccountPageItems user={user} />
                </Grid.Column>
                <Grid.Column width={12}>
                  {this.renderAccountPending()}
                  {this.renderAccountReady()}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column width={8} className="login-bg">
            {this.renderRightPartOfPage()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { user: state.auth.user };
};

export default connect(mapStateToProps, { updateUserAccount })(AccountPage);
