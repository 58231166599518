import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";

//refactored containers
import HomePage from "./containers/home-page/HomePage";
import LoginPage from "./containers/login-page/LoginPage";
import BrowsePropertiesPage from "./containers/browse-properties-page/BrowsePropertiesPage";
import PropertyPage from "./containers/property-page/PropertyPage";
import LoloftNavbar from "./components/common/loloft-navbar/LoloftNavbar";
import LoloftFooter from "./components/common/LoloftFooter";
import firebase from "./config/firebase";
import history from "./history";

import { isLoggedIn } from "./store/actions/auth";
import "./assets/css/loloft-v2/loloft.scss";
import BookingPropertyPage from "./containers/booking-property-page/BookingPropertyPage";
import MemberOnboardingPage from "./containers/member-onboarding-page/MemberOnboardingPage";
import PropertyOnboardingPage from "./containers/property-onboarding-page/PropertyOnboardingPage";
import AccountPage from "./containers/account-page/AccountPage";

class App extends Component {
  //detect when firebase SDK is initialized
  //and will detect if user has an existing session
  //if it does, action isLoggedIn will be called
  //that will add logged user in app state
  componentDidMount() {
    firebase.auth().onAuthStateChanged(() => {
      this.props.isLoggedIn();
    });
  }

  render() {
    return (
      <React.Fragment>
        <LoloftNavbar history={history} />

        <div className="page-content">
          <Switch>
            <Route path="/home" component={HomePage} />
            <Route path="/login" component={LoginPage} />
            <Route exact path="/properties" component={BrowsePropertiesPage} />
            <Route path="/properties/:id" component={PropertyPage} />
            <Route path="/booking/" component={BookingPropertyPage} />
            <Route path="/member-onboarding" component={MemberOnboardingPage} />
            <Route
              path="/property-onboarding"
              component={PropertyOnboardingPage}
            />
            <Route path="/account" component={AccountPage} />
            <Route path="/" component={HomePage} />
          </Switch>
          <LoloftFooter />
        </div>
      </React.Fragment>
    );
  }
}

export default connect(null, { isLoggedIn })(App);
