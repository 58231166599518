export const yourAccountData = {
  title: "Your Account",
  changeAccount: "Change Account",
};
export const personalInformationData = {
  title: "Personal Information",
  fullNameInput: { label: "Full Legal Name", placeholder: "e.g. John Wick" },
  ssnInput: { label: "Social Security Number", placeholder: "AAA-GG-SSSS" },
  dateOfBirthInput: { label: "Date of Birth", placeholder: "YYYY-MM-DD" },
  checkboxLabel:
    "By submitting your application you agree with the terms of service",
  buttonLabel: "Continue",
  accountViewButtonLabel: "Update",
};
export const employmentDetailsData = {
  title: "Employment Details",
  dropdown: {
    label: "Employment Status",
    values: [
      { text: "Employed", value: "employed" },
      { text: "Unemployed", value: "unemployed" },
    ],
  },
  employerNameInput: { label: "Employer Name", placeholder: "e.g. Loloft" },
  jobTitleInput: { label: "Job Title", placeholder: "AAA-GG-SSSS" },
  employedSinceInput: { label: "Employed Since", placeholder: "Select Year" },
  buttonLabel: "Continue",
  accountViewButtonLabel: "Update",
};
export const currentAddressData = {
  title: "Current Address",
  cityInput: { label: "City", placeholder: "Enter current city" },
  stateInput: { label: "State", placeholder: "Current state" },
  addressInput: { label: "Address", placeholder: "Enter current address" },
  zipCodeInput: { label: "Zip Code", placeholder: "NY1234" },
  residentSinceInput: { label: "Resident Since", placeholder: "Select Year" },
  buttonLabel: "Continue",
  accountViewButtonLabel: "Save Address",
};
export const paymentInformationData = {
  title: "Payment Information",
  addAnotherButtonLabel: "Add Another",
  buttonLabel: "Continue",
  accountViewButtonLabel: "Update",
};
export const photoIDData = {
  title: "Photo ID",
  acceptable: {
    title: "Acceptable forms or ID",
    items: ["Valid drivers licence", "Government issued ID"],
  },
  front: "Front",
  back: "Back",
  checkboxLabel:
    "By submitting your photo ID you agree with the terms of service.",
  buttonLabel: "Continue",
  accountViewButtonLabel: "Update",
};

export const doneData = {
  title: "Done",
  info:
    "Your request has been sent to the review team. We'll get back to you shortly.",
  buttonLabel: "Go To Your Account",
};
