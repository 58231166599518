import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class LoloftCalendar extends Component {
  render() {
    const { onChange, date, label } = this.props;
    return (
      <div>
        <h4>{label}</h4>
        <DatePicker
          onChange={onChange}
          selected={date}
          inline
          dayClassName={d => (d.isBefore(date) ? "_past-day" : undefined)}
        />
      </div>
    );
  }
}

export default LoloftCalendar;
