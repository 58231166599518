import firebase from "../../config/firebase";

import { GET_BOOKED_DATA } from "../types";

export const bookProperty = (property, dates, paymentMethodId) => async (
  dispatch
) => {
  try {
    const booking = firebase.functions().httpsCallable("bookProperty");
    const { commitmentType, date } = dates;
    const propertyId = property.id;
    await booking({
      propertyId,
      commitmentType,
      date: date.format("Y-MM-DD"),
      paymentMethodId,
    });
  } catch (err) {
    console.error(err);
  }
};

export const getBookedProperty = (user) => async (dispatch) => {
  try {
    const bookedProperty = await firebase
      .firestore()
      .collection("bookings")
      .where("userId", "==", user.uid)
      .get();
    if (bookedProperty.empty) {
      console.error(`Booking info doesn't exist`);
    } else {
      dispatch({
        type: GET_BOOKED_DATA,
        payload: bookedProperty.docs[0].get(),
      });
    }
  } catch (err) {
    console.error(err);
  }
};
