export const amenities = [
  { value: "pool", label: "Pool", icon: "/assets/icons/icon-pool.svg" },
  {
    value: "fitness",
    label: "Fitness",
    icon: "/assets/icons/icon-fitness.svg"
  },
  {
    value: "elevator",
    label: "Elevator",
    icon: "/assets/icons/icon-elevator.svg"
  },
  {
    value: "pets",
    notACheckbox: true,
    label: "Pets Allowed",
    icon: "/assets/icons/icon-pets.svg"
  },
  { value: "wifi", label: "Wifi", icon: "/assets/icons/icon-wifi.svg" },
  { value: "shower", label: "Shower", icon: "/assets/icons/icon-shower.svg" },
  {
    value: "bathtub",
    label: "Bathtub",
    icon: "/assets/icons/icon-bathtub.svg"
  },
  { value: "ac", label: "AC", icon: "/assets/icons/icon-ac.svg" },
  { value: "garden", label: "Garden", icon: "/assets/icons/icon-garden.svg" },
  { value: "view", label: "Biew", icon: "/assets/icons/icon-view.svg" },
  {
    value: "parking",
    label: "Parking",
    icon: "/assets/icons/icon-parking.svg"
  },
  { value: "tv", label: "TV", icon: "/assets/icons/icon-tv.svg" }
];
export const label = "Amenities";
