import React, { Component } from "react";
import { connect } from "react-redux";

import { Grid, Container } from "semantic-ui-react";

import history from "../../history";

import PropertyImage from "./PropertyImage";
import PropertyDescription from "./PropertyDescription";
import PropertyAmenities from "./PropertyAmenities";
import PropertySleepingArrangements from "./PropertySleepingArrangements";
import PropertyMap from "./PropertyMap";
import PropertyData from "./PropertyData";
import PropertyDataMobile from "./PropertyDataMobile";

// Property css
import "../../assets/css/loloft-v2/property.scss";

import {
  getPropertiesById,
  getProperties,
  setLocation
} from "../../store/actions/properties";
import PropertiesContainer from "../browse-properties-page/PropertiesContainer";
import { propertyPage } from "../../statics/pages/property-page";
import { injectPropertyPageStyle } from "../../utils/bodyStyleInjector";

class PropertyPage extends Component {
  //if there is no property with required id in app state, try to load it
  componentDidMount() {
    if (!this.props.property) {
      this.props.getPropertiesById(this.props.match.params.id);
    }
  }

  renderComputer = () => {
    const { property } = this.props;
    return (
      <Container fluid>
        <div className="property-hero">
          <PropertyImage property={property} />
        </div>
        <Grid stackable container>
          <Grid.Row centered>
            <Grid.Column width={10}>
              <Grid.Row className="mobile only">
                <PropertyDataMobile property={property} />
              </Grid.Row>
              <Grid.Row>
                <PropertyDescription property={property} />
              </Grid.Row>
              <Grid.Row>
                <PropertySleepingArrangements property={property} />
              </Grid.Row>
              <Grid.Row>
                <PropertyAmenities property={property} />
              </Grid.Row>
              <Grid.Row>
                <PropertyMap property={property} />
              </Grid.Row>
              <Grid.Row>
                <h3>{propertyPage.other}</h3>
                <PropertiesContainer />
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={6} className="desktop only">
              <PropertyData property={property} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  };

  renderLoader = () => {
    return (
      <div>
        <h1>Loader</h1>
      </div>
    );
  };

  render() {
    injectPropertyPageStyle();
    if (this.props.property) {
      return <div className="switch">{this.renderComputer()}</div>;
    } else {
      if (this.props.propertyNotFound) {
        history.push("/properties");
      } else {
        return this.renderLoader();
      }
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const property = state.properties.properties.find(
    p => p.id === ownProps.match.params.id
  );
  return {
    property,
    propertyNotFound: state.properties.propertyNotFound
  };
};

export default connect(mapStateToProps, {
  getPropertiesById,
  getProperties,
  setLocation
})(PropertyPage);
