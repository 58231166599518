export const commitmentTypes = [
  {
    title: "Full-Flexi",
    excerpt: "Move every 30 days",
    description:
      "Your Loloft Membership gives you unlimited property switches after a 30 days minimum stay. You can also extend your stay at any time. Fees apply.",
    value: "full-flexi",
    multiplier: 1.2,
    months: 1
  },
  {
    title: "Flexi",
    excerpt: "Move every 6 months",
    description:
      "Switch properties twice per year. You can also extend your stay at any time.",
    value: "flexi",
    multiplier: 1.1,
    months: 6
  },
  {
    title: "Fixed",
    excerpt: "Stay for 12 months or more",
    description:
      "Found the apartment of your dreams? Lock it down so nobody else can get their hands on it.",
    value: "fixed",
    multiplier: 1,
    months: 12
  }
];

export const label = "Commitment Type";

export const priceBreakdown = {
  commitmentType: [
    { text: "Full-Flexi", value: "full-flexi", multiplier: 1.2 },
    { text: "Flexi", value: "flexi", multiplier: 1.1 },
    { text: "Fixed", value: "fixed", multiplier: 1 }
  ],
  title: "Price Breakdown",
  monthly: "monthly",
  deposit: {
    text: "Deposit",
    description: "Returned to you at the end of your stay"
  },
  registrationFee: {
    text: "Registration Fee",
    description: "One time payment for credit and background checks",
    value: "45"
  }
};
