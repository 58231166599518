import React, { Component } from "react";
import { Button } from "semantic-ui-react";

class LoloftPlusMinusInput extends Component {
  //if min prop exist and value is min, disable - button
  isDecrementDisabled = () => {
    const { value, min } = this.props;
    return min !== undefined && value === min ? true : false;
  };

  //if max prop exist and value is max, disable + button
  isIncrementDisabled = () => {
    const { value, max } = this.props;
    return max !== undefined && value === max ? true : false;
  };

  decrement = () => {
    const { name, onPlusMinusChange, value } = this.props;
    onPlusMinusChange(name, value - 1);
  };

  increment = () => {
    const { name, onPlusMinusChange, value } = this.props;
    onPlusMinusChange(name, value + 1);
  };

  render() {
    const { value, label } = this.props;
    return (
      <div className="flex inline spaced">
        <span>{label}</span>
        <div className="incremented flex inline contents">
          <span className="value">{value}</span>
          <span>
            <Button
              disabled={this.isDecrementDisabled()}
              onClick={this.decrement}
            >
              <img alt="" src="/assets/icons/minus.svg" />
            </Button>
          </span>
          <span>
            <Button
              disabled={this.isIncrementDisabled()}
              onClick={this.increment}
            >
              <img alt="" src="/assets/icons/plus.svg" />
            </Button>
          </span>
        </div>
      </div>
    );
  }
}

export default LoloftPlusMinusInput;
