import React, { Component } from "react";
import firebase from "../../config/firebase";
import { Loader, Dimmer } from "semantic-ui-react";

class LoloftUploadImage extends Component {
  state = { loading: this.props.loadImage, image: undefined, error: undefined };

  componentDidMount() {
    if (this.props.loadImage) {
      const storageRef = firebase.storage().ref(`${this.props.fileName}`);
      storageRef
        .getDownloadURL()
        .then(url => {
          this.setState({ loading: false, image: url });
          this.props.onUploadFile();
        })
        .catch(err => {
          this.setState({ loading: false, error: err });
        });
    }
  }

  uploadImage = () => {
    if (this.uploadRef) {
      this.uploadRef.click();
    }
  };

  onUploadFile = event => {
    this.setState({ loading: true });
    const file = event.target.files[0];
    if (!file) return;
    const storageRef = firebase.storage().ref(this.props.fileName);
    const uploadTask = storageRef.put(file);
    uploadTask.on("state_changed", {
      complete: async () => {
        try {
          const downloadUrl = await storageRef.getDownloadURL();
          this.setState({ image: downloadUrl });
          if (this.props.onUploadFile) {
            this.props.onUploadFile();
          }
        } finally {
          this.setState({ loading: false });
        }
      }
    });
  };

  removeImage = async () => {
    this.setState({ loading: true, image: undefined });
    const storageRef = firebase.storage().ref(`gs://${this.props.fileName}`);
    try {
      await storageRef.delete();
      if (this.props.onRemoveFile) {
        this.props.onRemoveFile();
      }
    } catch (err) {
      this.setState({ error: err });
    }
    this.setState({ loading: false });
  };

  renderRemoveButton = () => {
    if (this.state.image) {
      return (
        <img src={"/assets/icons/icon-close.svg"} onClick={this.removeImage} />
      );
    }
  };

  renderLoader = () => {
    if (this.state.loading) {
      return (
        <Dimmer active>
          <Loader size="small"></Loader>
        </Dimmer>
      );
    }
  };

  render() {
    return (
      <div className="upload card">
        <div
          className={this.state.loading ? "contents faded" : "contents"}
          onClick={() => this.uploadImage()}
        >
          {this.renderLoader()}
          <img
            className={this.state.image ? "ui centered image" : "icon"}
            src={
              this.state.image
                ? this.state.image
                : "/assets/icons/icon-camera.svg"
            }
          />
          <label className={this.state.image ? "hidden" : ""}>
            {this.props.label}
          </label>
          <input
            type="file"
            ref={ref => (this.uploadRef = ref)}
            onChange={this.onUploadFile}
            className="uploader"
            style={{ display: "none" }}
          />
        </div>
      </div>
    );
  }
}

export default LoloftUploadImage;
