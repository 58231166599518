import React from "react";
import { Button } from "semantic-ui-react";
import history from "../../../history";

function ContactSupportButton() {
  return (
    <Button
      onClick={() => {
        history.push("/support");
      }}
    >
      Contact Support
    </Button>
  );
}

export default ContactSupportButton;
