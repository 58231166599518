import React from "react";
import { Progress } from "semantic-ui-react";

function LoloftProgressBar(props) {
  return (
    <div className="loloft-progress">
      <span>Step {props.currentStep}</span>
      <Progress
        value={`${props.currentStep}`}
        total={`${props.totalSteps}`}
        progress="value"
      />
    </div>
  );
}

export default LoloftProgressBar;
