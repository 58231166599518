import React, { Component } from "react";
import { connect } from "react-redux";
import { Dropdown, Button, Grid } from "semantic-ui-react";

import LoloftPropertyTypes from "../../components/common/LoloftPropertyTypes";
import LoloftPlusMinusInput from "../../components/common/LoloftPlusMinusInput";
import LoloftAmenities from "../../components/common/LoloftAmenities";
import { setFilters, getPropertiesCount } from "../../store/actions/properties";
import { setFiltersData } from "../../statics/pages/browse-properties-page";

class Filters extends Component {
  getInitialState = () => {
    return {
      amenities: [],
      propertyTypes: [],
      bedrooms: 0,
      bathrooms: 0,
      parkingSpaces: 0,
      petsAlowed: setFiltersData.livingSpace.petsAllowed.values[0],
      budget: 0
    };
  };
  state = this.getInitialState();

  componentDidMount() {
    if (this.props.filters) {
      this.setState(this.props.filters);
    }
    this.props.getPropertiesCount(this.props.filters);
  }

  //anytime state is change, it will be done trough this function
  //and this function will also do getPropertiesCount action
  //which will try to fetch number of properties that are available for set
  //dates and filters and location
  setStateAndFetchCount = newState => {
    const state = { ...this.state, ...newState };
    this.setState(state);
    this.props.getPropertiesCount(state);
  };

  //seting state for property types
  onPropertyTypesChange = propertyTypes => {
    this.setStateAndFetchCount({ propertyTypes });
  };

  //setting state on plus minus input fields
  onPlusMinusChange = (name, value) => {
    this.setStateAndFetchCount({ [name]: value });
  };

  //setting pets allowed state
  onPetsAllowedChange = (e, selected) => {
    this.setStateAndFetchCount({ petsAlowed: { value: selected.value } });
  };

  //setting amenities state
  onAmenitiesChange = amenities => {
    this.setStateAndFetchCount({ amenities });
  };

  //setting budget state
  //but will not trigget count, because it will be triggered multiple times
  onBudgetChange = e => {
    this.setState({ budget: e.target.value });
  };

  onBudgetMouseUp = e => {
    this.props.getPropertiesCount(this.state);
  };

  //restores initial state
  resetFilters = () => {
    this.setStateAndFetchCount(this.getInitialState());
  };

  //this action will set filters in app state
  //and then will close modal window - if desktop
  //or return to browse properties view - if mobile
  onSubmit = () => {
    this.props.setFilters(this.state);
    this.props.onSubmit();
    this.props.closeAction();
  };

  renderResetLink = label => {
    return (
      <a href="#" onClick={this.resetFilters}>
        {label}
      </a>
    );
  };

  renderSubmitButton = () => {
    return (
      <Button
        primary 
        onClick={this.onSubmit}
      >{//this is dynamicly set text that will show number of properties available on
      //on set date, with set property state, and set commitment
      `${setFiltersData.buttonLabel} ${
        this.props.count !== undefined ? this.props.count : ""
      }`}</Button>
    );
  };

  renderBudgetInput = () => {
    const { maximumBudget } = setFiltersData;
    return (
      <div className="box">
        <h4>{maximumBudget.label}</h4>
        <input
          type="range"
          min={0}
          max={maximumBudget.max}
          value={this.state.budget}
          onChange={this.onBudgetChange}
          onMouseUp={this.onBudgetMouseUp}
        />
        <span className="value">${this.state.budget}</span>
      </div>
    );
  };

  renderPetsAllowed = () => {
    const { livingSpace } = setFiltersData;
    return (
      <div className="flex inline spaced">
        <div>{livingSpace.petsAllowed.label}</div>
        <Dropdown
          onChange={this.onPetsAllowedChange}
          value={this.state.petsAlowed.value}
          options={livingSpace.petsAllowed.values}
        />
      </div>
    );
  };

  renderLivingSpace = () => {
    const { livingSpace } = setFiltersData;

    return (
      <div className="box">
        <h4>{livingSpace.label}</h4>
        <div className="loloft-grid">
          <LoloftPlusMinusInput
            onPlusMinusChange={this.onPlusMinusChange}
            value={this.state.bedrooms}
            name="bedrooms"
            label={livingSpace.bedrooms.label}
            min={0}
          />
          <LoloftPlusMinusInput
            onPlusMinusChange={this.onPlusMinusChange}
            value={this.state.parkingSpaces}
            name="parkingSpaces"
            label={livingSpace.parkingSpaces.label}
            min={0}
          />
          <LoloftPlusMinusInput
            onPlusMinusChange={this.onPlusMinusChange}
            value={this.state.parkingSpaces}
            name="parkingSpaces"
            label={livingSpace.parkingSpaces.label}
            min={0}
          />
          {this.renderPetsAllowed()}
        </div>
      </div>
    );
  };

  renderForComputer = () => {
    return (
      <div>
        <LoloftPropertyTypes
          propertyTypesState={this.state.propertyTypes}
          setPropertyTypes={this.onPropertyTypesChange}
        />
        {this.renderLivingSpace()}
        <LoloftAmenities
          amenities={this.state.amenities}
          onChange={this.onAmenitiesChange}
        />
        {this.renderBudgetInput()}
        <div className="right actions">
          {this.renderResetLink(setFiltersData.resetLabel)}
          {this.renderSubmitButton()}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="switch">
        {this.renderForComputer()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    filters: state.properties.filters,
    count: state.properties.filteredCount
  };
};

export default connect(mapStateToProps, { setFilters, getPropertiesCount })(
  Filters
);
