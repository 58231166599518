import React, { Component } from "react";

import { Form, Checkbox } from "semantic-ui-react";

import { propertyType as data } from "../../statics/property-types";

class LoloftPropertyTypes extends Component {
  //complicated function for switching checkboxes
  //and managing ALL checkbox behaviour
  onPropertyTypesChange = (e, { value }) => {
    const { setPropertyTypes, propertyTypesState } = this.props;
    var propertyTypes;
    //if ALL checkbox is selected, all values, except ALL, will be added to the state
    if (value === "all") {
      propertyTypes = data.types.map(type => type.id);
      propertyTypes = propertyTypes.filter(type => type && type !== value);
    } else {
      //if clicked checkbox is already added to the state
      if (propertyTypesState.includes(value)) {
        //if all boxes are checked, then remove all values from state, except the one that is clicked
        if (propertyTypesState.length === data.types.length - 1) {
          propertyTypes = propertyTypesState.filter(type => type === value);
        } else {
          //else just remove it from state
          propertyTypes = propertyTypesState.filter(type => type !== value);
        }
        //if clicked checkbox is not in the state, add it to the state
      } else {
        propertyTypes = [...propertyTypesState, value];
      }
    }
    //set state
    setPropertyTypes(propertyTypes);
  };

  //if every type is in state, except ALL, select ALL,
  //otherwise, select only those checkboxes in state
  isPropertyTypeSelected = type => {
    const { propertyTypesState } = this.props;
    if (propertyTypesState.length === data.types.length - 1) {
      return type === "all";
    } else {
      return propertyTypesState.includes(type);
    }
  };

  render() {
    return (
      <div className="box">
        <h4>{data.label}</h4>
        <Form.Field>
          {data.types.map(type => {
            return (
              <Checkbox
                key={type.id}
                label={type.label}
                name="propertyTypes"
                value={type.id}
                checked={this.isPropertyTypeSelected(type.id)}
                onChange={this.onPropertyTypesChange}
              />
            );
          })}
        </Form.Field>
      </div>
    );
  }
}

export default LoloftPropertyTypes;
