import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { Elements } from "react-stripe-elements";
import { Tab, Button } from "semantic-ui-react";

import { loadPaymentInformation } from "../../../store/actions/onboarding";
import LoloftErrorMessage from "../../common/LoloftErrorMessage";
import CreditCardInput from "./CreditCardInput";
import BankAccountInput from "./BankAccountInput";
import CreditCardItem from "./CreditCardItem";
import BankAccountItem from "./BankAccountItem";
import { paymentInformationData } from "../../../statics/pages/member-onboarding-page";

class PaymentInformation extends Component {
  state = { showInput: false, error: {} };

  componentDidMount() {
    this.props.loadPaymentInformation();
  }

  closeInput = () => {
    this.setState({ showInput: false });
  };
  showInput = () => {
    this.setState({ showInput: true });
  };

  setParrentError = (errorMsg) => {
    const error = { removeError: { text: errorMsg } };
    this.setState(errorMsg ? { error } : { error: {} });
  };

  getPaymentMethodInputs = () => {
    return [
      {
        menuItem: "Credit Card",
        render: () => (
          <Tab.Pane>
            <Elements>
              <CreditCardInput
                owner={this.props.auth.user}
                closeInput={this.closeInput}
                legalName={this.props.legalName}
              />
            </Elements>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Bank Account",
        render: () => (
          <Tab.Pane>
            <Elements>
              <BankAccountInput
                owner={this.props.auth.user}
                closeInput={this.closeInput}
                legalName={this.props.legalName}
              />
            </Elements>
          </Tab.Pane>
        ),
      },
    ];
  };

  shouldShowInput = () => {
    const { creditCards, bankAccounts } = this.props.paymentInformation;
    return (
      this.state.showInput ||
      (creditCards.length === 0 && bankAccounts.length === 0)
    );
  };

  //if component is in show input mode, or there is no cards or bank accounts
  //render payment methods
  renderPaymentInputs = () => {
    if (this.shouldShowInput()) {
      return (
        <Tab menu={{ secondary: true }} panes={this.getPaymentMethodInputs()} />
      );
    }
  };

  //if component is in show input mode, or there is no cards or bank accounts
  //render payment methods
  renderPaymentMethods = () => {
    const { creditCards, bankAccounts } = this.props.paymentInformation;
    const canRemoveCreditCards = creditCards.length > 1;
    const canRemoveBankAccounts = bankAccounts.length > 1;
    if (!this.shouldShowInput()) {
      return (
        <div>
          <LoloftErrorMessage parentState={this.state} name="removeError" />
          {creditCards.map((card) => {
            return (
              <CreditCardItem
                card={card}
                key={card.id}
                legalName={this.props.legalName}
                removePossible={canRemoveCreditCards}
                setError={this.setParrentError}
              />
            );
          })}
          {bankAccounts.map((bank) => {
            return (
              <BankAccountItem
                bank={bank}
                key={bank.id}
                legalName={this.props.legalName}
                removePossible={canRemoveBankAccounts}
                setError={this.setParrentError}
              />
            );
          })}
          <Button fluid white onClick={this.showInput}>
            {paymentInformationData.addAnotherButtonLabel}
          </Button>
          <Button fluid primary onClick={this.props.onComplete}>
            {paymentInformationData.buttonLabel}
          </Button>
        </div>
      );
    }
  };

  render() {
    if (isEmpty(this.props.auth.user)) return null;
    return (
      <Fragment>
        <h1>{paymentInformationData.title}</h1>
        {this.renderPaymentMethods()}
        {this.renderPaymentInputs()}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  let legalName;
  if (
    state.onboarding.personalInformation &&
    state.onboarding.personalInformation.fullName
  ) {
    legalName = state.onboarding.personalInformation.fullName;
  } else if (state.auth.user && state.auth.user.fullName) {
    legalName = state.auth.user.fullName;
  }

  return {
    auth: state.auth,
    paymentInformation: state.onboarding.paymentInformation,
    legalName,
  };
};

export default connect(mapStateToProps, { loadPaymentInformation })(
  PaymentInformation
);
