import React, { Component } from "react";
import { connect } from "react-redux";

import { Grid, Button } from "semantic-ui-react";

import { getProperties, showNextPage } from "../../store/actions/properties";
import PropertiesCard from "./PropertiesCard";
import { propertiesContainerData } from "../../statics/pages/browse-properties-page";

class PropertiesContainer extends Component {
  PAGE_SIZE = 4;

  //this method is colled when rendering property cards,
  //and can be called in three different ways
  //for small screens it will show all properties in 1 column
  //for medium size screens it will show properties in 2 columns
  //and for larghe screens it will show properties in 3 columns
  renderProperties = (current, columns) => {
    var counter = 0;
    const { propertiesToRender } = this.props;
    if (propertiesToRender) {
      return propertiesToRender.map(property => {
        counter++;
        const propertyCard = (
          <PropertiesCard property={property} key={counter} />
        );
        if ((counter - 1) % columns === current) {
          return propertyCard;
        }
        return null;
      });
    }
  };

  onLoadMoreButtonClick = () => {
    this.props.showNextPage();
  };

  //will render show more properties button
  //if there is more pages to be shown
  renderShowMorePropertiesButton = () => {
    const documentsToRender = this.props.containerPage * this.PAGE_SIZE;
    if (this.props.properties.length > documentsToRender) {
      return (
        <Button
          primary
          fluid
          className="white"
          onClick={this.onLoadMoreButtonClick}
        >
          {propertiesContainerData.showMoreButtonLabel}
        </Button>
      );
    } else {
      if (this.props.hasMoreDocs) {
        this.props.getProperties(true);
        //return loader
      }
    }
  };

  renderColumns = () => {
    return (
      <Grid stackable className="variable">
        <Grid.Column width={3}>{this.renderProperties(0, 1)}</Grid.Column>
        <Grid.Column width={16}>
          {this.renderShowMorePropertiesButton()}
        </Grid.Column>
      </Grid>
    );
  };

  render() {
    return (
      <div>
        <div className="list">
          {this.renderProperties(0, 1)}
        </div>
        <div className="more">
          {this.renderShowMorePropertiesButton()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    properties,
    propertiesToRender,
    hasMoreDocs,
    containerPage
  } = state.properties;
  return {
    properties,
    propertiesToRender,
    hasMoreDocs,
    containerPage
  };
};

export default connect(mapStateToProps, { getProperties, showNextPage })(
  PropertiesContainer
);
