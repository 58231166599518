import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid } from "semantic-ui-react";

import history from "../../history";

import Authentication from "../../components/common/loloft-auth/Authentication";
import BookingData from "./BookingData";
import { isUserLoggedIn } from "../../utils/userUtility";
import { injectAuthPageStyle } from "../../utils/bodyStyleInjector";
import { bookingPropertyPageData } from "../../statics/pages/booking-property-page";
import SelectPaymentMethod from "./SelectPaymentMethod";
import BookingDone from "./BookingDone";

class BookingPropertyPage extends Component {
  state = { selectedPaymentId: undefined };

  //renders content that is depending on login state, user account, and this component state
  renderContent = () => {
    const { bookingData } = this.props;
    const { user } = this.props.auth;
    if (isUserLoggedIn(user)) {
      //if user is logged in
      if (user.emailVerified) {
        //and user is verified
        var { selectedPaymentId } = this.state;
        if (selectedPaymentId) {
          //and has not selected payment yet
          return (
            <BookingDone
              selectedPaymentId={selectedPaymentId}
              bookingData={bookingData}
              isVerified={true}
            />
          );
        } else {
          return (
            //and has selected payment
            <div className="basic box">
            <SelectPaymentMethod
              bookingData={bookingData}
              onPaymentSelect={paymentMethod =>
                this.setState({ selectedPaymentId: paymentMethod })
              }
            />
            </div>
          );
        }
      } else {
        //and user is not verified
        return <BookingDone bookingData={bookingData} isVerified={false} />;
      }
    } else {
      //if user is not logged in
      return ( 
        <div className="basic box">
          <h1>{bookingPropertyPageData.title}</h1>
          <Authentication owner="booking" />
        </div>
      );
    }
  };

  render() {
    injectAuthPageStyle();
    if (this.props.bookingData) {
      return (
        <Grid stackable reversed="mobile">
          <Grid.Column verticalAlign="middle" width={8}>
            {this.renderContent()}
            <BookingData bookingData={this.props.bookingData} />
          </Grid.Column>
          <Grid.Column width={8} className="login-bg"></Grid.Column>
        </Grid>
      );
    } else {
      //if there is no booking data inside state,
      //redirect back to properties page
      history.push("/properties");
      return null;
    }
  }
}

const mapStateToProps = state => {
  return { auth: state.auth, bookingData: state.properties.bookingData };
};

export default connect(mapStateToProps)(BookingPropertyPage);
