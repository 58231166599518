import React, { Component } from "react";
import { connect } from "react-redux";

import history from "../../history";
import { bookProperty } from "../../store/actions/booking";
import { placeReservedData } from "../../statics/pages/booking-property-page";
import { Button } from "semantic-ui-react";

class BookingDone extends Component {
  componentDidMount() {
    const { bookingData, selectedPaymentId } = this.props;
    const { property, dates } = bookingData;
    this.props.bookProperty(property, dates, selectedPaymentId);
  }

  onButtonClick = () => {
    history.push("/");
  };

  render() {
    if (this.props.isVerified) {
      return (
        <div className="basic box">
          <h1>{placeReservedData.title}</h1>
          <p>{placeReservedData.textVerified}</p>
          <Button primary fluid onClick={this.onButtonClick}>
            {placeReservedData.buttonVerifiedLabel}
          </Button>
        </div>
      );
    } else {
      return (
        <div className="basic box">
          <h1>{placeReservedData.title}</h1>
          <p>{placeReservedData.textUnverified}</p>
          <Button primary fluid onClick={this.onButtonClick}>
            {placeReservedData.buttonUnverifiedLabel}
          </Button>
        </div>
      );
    }
  }
}

export default connect(null, { bookProperty })(BookingDone);
