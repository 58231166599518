import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu, Icon } from "semantic-ui-react";

import { closeSidebar, openSidebar } from "../../../store/actions/sidebar";

class LoloftNavbarMobile extends Component {
  handleToggle = () => {
    if (this.props.opened) {
      this.props.closeSidebar();
    } else {
      this.props.openSidebar();
    }
  };

  render() {
    return (
      <Menu pointing size="large" fixed="top">
        <Menu.Item className="permanent" position="left">
          <img
            src="/assets/img/loloft-logo.svg"
            className="logo"
            alt=""
          />
        </Menu.Item>
        <Menu.Item
          className="permanent"
          position="right"
          onClick={this.handleToggle}
        >
          <Icon name="sidebar" />
        </Menu.Item>
      </Menu>
    );
  }
}

const mapStateToProps = state => {
  return { opened: state.sidebar.opened };
};
export default connect(mapStateToProps, { closeSidebar, openSidebar })(
  LoloftNavbarMobile
);
