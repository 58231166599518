import React from "react";
import { connect } from "react-redux";

import { paymentInformationData } from "../../../statics/pages/member-onboarding-page";
import PaymentInformation from "../loloftPayment/PaymentInformation";

//props
//mobile - is this a view for mobile device
//open - is this step currently in focus/ opened
//competed - is this step already completed
//setCompletedStep - will set this step as compete in parent,
//which will make parent switch to the next step
const PaymentInformationStep = props => {
  const { open, completed, mobile } = props;
  if (mobile) {
    if (open) return renderStep(props);
    else return null;
  } else {
    if (open) return renderStep(props);
    else {
      if (completed) return renderCompletedInformation(props);
      else return renderIncompleteInformation(props);
    }
  }
};

//renders information about data that user entered in this step
const renderCompletedInformation = props => {
  const pi = props.paymentInformation;
  if (pi) {
    return (
      <div>
        <h2>{paymentInformationData.title}</h2>
        <p>{pi.type} ending with</p>
      </div>
    );
  }
  return null;
};

//renders information about data that user entered in this step
const renderIncompleteInformation = () => {
  return <h2>{paymentInformationData.title}</h2>;
};

//renders specific for or data for this step, and will bi directly used
//for mobile view
const renderStep = props => {
  return (
    <PaymentInformation
      {...props}
      onComplete={() => props.setCompletedStep(props.step)}
    />
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    paymentInformation: state.onboarding.paymentInformation
  };
};

export default connect(mapStateToProps)(PaymentInformationStep);
