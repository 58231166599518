import React, { Component } from "react";
import history from "../../history";
import LoloftCarousell from "../../components/common/loloft-carousell/LoloftCarousell";

export class PropertiesCard extends Component {
  onClick = () => {
    const { id } = this.props.property;
    if (id) {
      history.push(`/properties/${id}`);
    }
  };

  renderPrice = () => {
    const { price } = this.props.property;
    return <div className="card-price">${price} <span>/ month</span></div>;
  };
  renderLivingSpace = () => {
    const { bathrooms, bedrooms } = this.props.property;
    return (
      <div className="card-lspace">
        {bedrooms} Bedrooms <span>•</span> {bathrooms} Bathrooms
      </div>
    );
  };
  renderAddress = () => {
    const { address } = this.props.property.location;
    return <div className="card-address">{address}</div>;
  };

  renderImages = () => {
    const { images, occupied } = this.props.property;
    const occupiedLabel = occupied ? null : <div className="ui yellow label">Available Today</div>;
    if (images && images.length > 0) {
      if (images.length === 1) {
        return (
          <div>
            <img
              className="ui centered image"
              width="500"
              alt=""
              src={images[0].image}
            />
            {occupiedLabel}
          </div>
        );
      } else {
        var key = 0;
        return (
          <div className="wrap">
            {" "}
            <LoloftCarousell>
              {images.map(img => {
                return (
                  <div key={key++}>
                    <img
                      className="ui centered image"
                      width="500"
                      alt=""
                      src={img.image}
                    />
                  </div>
                );
              })}
            </LoloftCarousell>
            {occupiedLabel}
          </div>
        );
      }
    }
  };

  render() {
    return (
      <div className="property card" onClick={this.onClick}>
        <div className="property images">{this.renderImages()}</div>
        <div className="card-meta">
          {this.renderPrice()}
          {this.renderLivingSpace()}
          {this.renderAddress()}
        </div>
      </div>
    );
  }
}

export default PropertiesCard;
