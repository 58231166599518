export const loginForm = {
  title: "Log In",
  emailInput: {
    label: "Email Address",
    placeholder: "Email Address"
  },
  passwordInput: {
    label: "Password",
    placeholder: "Enter Your Password"
  },
  forgottenLink: "Forgot Your Password?",
  buttonText: "Login"
};

export const forgottenPassword = {
  title: "Recover Your Password",
  emailInput: {
    label: "Email Address",
    placeholder: "Email Address"
  },
  buttonText: "Recover Password",
  backLink: "Back to Login"
};

export const forgottenPasswordSent = {
  title: "Email Sent!",
  text:
    "Before you can move in we need to get a few more details from you. This helps us to maintain a safe community on loloft.",

  buttonText: "Login"
};

export const resetPassword = {
  title: "Reset Password",
  emailInput: {
    label: "Email Address"
  },
  passwordInput: {
    label: "Password",
    placeholder: " Enter Your New Password"
  },
  buttonText: "Reset password",
  backLink: "Back to Login"
};
