import React, { Fragment } from "react";
import LoloftDropdown from "./LoloftDropdown";

function LoloftYearPicker(props) {
  return (
    <Fragment>
      <LoloftDropdown
        name={props.name}
        onChange={props.onChange}
        value={props.value}
        defaultValue="Select Year"
        options={getPastYearsOptions(50)}
        dropdownLabel={props.label}
        error={props.error}
      />
    </Fragment>
  );
}

const getPastYearsOptions = numberOfYears => {
  const currentYear = new Date().getFullYear();
  var options = [];
  for (var i = 0; i <= numberOfYears; i++) {
    const year = currentYear - i + "";
    options.push({ value: year, text: year });
  }
  return options;
};

export default LoloftYearPicker;
